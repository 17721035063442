import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"

import Select from "react-dropdown-select"
import { useAlert } from "react-alert"
import { useMutation } from "react-query"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, FormFeedback, Input, Label, Row } from "reactstrap"
import { isEmpty, isNil } from "lodash"
import { Formik } from "formik"
import * as Yup from "yup"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { updateRequest } from "graphql/mutations"
import { CommunicationChannel, ReferrerType, RequestStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation, useTranslation } from "react-i18next"

// Component
function RequestDetailSummaryViewEdit(props) {
  const { username, request, userslist, onSave, onCancel } = props

  // Alert
  const customAlert = useAlert()

  // Detect language change
  const { i18n } = useTranslation()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const requestStatusOptions = useMemo(() => {
    return initRequestStatusOptions()
  }, [i18n.language])
  const communicationChannelOptions = useMemo(() => {
    return initCommunicationChannelOptions()
  }, [i18n.language])
  const referrerOptions = useMemo(() => {
    return initReferrerOptions()
  }, [i18n.language])

  // ----------------------------------------------------------------------------------------------------------------
  // Init function (for useMemo hook)
  // ----------------------------------------------------------------------------------------------------------------
  function initRequestStatusOptions() {
    //console.log("[initRequestStatusOptions] - params[]")
    let items = [
      { value: RequestStatus.PENDING, label: i18n.t("Common.Enum.RequestStatus.PENDING") },
      { value: RequestStatus.INPROGRESS, label: i18n.t("Common.Enum.RequestStatus.INPROGRESS") },
      { value: RequestStatus.COMPLETED, label: i18n.t("Common.Enum.RequestStatus.COMPLETED") },
    ]
    return items
  }

  function initCommunicationChannelOptions() {
    //console.log("[initCommunicationChannelOptions] - params[]")
    let items = [
      { value: CommunicationChannel.EMAIL, label: i18n.t("Common.Enum.CommunicationChannel.EMAIL") },
      { value: CommunicationChannel.PHONE, label: i18n.t("Common.Enum.CommunicationChannel.PHONE") },
      { value: CommunicationChannel.SOCIAL_MEDIA, label: i18n.t("Common.Enum.CommunicationChannel.SOCIALMEDIA") },
      { value: CommunicationChannel.WEBSITE, label: i18n.t("Common.Enum.CommunicationChannel.WEBSITE") },
    ]
    return items
  }

  // TODO REVIEW
  function initReferrerOptions() {
    //console.log("[initReferredByOptions] - params[]")
    let items = [
      { value: ReferrerType.CLIENT, label: i18n.t("Common.Enum.Referrer.CLIENT") },
      { value: ReferrerType.SEARCH, label: i18n.t("Common.Enum.Referrer.SEARCH") },
      { value: ReferrerType.SOCIAL_MEDIA, label: i18n.t("Common.Enum.Referrer.SOCIALMEDIA") },
      { value: ReferrerType.WEBSITE, label: i18n.t("Common.Enum.Referrer.WEBSITE") },
      { value: ReferrerType.OTHER, label: i18n.t("Common.Enum.Referrer.OTHER") },
    ]
    return items
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  async function handleOnSubmit(entityRequest) {
    //console.log("[handleOnSubmit] - params[entityRequest]", entityRequest)
    let entityRequestUpdate = await doUpdateRequest(entityRequest)
    if (isNil(entityRequestUpdate)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de mettre à jour la requête" }), { timeout: 0 })
    } else {
      onSave(entityRequestUpdate)
    }
  }

  async function doUpdateRequest(entityRequest) {
    //console.log("[doUpdateRequest] - params[entityRequest]", entityRequest)
    const input = {
      ...entityRequest,
      updateUser: username,
    }
    //console.log("EditRequestView.doUpdateRequest:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("EditRequestView.doUpdateRequest:updateInput", updateInput)
    try {
      const result = await updateRequestMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("EditRequestView.doUpdateRequest.updateRequestMutation:error", error)
      return null
    }
  }

  const updateRequestMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateRequest", updateRequest, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <Formik
          /*enableReinitialize={true}*/
          initialValues={{
            assignee: isEmpty(request?.assignee) ? "" : request?.assignee,
            status: isEmpty(request?.status) ? "" : request?.status,
            requestDate: isEmpty(request?.requestDate) ? "" : request.requestDate,
            channel: isEmpty(request?.communicationChannel) ? "" : request.communicationChannel,
            //referrer: isEmpty(request?.referrer) ? "" : request.referrer,
            //estimatedDeliveryDate: isEmpty(request?.estimedDeliveryDate) ? "" : request.estimedDeliveryDate,
            //deliveryDate: isEmpty(request?.deliveryDate) ? "" : request.deliveryDate,
          }}
          validationSchema={Yup.object({})}
          onSubmit={values => {
            //console.log("EditRequestView.onSubmit:values", values)
            request.assignee = isEmpty(values["assignee"]) ? null : values["assignee"]
            request.status = isEmpty(values["status"]) ? null : values["status"]
            request.requestDate = isEmpty(values["requestDate"]) ? null : values["requestDate"]
            request.communicationChannel = isEmpty(values["channel"]) ? null : values["channel"]
            request.referrer = isEmpty(values["referrer"]) ? null : values["referrer"]
            //request.estimedDeliveryDate = isEmpty(values["estimatedDeliveryDate"]) ? null : values["estimatedDeliveryDate"]
            //request.deliveryDate = isEmpty(values["deliveryDate"]) ? null : values["deliveryDate"]
            handleOnSubmit(request)
          }}
        >
          {formik => (
            <React.Fragment>
              <Form onSubmit={formik.handleSubmit}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <CardTitle>{props.t("Page.RequestDetail.SummaryViewEdit")}</CardTitle>
                    <div className="mb-2">{props.t("Page.RequestDetail.SummaryViewEdit.Subtitle")}</div>
                    <hr className="mt-0" />
                  </div>
                </div>
                <Row>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Assignee")}</Label>
                    <Col sm="3">
                      <Select
                        name="assignee"
                        placeholder={props.t("Common.Text.User.Unassigned")}
                        values={userslist.filter(item => {
                          return item.value === request?.assignee
                        })}
                        options={userslist}
                        onChange={values => formik.setFieldValue("assignee", isNil(values[0]?.value) ? "" : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Status")}</Label>
                    <Col sm="3">
                      <Select
                        name="status"
                        values={requestStatusOptions.filter(item => {
                          return item.value === request.status
                        })}
                        options={requestStatusOptions}
                        onChange={values => formik.setFieldValue("status", isNil(values[0]?.value) ? "" : values[0].value)}
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <hr />
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Date.RequestDate")}</Label>
                    <Col sm="3">
                      <Input id="inputRequestDate" name="requestDate" type="date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.requestDate} invalid={formik.touched.requestDate && formik.errors.requestDate ? true : false} />
                      {formik.touched.requestDate && formik.errors.requestDate ? <FormFeedback type="invalid">{formik.errors.requestDate}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.CommunicationChannel")}</Label>
                    <Col sm="3">
                      <Select
                        name="communicationChannel"
                        values={communicationChannelOptions.filter(item => {
                          return item.value === request.communicationChannel
                        })}
                        options={communicationChannelOptions}
                        onChange={values => formik.setFieldValue("channel", isNil(values[0]?.value) ? "" : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.ReferredBy")}</Label>
                    <Col sm="3">
                      <Select
                        name="referred"
                        values={referrerOptions.filter(item => {
                          return item.value === request.referrer
                        })}
                        options={referrerOptions}
                        onChange={values => formik.setFieldValue("referrer", isNil(values[0]?.value) ? "" : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                </Row>
                <hr className="mt-0" />
                <Row>
                  <Col className="no-print">
                    <Button className="me-2" color="primary" disabled={!(formik.isValid && formik.dirty)} type="submit">
                      {props.t("Common.Action.Save")}
                    </Button>
                    <Button className="me-2" color="secondary" outline onClick={onCancel}>
                      {props.t("Common.Action.Cancel")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

RequestDetailSummaryViewEdit.propTypes = {
  t: PropTypes.any,
  username: PropTypes.string,
  request: PropTypes.any,
  userslist: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(withTranslation()(RequestDetailSummaryViewEdit))
