// LEA APP URLs
export const URL_DASHBOARD_COMPANY = "/dashboard-company"

export const URL_DASHBOARD_PERSONAL = "/dashboard-personal"

export const URL_DESKTOP = "/desktop"

export const URL_REQUESTS = "/requests"

export const URL_MANDATES = "/mandates"

export const URL_TASKS = "/tasks"

export const URL_INVOICES = "/invoices"

export const URL_CONTACTS = "/contacts"

export const URL_SETTINGS = "/settings"

export const URL_PROFILE = "/profile"

export const URL_NOTIFICATIONS = "/notifications"