import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

const CurrentUserContext = React.createContext();

const CurrentUserProvider = (props) => {

    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        async function getCurrentUser() {
            const currentUser = await Auth.currentAuthenticatedUser();
            setCurrentUser(currentUser);
        }
        getCurrentUser();
    }, [])

    if(currentUser == null){
        return <></>
    }

    return (
        <CurrentUserContext.Provider
            value={{
                currentUser
            }}
            {...props}
        />
    )
}

const useCurrentUser = () => {
    const context = React.useContext(CurrentUserContext);
    if (context === undefined) {
        throw new Error(`useCurrentUser must be used within a CurrentUserProvider`);
    }
    return context;
}

export { CurrentUserProvider, useCurrentUser };