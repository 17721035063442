import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
//import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from "react-i18next"

// https://en.wikipedia.org/wiki/Language_localisation
import translationenUS from "./locales/en-US/translation.json"
import translationfrCA from "./locales/fr-CA/translation.json"
import { isNull } from "lodash"

// the translations
const resources = {
  en: {
    translation: translationenUS,
  },
  fr: {
    translation: translationfrCA,
  },
}

//console.log(navigator.language)
//console.log(navigator.languages)
const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  if (isNull(navigator.language)) {
    //console.log("navigator.language is null, set default language to english")
    localStorage.setItem("I18N_LANGUAGE", "en")
  } else {
    //console.log("search language...")
    switch (navigator.language) {
      case "en":
      case "en-US":
        localStorage.setItem("I18N_LANGUAGE", "en")
        break
      case "fr":
      case "fr-FR":
        localStorage.setItem("I18N_LANGUAGE", "fr")
        break
      default:
        localStorage.setItem("I18N_LANGUAGE", "en")
        break
    }
  }
} 

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  //.use(intervalPlural)
  .init({
    debug: false,
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use english if detected lng is not available
    keySeparator: true, // we use keys in form messages.welcome
    interpolation: {
      escapeValue: true, // react already safes from xss
    },
    compatibilityJSON: "v3",
  })

export default i18n
