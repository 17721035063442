import Moment from "react-moment"
import moment from "moment"

export function addBusinessDays(originalDate, numDaysToAdd) {
  const Sunday = 0
  const Saturday = 6
  let daysRemaining = numDaysToAdd

  const newDate = new Date(originalDate)

  while (daysRemaining > 0) {
    newDate.setDate(newDate.getDate() + 1)
    //console.log("newDate", newDate)
    if (newDate.getDay() !== Sunday && newDate.getDay() !== Saturday) {
      daysRemaining--
    }
  }

  return newDate
}

/**
 * Get the difference in days from the reference datetime to the current datetime (Now).
 * 
 * @param {*} referenceDateTime the reference datetime
 * @returns This function returns the number of days between two dates.
 */
export function dateDiffInDaysFromNow(referenceDateTime) {
  let a = moment(new Date())
  let b = moment.utc(referenceDateTime).local()
  return a.diff(b, "days")
}

/**
 * Get the difference in days from the datetime to the current date (Now).
 * 
 * @param {*} referenceDateTime the reference datetime
 * @returns This function returns the number of days between two dates.
 */
export function dateDiffInDaysFromNowWithoutTime(referenceDateTime) {
  let a = moment(new Date()).startOf('day')
  let b = moment(referenceDateTime).startOf('day')
  return a.diff(b, "days")
}


