import React, { useEffect, useState } from "react"

import { withTranslation } from "react-i18next"

import { DropdownItem, DropdownMenu } from "reactstrap"
import { get, map } from "lodash"

// i18n
import i18n from "i18n"
import languages from "locales/languages"

/**
 * Language dropdown component
 */
const LanguageDropdownForMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    setSelectedLang(currentLanguage)
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <DropdownMenu className="language-switch dropdown-menu-end">
      {map(Object.keys(languages), key => (
        <DropdownItem key={key} onClick={() => changeLanguageAction(key)} className={`notify-item ${selectedLang === key ? "active" : "none"}`}>
          {/*<img
                src={get(languages, `${key}.flag`)}
                alt="alt-language"
                className="me-1"
                height="12"
              />
              */}
          <span className="align-middle">{get(languages, `${key}.label`)}</span>
        </DropdownItem>
      ))}
    </DropdownMenu>
  )
}

export default withTranslation()(LanguageDropdownForMenu)
