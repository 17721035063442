import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { useAlert } from "react-alert"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Collapse, Container, FormFeedback, Form, Input, Label, Row, Spinner } from "reactstrap"
import { isEmpty, isNil } from "lodash"
import { useFormik } from "formik"
import * as Yup from "yup"

import avatar from "assets/images/users/user.png"

import { useCurrentUser } from "contexts/current-user-context"
import { callAPI } from "helpers/lea-graphql_helper"
import { updateUser } from "graphql/mutations"
import { listUsers } from "graphql/queries"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function ProfilePage(props) {
  // Alert
  const customAlert = useAlert()

  // Context
  const user = useCurrentUser()

  // Access the client
  const queryClient = useQueryClient()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [dynamoUser, setDynamoUser] = useState({})

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  const { isError, isIdle, isLoading, isFetching, error } = useQuery(
    "listUsers-" + user.username,
    () =>
      firstValueFrom(
        callAPI("listUsers", listUsers, {
          filter: { email: { eq: user.currentUser.attributes.email } }, // TODOD : listusers ne supporte pas la recherche par cognitoId
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("ProfilPage.getUser.onSuccess:result", result)
        setDynamoUser(result.items[0])
      },
      onError: error => {
        console.error("ProfilPage.getUser.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }), { timeout: 0 })
      },
    }
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  async function handlerOnSubmit(entityUser) {
    //console.log("ProfilPage.handlerOnSubmit:entityUser", entityUser);
    let entityUserUpdated = await doUpdateUser(entityUser)
    if (!isNil(entityUserUpdated)) {
      // Success :
      setDynamoUser(entityUserUpdated)
    } else {
      // Error   : display a message
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error }))
      queryClient.invalidateQueries("listUsers-" + user.username)
    }
  }

  // TODO : tenantId & cognitoId ne font pas partis du UpdateUserInput
  function removeGeneratedProperties(input) {
    const { tenantId, cognitoId, createdAt, updatedAt, _deleted, _lastChangedAt, ...rest } = input
    return rest
  }

  async function doUpdateUser(entityUser) {
    //console.log("ProfilPage.doUpdateUser:entityUser", entityUser);
    const input = {
      ...entityUser,
      updateUser: user.currentUser.username,
    }
    //console.log("ProfilPage.doUpdateUser:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("ProfilPage.doUpdateUser:updateInput", updateInput)
    try {
      const result = await updateUserMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("ProfilPage.doUpdateUser:error", error)
      return null
    }
  }

  const updateUserMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateUser", updateUser, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: dynamoUser.username || "",
    },
    validationSchema: Yup.object({
      //username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      dynamoUser.username = isEmpty(values["username"]) ? null : values["username"]
      handlerOnSubmit(dynamoUser)
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Profile</title>
      </MetaTags>
      <Container fluid>
        {/* <h4>
          {props.t("Page.User.Profile")}
        </h4> */}
        {(isLoading || isFetching) && <Spinner className="ms-2" color="primary" />}
        {(!isLoading && !isFetching && !isError) &&
          <Fragment>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="ms-0 me-2">
                        <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{isEmpty(dynamoUser.username) ? user.currentUser.attributes.email : dynamoUser.username}</h5>
                          <p className="mb-1">{user.currentUser.attributes.email}</p>
                          <p className="mb-0">{user.currentUser.username}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <h4 className="card-title mb-2">{props.t("Page.User.Profile.Username.Title")}</h4>
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div className="form-group">
                    <Label className="form-label">{props.t("Page.User.Profile.Username")}</Label>
                    <Input
                      name="username"
                      // value={name}
                      className="form-control"
                      placeholder={props.t("Page.User.Profile.Username.Placeholder")}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={validation.touched.username && validation.errors.username ? true : false}
                    />
                    {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null}
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="primary" disabled={!(validation.isValid && validation.dirty)}>
                      {props.t("Page.User.Profile.Action.Update")}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Collapse isOpen={false}>
              <pre>{user != null && JSON.stringify(user, null, 2)}</pre>
            </Collapse>
          </Fragment>
        }
      </Container>
    </div>
  )
}

ProfilePage.propTypes = {
  t: PropTypes.any,
  props: PropTypes.any,
  user: PropTypes.any,
}

export default withRouter(withTranslation()(ProfilePage))
