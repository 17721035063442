import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button } from "reactstrap"
import { isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"

import { getOptionById } from "helpers/lea-options_helper"
import { taskStatusClasses } from "helpers/lea-status_helper"
import { URL_TASKS } from "helpers/lea-url_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function MandateDetailTaskTableRow(props) {
  const { task, optionsTaskType, users, onDelete } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  const handleViewTask = e => {
    //console.log("TaskTableRow.handleViewTask:taskId", taskId);
    e.preventDefault()
    props.history.push(URL_TASKS + "/" + task.id)
  }

  function handleDeleteTask(e) {
    //console.log("TaskTableRow.handleDeleteContact");
    e.preventDefault()
    toggleDeleteConfirmationForm() // Open the form
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("TaskTableRow.handleDeleteConfirmationFormOnSave:confirmationData", confirmationData);
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(task)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function assigneeDisplayName() {
    let usr = users.find(item => {
      return item.cognitoId === task.assignee
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={task.id}>
      <td className="no-print" valign="middle" width="10%">
        <Button className="me-2" size="sm" color="secondary" outline onClick={e => handleViewTask(e)}>
          <i className="mdi mdi-eye" />
        </Button>
        {!task.retired && (
          <Button size="sm" color="danger" className="me-2" outline onClick={e => handleDeleteTask(e)}>
            <i className="bx bx-trash-alt" />
          </Button>
        )}
      </td>
      <td valign="middle" width="30%">
        {getOptionById(optionsTaskType, task.taskType).name}
      </td>
      <td valign="middle" width="40%">
        {isEmpty(assigneeDisplayName()) ? props.t("Common.Text.User.Unassigned") : assigneeDisplayName()}
      </td>
      <td valign="middle" width="20%" className="text-end">
        <span className={"badge rounded-pill badge-soft-" + taskStatusClasses[task.status]}>
          {task.retired && (
            /* Strikethrough */
            <s>{props.t("Common.Enum.TaskStatus." + task.status)}</s>
          )}
          {!task.retired && (
            /* Not Strikethrough */
            <>{props.t("Common.Enum.TaskStatus." + task.status)}</>
          )}
        </span>
        {task.retired && <span className={"badge rounded-pill badge-soft-" + taskStatusClasses["RETIRED"]}>{props.t("Common.Enum.TaskStatus." + (task.retired ? "RETIRED" : task.status))}</span>}
      </td>
      {openDeleteConfirmationForm && <DeleteConfirmationForm id={task.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.MandateDetail.TaskView.Form.DeleteConfirmation.Title")} body={props.t("Page.MandateDetail.TaskView.Form.DeleteConfirmation.Body", { 0: getOptionById(optionsTaskType, task.taskType).name })} />}
    </tr>
  )
}

MandateDetailTaskTableRow.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  task: PropTypes.any,
  optionsTaskType: PropTypes.any,
  users: PropTypes.any,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(MandateDetailTaskTableRow))
