import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Row } from "reactstrap"
import { isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"

import { getOptionByValue } from "helpers/lea-options_helper"

//i18n
import { withTranslation, useTranslation } from "react-i18next"

// Component
function MandateDetailLocationView(props) {
  const { mandate, onEdit, onDelete } = props

  // Detect language change
  const { i18n } = useTranslation()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuLocation, setOpenMenuLocation] = useState(false)
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)
  const categoriesOptions = useMemo(() => { return initCategoryOptions() }, [i18n.language])
  
  // ----------------------------------------------------------------------------------------------------------------
  // Init function (for useMemo hook)
  // ----------------------------------------------------------------------------------------------------------------
  function initCategoryOptions() {
    //console.log("[initCategoryOptions] - params[]")
    let items = [
      { value: "terrain", label: "Terrain" },
      { value: "residentiel", label: "Résidentiel" },
      { value: "multifamilial", label: "Multifamilial" },
      { value: "commercial", label: "Commercial" },
      { value: "industriel", label: "Industriel" },
      { value: "agricole", label: "Agricole" },
    ]
    return items
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("[handleDeleteConfirmationFormOnSave] - params[confirmationData]", confirmationData)
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(mandate)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuLocation = useCallback(() => {
    setOpenMenuLocation(!openMenuLocation)
  }, [openMenuLocation])

  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.MandateDetail.LocationView")}</CardTitle>
          </div>
          {!mandate.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuLocation} toggle={toggleMenuLocation}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={onEdit}>{props.t("Page.MandateDetail.LocationView.Action.Edit")}</DropdownItem>
                {!isEmpty(location) && <DropdownItem onClick={toggleDeleteConfirmationForm}>{props.t("Page.MandateDetail.LocationView.Action.Delete")}</DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {openDeleteConfirmationForm && <DeleteConfirmationForm id={mandate.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.MandateDetail.LocationView.Form.DeleteConfirmation.Title")} body={props.t("Page.MandateDetail.LocationView.Form.DeleteConfirmation.Body")} />}
        {isEmpty(mandate?.location) && (
          <Row className="mt-2">
            <Col xl="12">
              <Alert color="danger">
                <i className="mdi mdi-alert-outline me-1" />
                {props.t("Page.MandateDetail.LocationView.Alert")}
              </Alert>
            </Col>
          </Row>
        )}
        {!isEmpty(mandate?.location) && (
          <Row className="mt-2">
            <Col xl="4" xs="4">
              <div>
                <Label>
                  <i className="dripicons-location me-1 text-primary" />
                  {props.t("Common.Label.Location.Address")}
                </Label>
                <div>
                  <p className="mb-0">{mandate.location?.address}</p>
                  <p className="mb-0">
                    {mandate.location?.city} {mandate.location?.stateOrProvince}
                  </p>
                  <p className="mb-0">{mandate.location?.zipOrPostalCode}</p>
                </div>
              </div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-map-alt me-1 text-primary" />
                {props.t("Common.Label.Location.LotNumber")}
              </Label>
              <div>
                {isEmpty(mandate.location?.lotNumber) && (
                  <div className="mt-0 text-danger">
                    <i className="mdi mdi-alert-outline me-1" />
                    {props.t("Common.Text.Alert.LotNumber")}
                  </div>
                )}
                {!isEmpty(mandate.location?.lotNumber) && <p className="mb-0">{mandate.location.lotNumber}</p>}
              </div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-list-ol me-1 text-primary" />
                {props.t("Common.Label.Location.Category")}
              </Label>
              <div>
                <p className="mb-0">{ getOptionByValue(categoriesOptions, mandate.location.category).label }</p>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

MandateDetailLocationView.propTypes = {
  t: PropTypes.any,
  mandate: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(MandateDetailLocationView))
