import { API, graphqlOperation } from "aws-amplify"
import { updateTask } from "graphql/mutations"
import { removeGeneratedProperties } from "helpers/lea-graphql_helper"

// Async function to archive the task
export async function archiveTask(task) {
  //console.log("TaskService.archiveTask:task", task)

  // 1) Set the "Retired" field to true for all task
  const deleteTask = async task => {
    const input = {
      ...task,
      retired: true,
    }
    //console.log("TaskService.archiveTask.deleteTask:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("TaskService.archiveTask.deleteTask:updateInput", updateInput)
    return await API.graphql(graphqlOperation(updateTask, { input: updateInput }))
  }
  // 2)  Execute
  deleteTask(task).then(() => {})
}
