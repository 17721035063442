import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, Card, CardBody, Col, Form, FormGroup, FormFeedback, Input, Label, Row } from "reactstrap";
import { isEmpty } from "lodash";
import { Formik } from "formik";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from "yup";

import { PHONE_NO_REGEX} from "helpers/lea-phonenumber_helper";
import { ContactType } from "models";

// i18n
import { withTranslation } from "react-i18next";

// Component
function EditCompanyView(props) {

    const { companyInformation, onCancel, onSave, edit } = props

    // ----------------------------------------------------------------------------------------------------------------
    // Rendering view
    // ----------------------------------------------------------------------------------------------------------------
    return (
        <Card>
            <CardBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        contactType: ContactType.COMPANY,
                        fullName: isEmpty(companyInformation?.contact?.fullName) ? "" : `${companyInformation.contact.fullName}`,
                        email: isEmpty(companyInformation?.contact?.email) ? "" : `${companyInformation.contact.email}`,
                        phoneNumber: isEmpty(companyInformation?.contact?.phoneNumber) ? "" : `${companyInformation.contact.phoneNumber}`,
                        address: isEmpty(companyInformation?.contact?.address) ? "" : `${companyInformation.contact.address}`,
                        city: isEmpty(companyInformation?.contact?.city) ? "" : `${companyInformation.contact.city}`,
                        stateOrProvince: isEmpty(companyInformation?.contact?.stateOrProvince) ? "" : `${companyInformation.contact.stateOrProvince}`,
                        zipOrPostalCode: isEmpty(companyInformation?.contact?.zipOrPostalCode) ? "" : `${companyInformation.contact.zipOrPostalCode}`,
                    }}
                    validationSchema={Yup.object({
                        fullName: Yup.string().required(props.t("Common.Text.Contact.Name.Validation.Required")),
                        email: Yup.string()/*.required(props.t("Common.Text.Contact.Email.Validation.Required"))*/.email(props.t("Common.Text.Contact.Email.Validation")).max(255),
                        phoneNumber: Yup.string()/*.required(props.t("Common.Text.Contact.PhoneNumber.Validation.Required"))*/.matches(PHONE_NO_REGEX, props.t("Common.Text.Contact.PhoneNumber.Validation")).nullable()
                    })}
                    onSubmit={(values) => {
                        //console.log(JSON.stringify(values, null, 2))
                        let newId = uuidv4();
                        const updateContactInfo = {
                            id: isEmpty(companyInformation?.contact?.id) ? newId : companyInformation.contact.id,
                            contactType: values["contactType"],
                            fullName: values["fullName"],
                            email: values["email"] || null,
                            phoneNumber: values["phoneNumber"] || null,
                            address: values["address"] || null,
                            city: values["city"] || null,
                            stateOrProvince: values["stateOrProvince"] || null,
                            zipOrPostalCode: values["zipOrPostalCode"] || null
                        };
                        companyInformation.contact = { ...updateContactInfo}
                        onSave(companyInformation)
                    }}>
                    {
                        formik => (
                            <Form onSubmit={formik.handleSubmit}>
                                <FormGroup row>
                                    <Label for="inputFullName" sm="4">{props.t("Common.Label.Contact.Name")}</Label>
                                    <Col sm="8">
                                        <Input
                                            readOnly={!edit}
                                            id="inputFullName"
                                            name="fullName"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fullName}
                                            invalid={
                                                formik.touched.fullName && formik.errors.fullName ? true : false
                                            }
                                        />
                                        {formik.touched.fullName && formik.errors.fullName ? (
                                            <FormFeedback type="invalid">{formik.errors.fullName}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4" for="inputEmail">{props.t("Common.Label.Contact.Email")}</Label>
                                    <Col sm="8">
                                        <Input
                                            readOnly={!edit}
                                            id="inputEmail"
                                            name="email"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            invalid={
                                                formik.touched.email && formik.errors.email ? true : false
                                            }
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4" for="inputPhoneNumber">{props.t("Common.Label.Contact.PhoneNumber")}</Label>
                                    <Col sm="8">
                                        <Input
                                            readOnly={!edit}
                                            id="inputPhoneNumber"
                                            name="phoneNumber"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phoneNumber || ""}
                                            invalid={
                                                formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false
                                            }
                                        />
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                            <FormFeedback type="invalid">{formik.errors.phoneNumber}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4" for="inputAddress">{props.t("Common.Label.Contact.Address")}</Label>
                                    <Col sm="8">

                                        <Input
                                            readOnly={!edit}
                                            id="inputAddress"
                                            name="address"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address || ""}
                                            invalid={
                                                formik.touched.address && formik.errors.address ? true : false
                                            }
                                        />
                                        {formik.touched.address && formik.errors.address ? (
                                            <FormFeedback type="invalid">{formik.errors.address}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4" for="inputCity">{props.t("Common.Label.Contact.City")}</Label>
                                    <Col sm="8">
                                        <Input
                                            readOnly={!edit}
                                            id="inputCity"
                                            name="city"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.city || ""}
                                            invalid={
                                                formik.touched.city && formik.errors.city ? true : false
                                            }
                                        />
                                        {formik.touched.city && formik.errors.city ? (
                                            <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4" for="inputStateOrProvince">{props.t("Common.Label.Contact.StateOrProvince")}</Label>
                                    <Col sm="8">
                                        <Input
                                            readOnly={!edit}
                                            id="inputStateOrProvince"
                                            name="stateOrProvince"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.stateOrProvince || ""}
                                            invalid={
                                                formik.touched.stateOrProvince && formik.errors.stateOrProvince ? true : false
                                            }
                                        />
                                        {formik.touched.stateOrProvince && formik.errors.stateOrProvince ? (
                                            <FormFeedback type="invalid">{formik.errors.stateOrProvince}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm="4" for="inputZipOrPostalCode">{props.t("Common.Label.Contact.ZipOrPostalCode")}</Label>
                                    <Col sm="8">
                                        <Input
                                            readOnly={!edit}
                                            id="inputZipOrPostalCode"
                                            name="zipOrPostalCode"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.zipOrPostalCode || ""}
                                            invalid={
                                                formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? true : false
                                            }
                                        />
                                        {formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? (
                                            <FormFeedback type="invalid">{formik.errors.zipOrPostalCode}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <div>
                                        <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">{props.t("Common.Action.Save")}</Button>
                                        <Button className="me-2" color="secondary" outline onClick={onCancel}>{props.t("Common.Action.Cancel")}</Button>
                                    </div>
                                </Row>
                            </Form>
                        )
                    }
                </Formik>
            </CardBody>
        </Card >
    )
}

EditCompanyView.propTypes = {
    t: PropTypes.any,
    companyInformation: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    edit: PropTypes.bool
}

EditCompanyView.defaultProps = {
    edit: true,
}

export default withTranslation()(EditCompanyView)
