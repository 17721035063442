import React from "react";
import PropTypes from "prop-types";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Uploader from "./uploader";

//i18n
import { withTranslation } from "react-i18next";

function ImageModalUploader(props) {
    
    const { toggle, onAddClick, maxFiles } = props

    return (
        <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                {props.t("Component.Uploader.Title", { count: maxFiles })}
            </ModalHeader>
            <ModalBody>
                <Uploader maxFiles={maxFiles} onAddClick={onAddClick} />
            </ModalBody>
        </Modal>
    )
}

ImageModalUploader.propTypes = {
    t: PropTypes.any,
    onAddClick: PropTypes.func,
    maxFiles: PropTypes.number,
    toggle: PropTypes.func,
}

export default withTranslation()(ImageModalUploader)
