// Status based on Enum models.RequestStatus
export const requestStatusClasses = {
    PENDING: "danger",
    INPROGRESS: "warning",
    COMPLETED: "success",
    RETIRED: "secondary"
}

// Status based on Enum models.MandateStatus
export const mandateStatusClasses = {
    PENDING: "danger",
    INPROGRESS: "warning",
    COMPLETED: "success",
    RETIRED: "secondary"
}

// Status based on Enum models.TaskStatus 
export const taskStatusClasses = {
    PENDING: "danger",
    STARTED: "warning",
    COMPLETED: "success",
    RETIRED: "secondary"
}

// Status based on Enum models.InvoiceStatus 
export const invoiceStatusClasses = {
    DRAFT: "danger",
    SENT: "warning",
    PAID: "success",
    RETIRED: "secondary"
}