import React, { useMemo } from "react"
import PropTypes from "prop-types"

import Select from "react-dropdown-select"
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, FormFeedback, Input, Label, Row } from "reactstrap"
import { isEmpty, isNil } from "lodash"
import { Formik } from "formik"
import * as Yup from "yup"

// i18n
import { withTranslation, useTranslation } from "react-i18next"

// Component
function MandateDetailLocationViewEdit(props) {
  const { locationinfo, onSave, onCancel } = props

  // Detect language change
  const { i18n } = useTranslation()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const categoriesOptions = useMemo(() => { return initCategoryOptions() }, [i18n.language])
  
  // ----------------------------------------------------------------------------------------------------------------
  // Init function (for useMemo hook)
  // ----------------------------------------------------------------------------------------------------------------
  function initCategoryOptions() {
    //console.log("[initCategoryOptions] - params[]")
    let items = [
      { value: "terrain", label: "Terrain" },
      { value: "residentiel", label: "Résidentiel" },
      { value: "multifamilial", label: "Multifamilial" },
      { value: "commercial", label: "Commercial" },
      { value: "industriel", label: "Industriel" },
      { value: "agricole", label: "Agricole" },
    ]
    return items
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <Formik
          /*enableReinitialize={true}*/
          initialValues={{
            category: isEmpty(locationinfo?.category) ? "" : locationinfo.category,
            //subcategory: isEmpty(locationinfo?.subcategory) ? "" : locationinfo.subcategory,
            address: isEmpty(locationinfo?.address) ? "" : locationinfo.address,
            city: isEmpty(locationinfo?.city) ? "" : locationinfo.city,
            stateOrProvince: isEmpty(locationinfo?.stateOrProvince) ? "" : locationinfo.stateOrProvince,
            zipOrPostalCode: isEmpty(locationinfo?.zipOrPostalCode) ? "" : locationinfo.zipOrPostalCode,
            lotNumber: isEmpty(locationinfo?.lotNumber) ? "" : locationinfo.lotNumber,
          }}
          validationSchema={Yup.object({
            category: Yup.string().required(props.t("Common.Text.Location.Category.Validation.Required")),
          })}
          onSubmit={values => {
            //console.log("EditLocationView.onSubmit:values", values)
            const updateinfo = {
              category: isEmpty(values["category"]) ? null : values["category"],
              //subcategory: values["subcategory"],
              address: isEmpty(values["address"]) ? null : values["address"],
              city: isEmpty(values["city"]) ? null : values["city"],
              stateOrProvince: isEmpty(values["stateOrProvince"]) ? null : values["stateOrProvince"],
              zipOrPostalCode: isEmpty(values["zipOrPostalCode"]) ? null : values["zipOrPostalCode"],
              lotNumber: isEmpty(values["lotNumber"]) ? null : values["lotNumber"],
            }
            //console.log("EditLocationView.onSubmit:updateinfo", updateinfo)
            onSave(updateinfo)
          }}
        >
          {formik => (
            <React.Fragment>
              <Form onSubmit={formik.handleSubmit}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <CardTitle>{props.t("Page.MandateDetail.LocationViewEdit")}</CardTitle>
                    <hr className="mt-0" />
                  </div>
                </div>
                <Row>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Location.Category")}</Label>
                    <Col sm="6">
                      <Select
                        className="input"
                        name="category"
                        values={categoriesOptions.filter(item => {
                          return item.value === formik.values.category
                        })}
                        options={categoriesOptions}
                        onChange={values => formik.setFieldValue("category", isNil(values[0]?.value) ? "" : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                        invalid={formik.touched.category ? true : false}
                      />
                      {formik.errors.category ? <div className="text-danger font-size-10 mt-1">{formik.errors.category}</div> : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2" for="inputLotNumber">
                      {props.t("Common.Label.Location.LotNumber")}
                    </Label>
                    <Col sm="6">
                      <Input id="inputLotNumber" name="lotNumber" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lotNumber || ""} invalid={formik.touched.lotNumber && formik.errors.lotNumber ? true : false} />
                      {formik.touched.lotNumber && formik.errors.lotNumber ? <FormFeedback type="invalid">{formik.errors.lotNumber}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2" for="inputAddress">
                      {props.t("Common.Label.Location.Address")}
                    </Label>
                    <Col sm="6">
                      <Input id="inputAddress" name="address" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address || ""} invalid={formik.touched.address && formik.errors.address ? true : false} />
                      {formik.touched.address && formik.errors.address ? <FormFeedback type="invalid">{formik.errors.address}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2" for="inputCity">
                      {props.t("Common.Label.Location.City")}
                    </Label>
                    <Col sm="6">
                      <Input id="inputCity" name="city" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city || ""} invalid={formik.touched.city && formik.errors.city ? true : false} />
                      {formik.touched.city && formik.errors.city ? <FormFeedback type="invalid">{formik.errors.city}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2" for="inputStateOrProvince">
                      {props.t("Common.Label.Location.StateOrProvince")}
                    </Label>
                    <Col sm="6">
                      <Input id="inputStateOrProvince" name="stateOrProvince" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stateOrProvince || ""} invalid={formik.touched.stateOrProvince && formik.errors.stateOrProvince ? true : false} />
                      {formik.touched.stateOrProvince && formik.errors.stateOrProvince ? <FormFeedback type="invalid">{formik.errors.stateOrProvince}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2" for="inputZipOrPostalCode">
                      {props.t("Common.Label.Location.ZipOrPostalCode")}
                    </Label>
                    <Col sm="6">
                      <Input id="inputZipOrPostalCode" name="zipOrPostalCode" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zipOrPostalCode || ""} invalid={formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? true : false} />
                      {formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? <FormFeedback type="invalid">{formik.errors.zipOrPostalCode}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                </Row>
                <hr className="mt-0" />
                <Row>
                  <Col className="no-print">
                    <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">
                      {props.t("Common.Action.Save")}
                    </Button>
                    <Button className="me-2" color="secondary" outline onClick={onCancel}>
                      {props.t("Common.Action.Cancel")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

MandateDetailLocationViewEdit.propTypes = {
  t: PropTypes.any,
  locationinfo: PropTypes.any,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default withTranslation()(MandateDetailLocationViewEdit)
