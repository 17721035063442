import { API, graphqlOperation } from 'aws-amplify';
import { from, map, throwError } from "rxjs";

export function callAPI(funcName, func, query) {
  //console.log(funcName, func, query)
  return from(API.graphql(graphqlOperation(func, query))).pipe(
    map(res => {
      if (res.errors) {
        const { message } = res.errors[0] || 'Error..'
        throwError(() => new Error(message))
      }
      //console.log(res.data)
      return res.data[funcName];
    })
  );
}

export function callAPI2(funcName, func, query) {
  //console.log(funcName, func, query)
  return from(API.graphql(graphqlOperation(func, query))).pipe(
    map(res => {
      if (res.errors) {
        const { message } = res.errors[0] || 'Error..'
        throwError(() => new Error(message))
      }
      //console.log(res.data)
      return res.data
    })
  );
}

export function removeGeneratedProperties(input) {
  const { createdAt, updatedAt, _deleted, _lastChangedAt, ...rest } = input
  return rest
};

export function removePropertiesThatNotExistInRequestClient(input) {
  //console.log("RequestDetailPage.removePropertiesThatNotExistInRequestClient:input", input);
  const { _version, mandates, addresses, requests, emailDenom, fullNameDenom, phoneNumberDenom, stateOrProvinceDenom, zipOrPostalCodeDenom, ...rest } = input
  return rest
};

