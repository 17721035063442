import React, { Fragment, useCallback, useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { withRouter } from "react-router-dom"
import { CardBody, CardTitle, Col, Row, Spinner } from "reactstrap"
import { isEmpty, isNil } from "lodash"

import CompanyView from "./SettingsCompanyView"
import EditCompanyView from "./SettingsCompanyViewEdit"

import { useCurrentUser } from "contexts/current-user-context"

import { Storage } from "aws-amplify"
import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { createSettingsCompanyInformation, updateSettingsCompanyInformation } from "graphql/mutations"
import { listSettingsCompanyInformations } from "graphql/queries"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsCompanyPage(props) {
  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [companyInformation, setCompanyInformation] = useState({})
  const [openEditCompanyView, setOpenEditCompanyView] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get company information
  const { isLoading, isIdle, isFetching, isError, error } = useQuery("listSettingsCompanyInformations", () => firstValueFrom(callAPI("listSettingsCompanyInformations", listSettingsCompanyInformations)), {
    retry: false,
    onSuccess: result => {
      //console.log("SettingsCompanyPage.listSettingsCompanyInformations.onSuccess:result", result)
      setCompanyInformation(isEmpty(result?.items) ? {} : result.items[0])
    },
    onError: error => {
      console.error("SettingsCompanyPage.listSettingsCompanyInformations.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleCompanyViewOnEdit() {
    //console.log("SettingsCompanyPage.handleCompanyViewOnEdit");
    toggleEditCompanyView() // Open EditCompanyView
  }

  function handleEditCompanyViewOnCancel() {
    //console.log("SettingsCompanyPage.handleEditCompanyViewOnCancel");
    toggleEditCompanyView() // Close EditCompanyView
  }

  function handleEditCompanyViewOnSave(companyData) {
    //console.log("SettingsCompanyPage.handleEditCompanyViewOnCancel:companyData", companyData);
    toggleEditCompanyView() // Close EditCompanyView
    if (isNil(companyData.id)) {
      doCreateSettingsCompanyInformation(companyData)
    } else {
      doUpdateSettingsCompanyInformation(companyData)
    }
  }

  function doCreateSettingsCompanyInformation(companyData) {
    //console.log("SettingsCompanyPage.doCreateSettingsCompanyInformation:companyData", companyData)
    const input = {
      ...companyData,
      creator: username,
    }
    //console.log("SettingsCompanyPage.doCreateSettingsCompanyInformation:input", input);
    let updateInput = removeGeneratedProperties(input)
    //console.log("SettingsCompanyPage.doCreateSettingsCompanyInformation:updateInput", updateInput)
    createCompanyMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("SettingsCompanyPage.doCreateSettingsCompanyInformation.createCompanyMutation.onSuccess:result", result)
        setCompanyInformation(result)
        //queryClient.invalidateQueries('listSettingsCompanyInformations')
      },
      onError: error => {
        console.error("SettingsCompanyPage.doCreateSettingsCompanyInformation.createCompanyMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsCompanyInformations")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const createCompanyMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createSettingsCompanyInformation", createSettingsCompanyInformation, {
        input: {
          ...data,
        },
      })
    )
  )

  function doUpdateSettingsCompanyInformation(companyData) {
    //console.log("SettingsCompanyPage.doUpdateSettingsCompanyInformation:companyData", companyData);
    const input = {
      ...companyData,
      updateUser: username,
    }
    //console.log("SettingsCompanyPage.doUpdateSettingsCompanyInformation:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("SettingsCompanyPage.doUpdateSettingsCompanyInformation:updateInput", updateInput)
    updateCompanyMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("SettingsCompanyPage.doUpdateSettingsCompanyInformation.updateCompanyMutation.onSuccess:result", result)
        setCompanyInformation(result)
        //queryClient.invalidateQueries('listSettingsCompanyInformations')
      },
      onError: error => {
        console.error("SettingsCompanyPage.doUpdateSettingsCompanyInformation.updateCompanyMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsCompanyInformations")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const updateCompanyMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateSettingsCompanyInformation", updateSettingsCompanyInformation, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleEditCompanyView = useCallback(() => {
    setOpenEditCompanyView(!openEditCompanyView)
  }, [openEditCompanyView])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Fragment>
      {isFetching && <Spinner className="ms-2" color="primary" />}
      {!isLoading && !isFetching && !isError && (
        <CardBody className="bg-light">
          <CardTitle className="text-dark">{props.t("Page.Settings.CompanyView")} </CardTitle>
          <div className="mt-0">
            <p>{props.t("Page.Settings.CompanyView.Subtitle")}</p>
            <hr />
            <Row>
              {!openEditCompanyView && (
                <Col xl={12}>
                  <CompanyView companyInformation={companyInformation} onEdit={handleCompanyViewOnEdit} />
                </Col>
              )}
              {openEditCompanyView && (
                <Col xl={6}>
                  <EditCompanyView companyInformation={companyInformation} onSave={handleEditCompanyViewOnSave} onCancel={handleEditCompanyViewOnCancel} />
                </Col>
              )}
            </Row>
          </div>
        </CardBody>
      )}
    </Fragment>
  )
}

SettingsCompanyPage.propTypes = {
  t: PropTypes.any,
  addImages: PropTypes.any,
  contactinfo: PropTypes.any,
  onSave: PropTypes.func,
}

export default withRouter(withTranslation()(SettingsCompanyPage))
