import React from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap"

import { isEmpty } from "lodash"
import { Formik } from "formik"
import * as Yup from "yup"

//i18n
import { withTranslation } from "react-i18next"

// Component
function InvoiceDetailInvoiceRecordPaymentCard(props) {
  const { invoice, onCancel, onSave } = props

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card color="success" className="border mt-3" outline>
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={{
            //paidDate: isEmpty(invoice?.paidDate) ? "" : moment(invoice.paidDate).format('YYYY-MM-DDTHH:mm'),
            paidDate: isEmpty(invoice?.paidDate) ? "" : moment(invoice.paidDate).format("YYYY-MM-DD"),
          }}
          validationSchema={Yup.object({})}
          onSubmit={values => {
            //alert(JSON.stringify(values, null, 2))
            //invoice.paidDate = new Date(values["paidDate"]).toJSON()
            invoice.paidDate = new Date(values["paidDate"]).toISOString().split("T")[0]
            onSave(invoice)
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup row>
                <Label for="inputPaidDate" sm="2">
                  {props.t("Common.Label.Date.PaidDate")}
                </Label>
                <Col sm="2">
                  <Input id="inputPaidDate" name="paidDate" type="date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.paidDate} invalid={formik.touched.paidDate && formik.errors.paidDate ? true : false} />
                  {formik.touched.paidDate && formik.errors.paidDate ? <FormFeedback type="invalid">{formik.errors.paidDate}</FormFeedback> : null}
                </Col>
              </FormGroup>
              <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">
                {props.t("Common.Action.Save")}
              </Button>
              <Button className="me-2" color="secondary" outline onClick={onCancel}>
                {props.t("Common.Action.Cancel")}
              </Button>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

InvoiceDetailInvoiceRecordPaymentCard.propTypes = {
  t: PropTypes.any,
  invoice: PropTypes.any,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default withRouter(withTranslation()(InvoiceDetailInvoiceRecordPaymentCard))
