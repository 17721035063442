import React from "react"
import PropTypes from "prop-types"

import { Route, Switch, withRouter } from "react-router-dom"
import { URL_DESKTOP } from "helpers/lea-url_helper"

import DesktopPage from "./DesktopPage"
import Page404 from "components/Page404/Page404"

// Component
function DesktopRouter(props) {
  return (
    <Switch>
      <Route exact path={URL_DESKTOP} component={DesktopPage} />
      <Route exact from={`${props.match.path}/:Id`} component={Page404} />
    </Switch>
  )
};

DesktopRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(DesktopRouter);


