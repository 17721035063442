import { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import { isEmpty } from "lodash";

const PHONE_NO_REGEX = /^(1|)[2-9]\d{2}[2-9]\d{6}$/; // AWS AppSync Validation

function formatPhoneNumberAsLocal(input) {
    return isEmpty(input) ? "" : isPossiblePhoneNumber(input) ? formatPhoneNumber(input) : formatPhoneNumber('+1' + input)
}

export { formatPhoneNumberAsLocal, PHONE_NO_REGEX }

