import React, { useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { useMutation } from "react-query"
import { withRouter } from "react-router-dom"
import { Alert, Button, Card, CardBody, CardLink, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { Formik } from "formik"
import { includes, isEmpty, isNil } from "lodash"
import * as Yup from "yup"

import { PHONE_NO_REGEX } from "helpers/lea-phonenumber_helper"
import { URL_CONTACTS } from "helpers/lea-url_helper"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { updateMandateContact } from "graphql/mutations"
import { ContactRole } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { Trans, withTranslation } from "react-i18next"

// Component
function MandateDetailContactViewEdit(props) {
  const { username, mandateContact, onSave, onCancel } = props

  // Alert
  const customAlert = useAlert()

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleViewContactOnClick(e) {
    //console.log("EditMandateContactView.handleViewContactOnClick")
    e.preventDefault()
    props.history.push(URL_CONTACTS + "/" + mandateContact.contactId)
  }

  function handleCancelOnClick(e) {
    //console.log("EditMandateContactView.handleCancelOnClick")
    e.preventDefault()
    onCancel()
  }

  async function handleOnSubmit(entityMandateContact) {
    //console.log("EditMandateContactView.handleOnSubmit:entityMandateContact", entityMandateContact)
    let entityMandateContactUpdate = await doUpdateMandateContact(entityMandateContact)
    if (isNil(entityMandateContactUpdate)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de mettre à jour le contact" }), { timeout: 0 })
      return
    }
    onSave(entityMandateContactUpdate)
  }

  async function doUpdateMandateContact(entityMandateContact) {
    //console.log("EditMandateContactView.doUpdateMandateContact:entityMandateContact", entityMandateContact);
    const input = {
      ...entityMandateContact,
      updateUser: username,
    }
    //console.log("EditMandateContactView.doUpdateMandateContact:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("EditMandateContactView.doUpdateMandateContact:updateInput", updateInput)
    try {
      const result = await updateMandateContactMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("EditMandateContactView.doUpdateMandateContact:error", error)
      return null
    }
  }

  const updateMandateContactMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateMandateContact", updateMandateContact, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <Formik
          /* enableReinitialize={true} */
          initialValues={{
            /*contactType: isEmpty(contact?.contactType) ? "PERSON" : `${contactinfo.contact.contactType}`,*/
            fullName: isEmpty(mandateContact?.contact?.fullName) ? "" : mandateContact?.contact?.fullName,
            email: isEmpty(mandateContact?.contact?.email) ? "" : mandateContact?.contact?.email,
            phoneNumber: isEmpty(mandateContact?.contact?.phoneNumber) ? "" : mandateContact?.contact?.phoneNumber,
            phoneExtension: isEmpty(mandateContact?.contact?.phoneExtension) ? "" : mandateContact?.contact?.phoneExtension,
            phoneNumber2: isEmpty(mandateContact?.contact?.phoneNumber2) ? "" : mandateContact?.contact?.phoneNumber2,
            phoneExtension2: isEmpty(mandateContact?.contact?.phoneExtension2) ? "" : mandateContact?.contact?.phoneExtension2,
            address: isEmpty(mandateContact?.contact?.address) ? "" : mandateContact?.contact?.address,
            city: isEmpty(mandateContact.contact?.city) ? "" : mandateContact?.contact?.city,
            stateOrProvince: isEmpty(mandateContact?.contact?.stateOrProvince) ? "" : mandateContact?.contact?.stateOrProvince,
            zipOrPostalCode: isEmpty(mandateContact?.contact?.zipOrPostalCode) ? "" : mandateContact?.contact?.zipOrPostalCode,
            checkedACCOUNTANT: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.ACCOUNTANT) ? true : false,
            checkedAGENT: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.AGENT) ? true : false,
            checkedAGENCY: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.AGENCY) ? true : false,
            checkedBILLING: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.BILLING) ? true : false,
            checkedBUYER: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.BUYER) ? true : false,
            checkedCITY: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.CITY) ? true : false,
            checkedCLIENT: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.CLIENT) ? true : false,
            checkedDIRECTOR: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.DIRECTOR) ? true : false,
            checkedFINANCIALINSTITUTION: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.FINANCIALINSTITUTION) ? true : false,
            checkedGOVERNMENT: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.GOVERNMENT) ? true : false,
            checkedLAWYER: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.LAWYER) ? true : false,
            checkedMANAGER: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.MANAGER) ? true : false,
            checkedMINISTRY: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.MINISTRY) ? true : false,
            checkedNOTARY: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.NOTARY) ? true : false,
            checkedOWNER: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.OWNER) ? true : false,
            checkedPRESIDENT: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.PRESIDENT) ? true : false,
            checkedREALESTATEAGENT: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.REALESTATEAGENT) ? true : false,
            checkedREALTOR: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.REALTOR) ? true : false,
            checkedREQUESTOR: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.REQUESTOR) ? true : false,
            checkedSECRETARY: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.SECRETARY) ? true : false,
            checkedSYNDICATE: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.SYNDICATE) ? true : false,
            checkedVENDOR: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.VENDOR) ? true : false,
            checkedVICEPRESIDENT: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.VICEPRESIDENT) ? true : false,
            checkedOTHER: includes(isEmpty(mandateContact?.roles) ? [] : mandateContact?.roles, ContactRole.OTHER) ? true : false,
          }}
          validationSchema={Yup.object({
            fullName: Yup.string().required(props.t("Common.Text.Contact.Name.Validation.Required")),
            email: Yup.string().email(props.t("Common.Text.Contact.Email.Validation")).max(255),
            phoneNumber: Yup.string().matches(PHONE_NO_REGEX, props.t("Common.Text.Contact.PhoneNumber.Validation")).nullable(),
            phoneNumber2: Yup.string().matches(PHONE_NO_REGEX, props.t("Common.Text.Contact.PhoneNumber.Validation")).nullable(),
          })}
          onSubmit={values => {
            //console.log("onSubmit:values", values);
            /*contactType: values["contactType"],*/
            let contactToUpdate = {
              id: mandateContact.contactId,
              fullName: isEmpty(values["fullName"]) ? null : values["fullName"],
              email: isEmpty(values["email"]) ? null : values["email"],
              phoneNumber: isEmpty(values["phoneNumber"]) ? null : values["phoneNumber"],
              phoneExtension: isEmpty(values["phoneExtension"]) ? null : values["phoneExtension"],
              phoneNumber2: isEmpty(values["phoneNumber2"]) ? null : values["phoneNumber2"],
              phoneExtension2: isEmpty(values["phoneExtension2"]) ? null : values["phoneExtension2"],
              address: isEmpty(values["address"]) ? null : values["address"],
              city: isEmpty(values["city"]) ? null : values["city"],
              stateOrProvince: isEmpty(values["stateOrProvince"]) ? null : values["stateOrProvince"],
              zipOrPostalCode: isEmpty(values["zipOrPostalCode"]) ? null : values["zipOrPostalCode"],
            }
            let roles = []
            if (values["checkedACCOUNTANT"]) {
              roles.push(ContactRole.ACCOUNTANT)
            }
            if (values["checkedAGENT"]) {
              roles.push(ContactRole.AGENT)
            }
            if (values["checkedAGENCY"]) {
              roles.push(ContactRole.AGENCY)
            }
            if (values["checkedBILLING"]) {
              roles.push(ContactRole.BILLING)
            }
            if (values["checkedBUYER"]) {
              roles.push(ContactRole.BUYER)
            }
            if (values["checkedCITY"]) {
              roles.push(ContactRole.CITY)
            }
            if (values["checkedCLIENT"]) {
              roles.push(ContactRole.CLIENT)
            }
            if (values["checkedDIRECTOR"]) {
              roles.push(ContactRole.DIRECTOR)
            }
            if (values["checkedFINANCIALINSTITUTION"]) {
              roles.push(ContactRole.FINANCIALINSTITUTION)
            }
            if (values["checkedGOVERNMENT"]) {
              roles.push(ContactRole.GOVERNMENT)
            }
            if (values["checkedLAWYER"]) {
              roles.push(ContactRole.LAWYER)
            }
            if (values["checkedMANAGER"]) {
              roles.push(ContactRole.MANAGER)
            }
            if (values["checkedMINISTRY"]) {
              roles.push(ContactRole.MINISTRY)
            }
            if (values["checkedNOTARY"]) {
              roles.push(ContactRole.NOTARY)
            }
            if (values["checkedOWNER"]) {
              roles.push(ContactRole.OWNER)
            }
            if (values["checkedPRESIDENT"]) {
              roles.push(ContactRole.PRESIDENT)
            }
            if (values["checkedREALESTATEAGENT"]) {
              roles.push(ContactRole.REALESTATEAGENT)
            }
            if (values["checkedREALTOR"]) {
              roles.push(ContactRole.REALTOR)
            }
            if (values["checkedREQUESTOR"]) {
              roles.push(ContactRole.REQUESTOR)
            }
            if (values["checkedSECRETARY"]) {
              roles.push(ContactRole.SECRETARY)
            }
            if (values["checkedSYNDICATE"]) {
              roles.push(ContactRole.SYNDICATE)
            }
            if (values["checkedVENDOR"]) {
              roles.push(ContactRole.VENDOR)
            }
            if (values["checkedVICEPRESIDENT"]) {
              roles.push(ContactRole.VICEPRESIDENT)
            }
            if (values["checkedOTHER"]) {
              roles.push(ContactRole.OTHER)
            }

            mandateContact.roles = [...roles]
            mandateContact.contact = { ...contactToUpdate }
            //console.log("requestcontact", requestContact)
            handleOnSubmit(mandateContact)
          }}
        >
          {formik => (
            <React.Fragment>
              <Form onSubmit={formik.handleSubmit}>
                <CardTitle className="mb-2">{props.t("Page.MandateDetail.ContactViewEdit.Title")}</CardTitle>
                <div className="mb-2">{props.t("Page.MandateDetail.ContactViewEdit.Subtitle")}</div>
                <Alert className="mb-2" color="danger">
                  <Trans
                    defaults={props.t("Page.MandateDetail.ContactViewEdit.Alert")} // optional defaultValue
                    components={{ bold: <strong /> }}
                  />
                  <CardLink onClick={e => handleViewContactOnClick(e)}>&nbsp;{props.t("Common.Label.Action")}</CardLink>
                </Alert>
                <hr className="mt-0" />
                <Row>
                  <Col xl="5" xs="12">
                    <FormGroup row>
                      <Label sm="3" for="inputFullName">
                        {props.t("Common.Label.Contact.Name")}
                      </Label>
                      <Col sm="8">
                        <Input
                          id="inputFullName"
                          name="fullName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.fullName || ""}
                          invalid={
                            /*formik.touched.fullName && formik.errors.fullName ? true : false*/
                            isEmpty(formik.values.fullName) ? true : false
                          }
                        />
                        {formik.touched.fullName && formik.errors.fullName ? <div className="text-danger font-size-10 mt-1">{formik.errors.fullName}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputEmail">
                        {props.t("Common.Label.Contact.Email")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputEmail" name="email" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email || ""} invalid={formik.touched.email && formik.errors.email ? true : false} />
                        {formik.touched.email && formik.errors.email ? <div className="text-danger font-size-10 mt-1">{formik.errors.email}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputPhoneNumber">
                        {props.t("Common.Label.Contact.PhoneNumber1")}
                      </Label>
                      <Col sm="4">
                        <Input id="inputPhoneNumber" name="phoneNumber" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber || ""} invalid={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false} />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber}</div> : null}
                      </Col>
                      <Label sm="2" for="inputPhoneExtension">
                        {props.t("Common.Label.Contact.Extension1")}
                      </Label>
                      <Col sm="2">
                        <Input id="inputPhoneExtension" name="phoneExtension" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneExtension || ""} invalid={formik.touched.phoneExtension && formik.errors.phoneExtension ? true : false} />
                        {formik.touched.phoneExtension && formik.errors.phoneExtension ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneExtension}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputPhoneNumber2">
                        {props.t("Common.Label.Contact.PhoneNumber2")}
                      </Label>
                      <Col sm="4">
                        <Input id="inputPhoneNumber2" name="phoneNumber2" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber2 || ""} invalid={formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ? true : false} />
                        {formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber2}</div> : null}
                      </Col>
                      <Label sm="2" for="inputPhoneExtension2">
                        {props.t("Common.Label.Contact.Extension2")}
                      </Label>
                      <Col sm="2">
                        <Input id="inputPhoneExtension2" name="phoneExtension2" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneExtension2 || ""} invalid={formik.touched.phoneExtension2 && formik.errors.phoneExtension2 ? true : false} />
                        {formik.touched.phoneExtension2 && formik.errors.phoneExtension2 ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneExtension2}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputAddress">
                        {props.t("Common.Label.Contact.Address")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputAddress" name="address" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address || ""} invalid={formik.touched.address && formik.errors.address ? true : false} />
                        {formik.touched.address && formik.errors.address ? <div className="text-danger font-size-10 mt-1">{formik.errors.address}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputCity">
                        {props.t("Common.Label.Contact.City")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputCity" name="city" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city || ""} invalid={formik.touched.city && formik.errors.city ? true : false} />
                        {formik.touched.city && formik.errors.city ? <div className="text-danger font-size-10 mt-1">{formik.errors.city}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputStateOrProvince">
                        {props.t("Common.Label.Contact.StateOrProvince")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputStateOrProvince" name="stateOrProvince" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stateOrProvince || ""} invalid={formik.touched.stateOrProvince && formik.errors.stateOrProvince ? true : false} />
                        {formik.touched.stateOrProvince && formik.errors.stateOrProvince ? <div className="text-danger font-size-10 mt-1">{formik.errors.stateOrProvince}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputZipOrPostalCode">
                        {props.t("Common.Label.Contact.ZipOrPostalCode")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputZipOrPostalCode" name="zipOrPostalCode" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zipOrPostalCode || ""} invalid={formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? true : false} />
                        {formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? <div className="text-danger font-size-10 mt-1">{formik.errors.zipOrPostalCode}</div> : null}
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xl="7" xs="12">
                    <Row>
                      <Col>
                        <div>
                          <h5>{props.t("Common.Label.Roles")}</h5>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="4" xs="12">
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedBUYER" name="checkedBUYER" type="checkbox" defaultChecked={formik.values.checkedBUYER} onChange={formik.handleChange} />
                          <Label check for="checkedBUYER">
                            {props.t("Common.Enum.ContactRole.BUYER")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedVENDOR" name="checkedVENDOR" type="checkbox" defaultChecked={formik.values.checkedVENDOR} onChange={formik.handleChange} />
                          <Label check for="checkedVENDOR">
                            {props.t("Common.Enum.ContactRole.VENDOR")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedOWNER" name="checkedOWNER" type="checkbox" defaultChecked={formik.values.checkedOWNER} onChange={formik.handleChange} />
                          <Label check for="checkedOWNER">
                            {props.t("Common.Enum.ContactRole.OWNER")}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xl="4" xs="12">
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedCLIENT" name="checkedCLIENT" type="checkbox" defaultChecked={formik.values.checkedCLIENT} onChange={formik.handleChange} />
                          <Label check for="checkedCLIENT">
                            {props.t("Common.Enum.ContactRole.CLIENT")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedREQUESTOR" name="checkedREQUESTOR" type="checkbox" defaultChecked={formik.values.checkedREQUESTOR} onChange={formik.handleChange} />
                          <Label check for="checkedREQUESTOR">
                            {props.t("Common.Enum.ContactRole.REQUESTOR")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedBILLING" name="checkedBILLING" type="checkbox" defaultChecked={formik.values.checkedBILLING} onChange={formik.handleChange} />
                          <Label check for="checkedBILLING">
                            {props.t("Common.Enum.ContactRole.BILLING")}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xl="4" xs="12">
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedOTHER" name="checkedOTHER" type="checkbox" defaultChecked={formik.values.checkedOTHER} onChange={formik.handleChange} />
                          <Label check for="checkedOTHER">
                            {props.t("Common.Enum.ContactRole.OTHER")}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xl="4" xs="12">
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedAGENT" name="checkedAGENT" type="checkbox" defaultChecked={formik.values.checkedAGENT} onChange={formik.handleChange} />
                          <Label check for="checkedAGENT">
                            {props.t("Common.Enum.ContactRole.AGENT")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedREALESTATEAGENT" name="checkedREALESTATEAGENT" type="checkbox" defaultChecked={formik.values.checkedREALESTATEAGENT} onChange={formik.handleChange} />
                          <Label check for="checkedREALESTATEAGENT">
                            {props.t("Common.Enum.ContactRole.REALESTATEAGENT")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedREALTOR" name="checkedREALTOR" type="checkbox" defaultChecked={formik.values.checkedREALTOR} onChange={formik.handleChange} />
                          <Label check for="checkedREALTOR">
                            {props.t("Common.Enum.ContactRole.REALTOR")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedLAWYER" name="checkedLAWYER" type="checkbox" defaultChecked={formik.values.checkedLAWYER} onChange={formik.handleChange} />
                          <Label check for="checkedLAWYER">
                            {props.t("Common.Enum.ContactRole.LAWYER")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedACCOUNTANT" name="checkedACCOUNTANT" type="checkbox" defaultChecked={formik.values.checkedACCOUNTANT} onChange={formik.handleChange} />
                          <Label check for="checkedACCOUNTANT">
                            {props.t("Common.Enum.ContactRole.ACCOUNTANT")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedNOTARY" name="checkedNOTARY" type="checkbox" defaultChecked={formik.values.checkedNOTARY} onChange={formik.handleChange} />
                          <Label check for="checkedNOTARY">
                            {props.t("Common.Enum.ContactRole.NOTARY")}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xl="4" xs="12">
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedDIRECTOR" name="checkedDIRECTOR" type="checkbox" defaultChecked={formik.values.checkedDIRECTOR} onChange={formik.handleChange} />
                          <Label check for="checkedDIRECTOR">
                            {props.t("Common.Enum.ContactRole.DIRECTOR")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedMANAGER" name="checkedMANAGER" type="checkbox" defaultChecked={formik.values.checkedMANAGER} onChange={formik.handleChange} />
                          <Label check for="checkedMANAGER">
                            {props.t("Common.Enum.ContactRole.MANAGER")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedSECRETARY" name="checkedSECRETARY" type="checkbox" defaultChecked={formik.values.checkedSECRETARY} onChange={formik.handleChange} />
                          <Label check for="checkedSECRETARY">
                            {props.t("Common.Enum.ContactRole.SECRETARY")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedVICEPRESIDENT" name="checkedVICEPRESIDENT" type="checkbox" defaultChecked={formik.values.checkedVICEPRESIDENT} onChange={formik.handleChange} />
                          <Label check for="checkedVICEPRESIDENT">
                            {props.t("Common.Enum.ContactRole.VICEPRESIDENT")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedPRESIDENT" name="checkedPRESIDENT" type="checkbox" defaultChecked={formik.values.checkedPRESIDENT} onChange={formik.handleChange} />
                          <Label check for="checkedPRESIDENT">
                            {props.t("Common.Enum.ContactRole.PRESIDENT")}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xl="4" xs="12">
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedFINANCIALINSTITUTION" name="checkedFINANCIALINSTITUTION" type="checkbox" defaultChecked={formik.values.checkedFINANCIALINSTITUTION} onChange={formik.handleChange} />
                          <Label check for="checkedFINANCIALINSTITUTION">
                            {props.t("Common.Enum.ContactRole.FINANCIALINSTITUTION")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedAGENCY" name="checkedAGENCY" type="checkbox" defaultChecked={formik.values.checkedAGENCY} onChange={formik.handleChange} />
                          <Label check for="checkedAGENCY">
                            {props.t("Common.Enum.ContactRole.AGENCY")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedCITY" name="checkedCITY" type="checkbox" defaultChecked={formik.values.checkedCITY} onChange={formik.handleChange} />
                          <Label check for="checkedCITY">
                            {props.t("Common.Enum.ContactRole.CITY")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedGOVERNMENT" name="checkedGOVERNMENT" type="checkbox" defaultChecked={formik.values.checkedGOVERNMENT} onChange={formik.handleChange} />
                          <Label check for="checkedGOVERNMENT">
                            {props.t("Common.Enum.ContactRole.GOVERNMENT")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedMINISTRY" name="checkedMINISTRY" type="checkbox" defaultChecked={formik.values.checkedMINISTRY} onChange={formik.handleChange} />
                          <Label check for="checkedMINISTRY">
                            {props.t("Common.Enum.ContactRole.MINISTRY")}
                          </Label>
                        </FormGroup>
                        <FormGroup className="form-check-primary" check>
                          <Input className="form-check-input" id="checkedSYNDICATE" name="checkedSYNDICATE" type="checkbox" defaultChecked={formik.values.checkedSYNDICATE} onChange={formik.handleChange} />
                          <Label check for="checkedSYNDICATE">
                            {props.t("Common.Enum.ContactRole.SYNDICATE")}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr className="mt-0" />
                <Button className="me-2" color="primary" type="submit" disabled={!(formik.isValid && formik.dirty)}>
                  {props.t("Common.Action.Save")}
                </Button>
                <Button
                  className="me-2"
                  color="secondary"
                  type="reset"
                  outline
                  onClick={e => {
                    handleCancelOnClick(e)
                  }}
                >
                  {props.t("Common.Action.Cancel")}
                </Button>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

MandateDetailContactViewEdit.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  username: PropTypes.string,
  mandateContact: PropTypes.any,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default withRouter(withTranslation()(MandateDetailContactViewEdit))
