import React from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap"

import { isEmpty } from "lodash"
import { Formik } from "formik"
import * as Yup from "yup"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsUserCard(props) {
  const { userInfo, onSave, onCancel } = props

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card outline color="info" className="border-5">
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={{
            email: isEmpty(userInfo?.email) ? "" : userInfo.email,
          }}
          validationSchema={Yup.object({
            email: Yup.string().required(props.t("Common.Text.Contact.Email.Validation.Required")).email(props.t("Common.Text.Contact.Email.Validation")).max(255),
          })}
          onSubmit={values => {
            //alert(JSON.stringify(values, null, 2))
            let newUser = {
              email: values["email"],
            }
            userInfo.email = newUser.email
            if (onSave) {
              onSave(userInfo)
            }
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup floating>
                <Input id="inputEmail" name="email" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} invalid={formik.touched.email && formik.errors.email ? true : false} />
                {formik.touched.email && formik.errors.email ? <div className="text-danger font-size-10 mt-1">{formik.errors.email}</div> : null}
                <Label for="inputEmail">{props.t("Common.Label.Contact.Email")}</Label>
              </FormGroup>
              <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">
                {props.t("Common.Action.Save")}
              </Button>
              <Button className="me-2" color="secondary" outline onClick={() => {onCancel()}}>
                {props.t("Common.Action.Cancel")}
              </Button>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

SettingsUserCard.propTypes = {
  t: PropTypes.any,
  userInfo: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(withTranslation()(SettingsUserCard))
