import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress, Table } from "reactstrap"

import MandataDetailTaskModalForm from "./MandateDetailTaskModalForm"
import MandateDetailTaskTableRow from "./MandateDetailTaskTableRow"

import { URL_TASKS } from "helpers/lea-url_helper"

import { TaskStatus } from "models"

//i18n
import { withTranslation } from "react-i18next"

// Component
function MandateDetailTaskView(props) {
  const { mandate, tasks, users, optionsTaskType, onAddNew, onDelete } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openAddTaskForm, setOpenAddTaskForm] = useState(false)
  const [openMenuTask, setOpenMenuTask] = useState(false)
  const [displayCard, setDisplayCard] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMenuActionOnAddNewTask(e) {
    //console.log("TaskView.handleMenuActionOnAddNewTask");
    toggleAddTaskForm() // Open AddTaskForm
  }

  function handleAddTaskFormOnSave(entitySettingsTaskType) {
    //console.log("TaskView.handleAddTaskFormOnSave:entitySettingsTaskType", entitySettingsTaskType);
    toggleAddTaskForm() // Hide AddTaskForm
    onAddNew(entitySettingsTaskType)
  }

  function handleTaskCardOnDelete(entityTask) {
    //console.log("TaskView.handleTaskCardOnDelete:entityTask", entityTask);
    onDelete(entityTask)
  }

  function handleTaskTableRowOnDelete(entityTask) {
    //console.log("TaskView.handleTaskTableRowOnDelete:entityTask", entityTask);
    onDelete(entityTask)
  }

  const handleViewTask = (e, taskId) => {
    //console.log("TaskCard.handleViewTask:taskId", taskId);
    e.preventDefault()
    props.history.push(URL_TASKS + "/" + taskId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleAddTaskForm = useCallback(() => {
    setOpenAddTaskForm(!openAddTaskForm)
  }, [openAddTaskForm])

  const toggleMenuTask = useCallback(() => {
    setOpenMenuTask(!openMenuTask)
  }, [openMenuTask])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const tasksSortedAsc = tasks.sort((objA, objB) => objA.order - objB.order)

  const countAllTask = () => {
    return tasks.reduce((cumul, tsk) => {
      return cumul + 1
    }, 0)
  }

  const countAllTaskActive = () => {
    return tasks.reduce((cumul, tsk) => {
      if (tsk.retired !== true) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  const countAllTaskArchived = () => {
    return tasks.reduce((cumul, tsk) => {
      if (tsk.retired === true) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  const countAllTaskByStatus = status => {
    return tasks.reduce((cumul, tsk) => {
      if (tsk.status === status) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  const countAllTaskActiveByStatus = status => {
    return tasks.reduce((cumul, tsk) => {
      if (tsk.retired !== true && tsk.status === status) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>
              {props.t("Page.MandateDetail.TaskView")}
              {/* &nbsp;
              {!mandate.retired && (
                <sup>
                  <span className={"badge rounded-pill badge-soft-info font-size-12"}>{countAllTasksActive(tasks)}</span>
                </sup>
              )}
              {mandate.retired && (
                <React.Fragment>
                  <sup>
                    <span className={"badge rounded-pill badge-soft-info font-size-12"}>{countAllTasks(tasks)}</span>
                  </sup>
                  &nbsp;
                  <sup>
                    <span className={"badge rounded-pill badge-soft-secondary font-size-12"}>{countAllTasksArchived(tasks)}</span>
                  </sup>
                </React.Fragment>
              )} */}
            </CardTitle>
          </div>
          {!mandate.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuTask} toggle={toggleMenuTask}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={e => handleMenuActionOnAddNewTask(e)}> {props.t("Page.MandateDetail.TaskView.Action.AddNewTask")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          {/*
          <div className="text-sm-start">
            {!mandate.retired && (
              <Button color="primary" className="btn-rounded" onClick={toggleAddTaskForm}>
                <i className="mdi mdi-plus me-1" />
                {props.t("Page.MandateDetail.TaskView.Action.AddNewTask")}
              </Button>
            )}
          </div>
          */}
        </div>
        {openAddTaskForm && <MandataDetailTaskModalForm taskTypesOptions={optionsTaskType} toggle={toggleAddTaskForm} onSave={handleAddTaskFormOnSave} />}
        <div className="d-flex flex-column mt-2 no-print">
          <div>
            {(mandate.retired && countAllTask() > 0) &&
              <Progress className="progress-xl" multi max={100}>
                <Progress color="danger" bar value={(countAllTaskByStatus(TaskStatus.PENDING) / countAllTask()) * 100}>
                  {countAllTaskByStatus(TaskStatus.PENDING)}
                </Progress>
                <Progress color="warning" bar value={(countAllTaskByStatus(TaskStatus.STARTED) / countAllTask()) * 100}>
                  {countAllTaskByStatus(TaskStatus.STARTED)}
                </Progress>
                <Progress color="success" bar value={(countAllTaskByStatus(TaskStatus.COMPLETED) / countAllTask()) * 100}>
                  {countAllTaskByStatus(TaskStatus.COMPLETED)}
                </Progress>
              </Progress>
            }
            {(!mandate.retired && countAllTaskActive() > 0) &&
              <Progress className="progress-xl" multi max={100}>
                <Progress color="danger" bar value={(countAllTaskActiveByStatus(TaskStatus.PENDING) / countAllTaskActive()) * 100}>
                  {countAllTaskActiveByStatus(TaskStatus.PENDING)}
                </Progress>
                <Progress color="warning" bar value={(countAllTaskActiveByStatus(TaskStatus.STARTED) / countAllTaskActive()) * 100}>
                  {countAllTaskActiveByStatus(TaskStatus.STARTED)}
                </Progress>
                <Progress color="success" bar value={(countAllTaskActiveByStatus(TaskStatus.COMPLETED) / countAllTaskActive()) * 100}>
                  {countAllTaskActiveByStatus(TaskStatus.COMPLETED)}
                </Progress>
              </Progress>
            }
          </div>
        </div>
        <div className="mt-2">
          {mandate.retired !== true && countAllTaskActive() === 0 ? (
            <Alert color="danger">
              <i className="mdi mdi-alert-outline me-1" />
              {props.t("Common.Text.Alert.Tasks")}
            </Alert>
          ) : (
            <div className="table-responsive">
              <Table size="sm" className="table-nowrap">
                <thead>
                  <tr>
                    <th className="no-print">{props.t("Common.Label.Action")}</th>
                    <th>{props.t("Common.Label.TaskType")}</th>
                    <th>{props.t("Common.Label.Assignee")}</th>
                    <th className="text-end">{props.t("Common.Label.Status")}</th>
                  </tr>
                </thead>
                <tbody>{tasksSortedAsc.map(task => (mandate.retired || (!mandate.retired && !task.retired) ? <MandateDetailTaskTableRow key={task.id} task={task} optionsTaskType={optionsTaskType} users={users} onDelete={handleTaskTableRowOnDelete} /> : null))}</tbody>
              </Table>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

MandateDetailTaskView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  mandate: PropTypes.any,
  tasks: PropTypes.any,
  users: PropTypes.any,
  optionsTaskType: PropTypes.any,
  onAddNew: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(MandateDetailTaskView))
