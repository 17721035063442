import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import classname from "classnames";
//import MetisMenu from "metismenujs"
import SimpleBar from "simplebar-react"
import { Link, withRouter, useLocation } from "react-router-dom"

import { URL_DASHBOARD_COMPANY, URL_DASHBOARD_PERSONAL, URL_REQUESTS, URL_MANDATES, URL_TASKS, URL_INVOICES, URL_SETTINGS, URL_CONTACTS, URL_DESKTOP } from "helpers/lea-url_helper";

//i18n
import { withTranslation } from "react-i18next"

/**
 * Vertical sidebar content component
 */
const SidebarContent = props => {

  // assigning location variable
  const location = useLocation();

  // destructuring pathname from location
  const { pathname } = location;

  const ref = useRef();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  /* useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  } */

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            {/* ++++ MENU ++++ */}
            <li className="menu-title">{props.t("Menu")} </li> 
             {/*
            <li>
              <Link to="/#">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Menu.Dashboard")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={classname({ 'mm-active active': pathname.startsWith(URL_DASHBOARD_COMPANY) })}>
                  <Link to={URL_DASHBOARD_COMPANY}>{props.t("Menu.Dashboard.Company")}</Link>
                </li>
                <li className={classname({ 'mm-active active': pathname.startsWith(URL_DASHBOARD_PERSONAL) })}>
                  <Link to={URL_DASHBOARD_PERSONAL}>
                    {props.t("Menu.Dashboard.Personal")}
                  </Link>
                </li>
              </ul>
            </li>
            */}
            <li className={classname({ 'mm-active active': pathname.startsWith(URL_DESKTOP) })}>
              <Link to={URL_DESKTOP} className={classname('nav-link', { 'active': pathname.startsWith(URL_DESKTOP) })}>
                <i className="fas fa-th-large ms-3"></i>
                <span>{props.t("Menu.Desktop")}</span>
              </Link>
            </li>

            <li className={classname({ 'mm-active active': pathname.startsWith(URL_REQUESTS) })}>
              <Link to={URL_REQUESTS} className={classname('nav-link', { 'active': pathname.startsWith(URL_REQUESTS) })}>
                <i className="fas fa-seedling ms-3"></i>
                <span>{props.t("Menu.Requests")}</span>
              </Link>
            </li>

            <li className={classname({ 'mm-active active': pathname.startsWith(URL_MANDATES) })}>
              <Link to={URL_MANDATES} className={classname('nav-link', { 'active': pathname.startsWith(URL_MANDATES) })}>
                <i className="fas fa-file-alt ms-3"></i>
                <span>{props.t("Menu.Mandates")}</span>
              </Link>
            </li>

            <li className={classname({ 'mm-active active': pathname.startsWith(URL_TASKS) })}>
              <Link to={URL_TASKS} className={classname('nav-link', { 'active': pathname.startsWith(URL_TASKS) })}>
                <i className="fas fa-tasks ms-3"></i>
                <span>{props.t("Menu.Tasks")}</span>
              </Link>
            </li>

            <li className={classname({ 'mm-active active': pathname.startsWith(URL_INVOICES) })}>
              <Link to={URL_INVOICES} className={classname('nav-link', { 'active': pathname.startsWith(URL_INVOICES) })}>
                <i className="fas fa-file-invoice-dollar ms-3"></i>
                <span>{props.t("Menu.Invoices")}</span>
              </Link>
            </li>

            <li className={classname({ 'mm-active active': pathname.startsWith(URL_CONTACTS) })}>
              <Link to={URL_CONTACTS} className={classname('nav-link', { 'active': pathname.startsWith(URL_CONTACTS) })}>
                <i className="fas fa-address-book ms-3"></i>
                <span>{props.t("Menu.Contacts")}</span>
              </Link>
            </li>

            {/* ++++ MENU ADMIN ++++ */}
            <li className="menu-title">{props.t("Menu.Admin")} </li>
              {/*
              <li className={classname({ 'mm-active active': pathname.startsWith(URL_DEEDSOFSALE) })}>
                <Link to={URL_DEEDSOFSALE} className={classname('nav-link', { 'active': pathname.startsWith(URL_DEEDSOFSALE) })}>
                  <i className="bx bx-buildings"></i>
                  <span>{props.t("Menu.DeedsOfSale")}</span>
                </Link>
              </li>
              */}

              {/*
              <li className={classname({ 'mm-active active': pathname.startsWith(URL_INDEXATION) })}>
                <Link to={URL_INDEXATION} className={classname('nav-link', { 'active': pathname.startsWith(URL_INDEXATION) })}>
                  <i className="bx bx-grid"></i>
                  <span>{props.t("Menu.Admin.Indexation")}</span>
                </Link>
              </li>
              */}

              <li className={classname({ 'mm-active active': pathname.startsWith(URL_SETTINGS) })}>
                <Link to={URL_SETTINGS} className={classname('nav-link', { 'active': pathname.startsWith(URL_SETTINGS) })}>
                  <i className="fas fa-sliders-h ms-3"></i>
                  <span>{props.t("Menu.Admin.Settings")}</span>
                </Link>
              </li>
            
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
