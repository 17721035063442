import React, { useState } from "react"
import PropTypes from "prop-types"

import { Alert, Button, Col, Form, FormFeedback, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { useFormik } from "formik"
import * as Yup from "yup"

// i18n
import { withTranslation } from "react-i18next"

// Component
function MandateDetailTaskModalForm(props) {
  const { taskTypesOptions, toggle, onSave } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [saving, setSaving] = useState(false)
  const [activeTaskTypesOptions, setActiveTaskTypesOptions] = useState(taskTypesOptions.filter(item => item.retired !== true))

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const prepareInitialValues = () => ({
    taskType: "",
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: prepareInitialValues(),
    validationSchema: Yup.object({
      taskType: Yup.string().required(props.t("Page.MandateDetail.TaskView.Form.AddTask.Validation.Required")),
    }),
    onSubmit: values => {
      setSaving(true)
      let taskSelected = taskTypesOptions.find(item => item.id === values["taskType"])
      onSave(taskSelected)
    },
  })

  const taskTypesOptionsSortedAsc = activeTaskTypesOptions.sort((objA, objB) => objA.order - objB.order)

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering form
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Modal centered isOpen={true} backdrop="static">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Page.MandateDetail.TaskView.Form.AddTask")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              {isEmpty(activeTaskTypesOptions) && (
                <div>
                  <Alert color="danger">{props.t("Page.MandateDetail.TaskView.Form.AddTask.Alert")}</Alert>
                  <Alert color="info">{props.t("Page.MandateDetail.TaskView.Form.AddTask.Alert.Message")}</Alert>
                </div>
              )}
              {!isEmpty(activeTaskTypesOptions) && (
                <div>
                  <div className="mb-3">
                    <Input id="inputTaskType" name="taskType" type="select" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.taskType} invalid={validation.touched.taskType && validation.errors.taskType ? true : false}>
                      <option key="-1" hidden value="" label={props.t("Page.MandateDetail.TaskView.Form.AddTask.Placeholder")} />
                      {taskTypesOptionsSortedAsc.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.taskType && validation.errors.taskType ? <FormFeedback type="invalid">{validation.errors.taskType}</FormFeedback> : null}
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" outline onClick={toggle}>
                      {props.t("Common.Action.Cancel")}
                    </Button>
                    <Button color="primary" type="submit" disabled={saving}>
                      {props.t("Common.Action.Save")}
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

MandateDetailTaskModalForm.propTypes = {
  t: PropTypes.any,
  taskTypesOptions: PropTypes.any,
  onSave: PropTypes.func,
  toggle: PropTypes.func,
}

export default withTranslation()(MandateDetailTaskModalForm)
