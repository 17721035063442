import React from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { useMutation } from "react-query"
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { Formik } from "formik"
import { isEmpty, isNil } from "lodash"
import * as Yup from "yup"

import { PHONE_NO_REGEX } from "helpers/lea-phonenumber_helper"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { updateContact } from "graphql/mutations"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function ContactDetailSummaryViewEdit(props) {
  const { username, contact, onSave, onCancel } = props

  // Alert
  const customAlert = useAlert()

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleCancelOnClick(e) {
    //console.log("EditContactView.handleCancelOnClick")
    e.preventDefault()
    onCancel(e)
  }

  async function handleOnSubmit(entityContact) {
    //console.log("EditContactView.handleOnSubmit:entityContact", entityContact)
    let entityContactUpdate = await doUpdateContact(entityContact)
    if (isNil(entityContactUpdate)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de mettre à jour le contact" }), { timeout: 0 })
      return
    }
    onSave(entityContactUpdate)
  }

  async function doUpdateContact(entityContact) {
    //console.log("EditContactView.doUpdateContact:contactData", contactData);
    const input = {
      ...entityContact,
      updateUser: username,
    }
    //console.log("EditContactView.doUpdateContact:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("EditContactView.doUpdateContact:updateInput", updateInput)
    try {
      const result = await updateContactMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("EditContactView.doUpdateContact:error", error)
      return null
    }
  }

  const updateContactMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateContact", updateContact, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <Formik
          /*enableReinitialize={true}*/
          initialValues={{
            /*contactType: isEmpty(contact?.contactType) ? "PERSON" : `${contactinfo.contact.contactType}`,*/
            fullName: isEmpty(contact?.fullName) ? "" : contact?.fullName,
            email: isEmpty(contact?.email) ? "" : contact?.email,
            phoneNumber: isEmpty(contact?.phoneNumber) ? "" : contact?.phoneNumber,
            phoneExtension: isEmpty(contact?.phoneExtension) ? "" : contact?.phoneExtension,
            phoneNumber2: isEmpty(contact?.phoneNumber2) ? "" : contact?.phoneNumber2,
            phoneExtension2: isEmpty(contact?.phoneExtension2) ? "" : contact?.phoneExtension2,
            address: isEmpty(contact?.address) ? "" : contact?.address,
            city: isEmpty(contact?.city) ? "" : contact?.city,
            stateOrProvince: isEmpty(contact?.stateOrProvince) ? "" : contact?.stateOrProvince,
            zipOrPostalCode: isEmpty(contact?.zipOrPostalCode) ? "" : contact?.zipOrPostalCode,
          }}
          validationSchema={Yup.object({
            fullName: Yup.string().required(props.t("Common.Text.Contact.Name.Validation.Required")),
            email: Yup.string().email(props.t("Page.ContactDetail.SummaryViewEdit.Email.Validation")).max(255),
            phoneNumber: Yup.string().matches(PHONE_NO_REGEX, props.t("Page.ContactDetail.SummaryViewEdit.PhoneNumber.Validation")).nullable(),
            phoneNumber2: Yup.string().matches(PHONE_NO_REGEX, props.t("Page.ContactDetail.SummaryViewEdit.PhoneNumber2.Validation")).nullable(),
          })}
          onSubmit={values => {
            //console.log("AddContactCard.onSubmit:values", values);
            /*contactType: values["contactType"],*/
            contact.fullName = isEmpty(values["fullName"]) ? null : values["fullName"]
            contact.email = isEmpty(values["email"]) ? null : values["email"]
            contact.phoneNumber = isEmpty(values["phoneNumber"]) ? null : values["phoneNumber"]
            contact.phoneExtension = isEmpty(values["phoneExtension"]) ? null : values["phoneExtension"]
            contact.phoneNumber2 = isEmpty(values["phoneNumber2"]) ? null : values["phoneNumber2"]
            contact.phoneExtension2 = isEmpty(values["phoneExtension2"]) ? null : values["phoneExtension2"]
            contact.address = isEmpty(values["address"]) ? null : values["address"]
            contact.city = isEmpty(values["city"]) ? null : values["city"]
            contact.stateOrProvince = isEmpty(values["stateOrProvince"]) ? null : values["stateOrProvince"]
            contact.zipOrPostalCode = isEmpty(values["zipOrPostalCode"]) ? null : values["zipOrPostalCode"]
            // CHAMPS DENORM
            contact.fullNameDenom = isEmpty(values["fullName"]) ? null : values["fullName"].toLowerCase()
            contact.emailDenom = isEmpty(values["email"]) ? null : values["email"].toLowerCase()
            contact.phoneNumberDenom = isEmpty(values["phoneNumber"]) ? null : values["phoneNumber"]
            contact.phoneNumber2Denom = isEmpty(values["phoneNumber2"]) ? null : values["phoneNumber2"]
            contact.addressDenom = isEmpty(values["address"]) ? null : values["address"].toLowerCase()
            contact.cityDenom = isEmpty(values["city"]) ? null : values["city"].toLowerCase()
            contact.stateOrProvinceDenom = isEmpty(values["stateOrProvince"]) ? null : values["stateOrProvince"].toLowerCase()
            contact.zipOrPostalCodeDenom = isEmpty(values["zipOrPostalCode"]) ? null : values["zipOrPostalCode"].toLowerCase()

            handleOnSubmit(contact)
          }}
        >
          {formik => (
            <React.Fragment>
              <Form onSubmit={formik.handleSubmit}>
                <CardTitle className="mb-2">{props.t("Page.ContactDetail.SummaryViewEdit")}</CardTitle>
                <div className="mb-2">{props.t("Page.ContactDetail.SummaryViewEdit.Subtitle")}</div>
                <hr className="mt-0" />
                <Row>
                  <Col xl="5" xs="12">
                    <FormGroup row>
                      <Label sm="3" for="inputFullName">
                        {props.t("Common.Label.Contact.Name")}
                      </Label>
                      <Col sm="8">
                        <Input
                          id="inputFullName"
                          name="fullName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.fullName || ""}
                          invalid={
                            /*formik.touched.fullName && formik.errors.fullName ? true : false*/
                            isEmpty(formik.values.fullName) ? true : false
                          }
                        />
                        {formik.touched.fullName && formik.errors.fullName ? <div className="text-danger font-size-10 mt-1">{formik.errors.fullName}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputEmail">
                        {props.t("Common.Label.Contact.Email")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputEmail" name="email" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email || ""} invalid={formik.touched.email && formik.errors.email ? true : false} />
                        {formik.touched.email && formik.errors.email ? <div className="text-danger font-size-10 mt-1">{formik.errors.email}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputPhoneNumber">
                        {props.t("Common.Label.Contact.PhoneNumber1")}
                      </Label>
                      <Col sm="4">
                        <Input id="inputPhoneNumber" name="phoneNumber" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber || ""} invalid={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false} />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber}</div> : null}
                      </Col>
                      <Label sm="2" for="inputPhoneExtension">
                        {props.t("Common.Label.Contact.Extension1")}
                      </Label>
                      <Col sm="2">
                        <Input id="inputPhoneExtension" name="phoneExtension" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneExtension || ""} invalid={formik.touched.phoneExtension && formik.errors.phoneExtension ? true : false} />
                        {formik.touched.phoneExtension && formik.errors.phoneExtension ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneExtension}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputPhoneNumber2">
                        {props.t("Common.Label.Contact.PhoneNumber2")}
                      </Label>
                      <Col sm="4">
                        <Input id="inputPhoneNumber2" name="phoneNumber2" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber2 || ""} invalid={formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ? true : false} />
                        {formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber2}</div> : null}
                      </Col>
                      <Label sm="2" for="inputPhoneExtension2">
                        {props.t("Common.Label.Contact.Extension2")}
                      </Label>
                      <Col sm="2">
                        <Input id="inputPhoneExtension2" name="phoneExtension2" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneExtension2 || ""} invalid={formik.touched.phoneExtension2 && formik.errors.phoneExtension2 ? true : false} />
                        {formik.touched.phoneExtension2 && formik.errors.phoneExtension2 ? <div className="text-danger font-size-10 mt-1">{formik.errors.phoneExtension2}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputAddress">
                        {props.t("Common.Label.Contact.Address")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputAddress" name="address" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address || ""} invalid={formik.touched.address && formik.errors.address ? true : false} />
                        {formik.touched.address && formik.errors.address ? <div className="text-danger font-size-10 mt-1">{formik.errors.address}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputCity">
                        {props.t("Common.Label.Contact.City")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputCity" name="city" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.city || ""} invalid={formik.touched.city && formik.errors.city ? true : false} />
                        {formik.touched.city && formik.errors.city ? <div className="text-danger font-size-10 mt-1">{formik.errors.city}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputStateOrProvince">
                        {props.t("Common.Label.Contact.StateOrProvince")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputStateOrProvince" name="stateOrProvince" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stateOrProvince || ""} invalid={formik.touched.stateOrProvince && formik.errors.stateOrProvince ? true : false} />
                        {formik.touched.stateOrProvince && formik.errors.stateOrProvince ? <div className="text-danger font-size-10 mt-1">{formik.errors.stateOrProvince}</div> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="3" for="inputZipOrPostalCode">
                        {props.t("Common.Label.Contact.ZipOrPostalCode")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputZipOrPostalCode" name="zipOrPostalCode" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.zipOrPostalCode || ""} invalid={formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? true : false} />
                        {formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? <div className="text-danger font-size-10 mt-1">{formik.errors.zipOrPostalCode}</div> : null}
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="mt-0" />
                <Row>
                  <Col className="no-print">
                    <Button className="me-2" color="primary" type="submit" disabled={!(formik.isValid && formik.dirty)}>
                      {props.t("Common.Action.Save")}
                    </Button>
                    <Button className="me-2" color="secondary" type="reset" outline onClick={(e) => {handleCancelOnClick(e)}}>
                      {props.t("Common.Action.Cancel")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

ContactDetailSummaryViewEdit.propTypes = {
  t: PropTypes.any,
  username: PropTypes.string,
  contact: PropTypes.any,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default withTranslation()(ContactDetailSummaryViewEdit)
