import React from "react";
import PropTypes from "prop-types";

import { Route, Switch, withRouter } from "react-router-dom";

import TasksMasterPage from "./master/TasksMasterPage";
import TaskDetailPage from "./detail/TaskDetailPage";
import Page404 from "components/Page404/Page404";

// Component
function TasksRouter(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/`} component={TasksMasterPage} />
      <Route exact path={`${props.match.path}/:taskId`} component={TaskDetailPage} />
      <Route exact from={`${props.match.path}/:taskId/:Id`} component={Page404} />
    </Switch>
  )
};

TasksRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(TasksRouter);


