import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { useAlert } from "react-alert";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { Formik } from "formik";
import { isEmpty, isNil } from "lodash";
import * as Yup from "yup";

import { PHONE_NO_REGEX } from "helpers/lea-phonenumber_helper";
import { listContacts } from "graphql/queries";

import { API, graphqlOperation } from 'aws-amplify';

// i18n
import { withTranslation } from "react-i18next";

// Component 
function ContactSearchCard(props) {

    const { onSelect, onAddNew, onCancel, displayCancelButton } = props

    // Alert
    const customAlert = useAlert()

    // ----------------------------------------------------------------------------------------------------------------
    // State
    // ----------------------------------------------------------------------------------------------------------------
    const [searchResults, setSearchResults] = useState([])
    const [displayResult, setDisplayResult] = useState(false)
    const [displaySearchContactButton, setDisplaySearchContactButton] = useState(true)
    const [displayAddNewContactButton, setDisplayAddNewContactButton] = useState(false)

    // ----------------------------------------------------------------------------------------------------------------
    // Handlers
    // ----------------------------------------------------------------------------------------------------------------
    function handleOnSubmit(searchContactCriteria) {
        //console.log("ContactSearchCard.handleOnSubmit:searchContactCriteria", searchContactCriteria)
        doOnSubmit(searchContactCriteria)
    }

    function handleResetOnClick(e) {
        //console.log("ContactSearchCard.handleResetOnClick");
        e.preventDefault();
        setDisplayResult(false);
        setDisplaySearchContactButton(true);
        setDisplayAddNewContactButton(false);
        setSearchResults([]);
    }

    function handleCancelOnClick(e) {
        //console.log("ContactSearchCard.handleCancelOnClick:");
        e.preventDefault();
        onCancel(e)
    }

    function handleSelectOnClick(e, row) {
        //console.log("ContactSearchCard.handleSelectOnClick:row", row);
        e.preventDefault();
        onSelect(e, row)
    }

    function handleAddNewContactOnClick(e, values) {
        //console.log("ContactSearchCard.handleAddNewContactOnClick:values", values);
        e.preventDefault()
        onAddNew(e, values)
    }

    function doOnSubmit(searchContactCriteria) {
        //console.log("ContactSearchCard.doOnSubmit:searchContactCriteria", searchContactCriteria);
        //remove empty criteria
        Object.keys(searchContactCriteria).forEach(key => {
            if (isNil(searchContactCriteria[key])) {
                delete searchContactCriteria[key];
            }
        });
        doFetchContact(searchContactCriteria)
    }

    async function doFetchContact(searchContactCriteria) {
        //console.log("ContactSearchCard.doFetchContact:searchContactCriteria", searchContactCriteria);
        try {
            let filterBuilder = {
                fullNameDenom: { contains: `${searchContactCriteria.fullNameDenom}` },
                emailDenom: { contains: `${searchContactCriteria.emailDenom}` },
                phoneNumberDenom: { contains: `${searchContactCriteria.phoneNumberDenom}` },
                phoneNumber2Denom: { contains: `${searchContactCriteria.phoneNumber2Denom}` },
                addressDenom: { contains: `${searchContactCriteria.addressDenom}` },
                cityDenom: { contains: `${searchContactCriteria.cityDenom}` },
                stateOrProvinceDenom: { contains: `${searchContactCriteria.stateOrProvinceDenom}` },
                zipOrPostalCodeDenom: { contains: `${searchContactCriteria.zipOrPostalCodeDenom}` }
            }
            if (isNil(searchContactCriteria.fullNameDenom)) {
                delete filterBuilder.fullNameDenom
            }
            if (isNil(searchContactCriteria.emailDenom)) {
                delete filterBuilder.emailDenom
            }
            if (isNil(searchContactCriteria.phoneNumberDenom)) {
                delete filterBuilder.phoneNumberDenom
            }
            if (isNil(searchContactCriteria.phoneNumber2Denom)) {
                delete filterBuilder.phoneNumber2Denom
            }
            if (isNil(searchContactCriteria.addressDenom)) {
                delete filterBuilder.addressDenom
            }
            if (isNil(searchContactCriteria.cityDenom)) {
                delete filterBuilder.cityDenom
            }
            if (isNil(searchContactCriteria.stateOrProvinceDenom)) {
                delete filterBuilder.stateOrProvinceDenom
            }
            if (isNil(searchContactCriteria.zipOrPostalCodeDenom)) {
                delete filterBuilder.zipOrPostalCodeDenom
            }
            //console.log("ContactSearchCard.doFetchContact:filterBuilder", filterBuilder);
            const variables = {
                filter: filterBuilder,
                limit: 1000,
            }
            //console.log("ContactSearchCard.doFetchContact:variables", variables);
            const result = await API.graphql(
                graphqlOperation(listContacts, variables)
            )
            //console.log("ContactSearchCard.doFetchContact:result", result);
            if (isNil(result.data.listContacts.nextToken)) {
                setSearchResults(result.data.listContacts.items);
                if (result.data.listContacts.items.length > 0) {
                    setDisplaySearchContactButton(false)
                    setDisplayAddNewContactButton(false)
                    setDisplayResult(true);
                } else {
                    setDisplaySearchContactButton(false)
                    setDisplayAddNewContactButton(true);
                    setDisplayResult(true);
                }
            } else {
                setSearchResults([]);
                setDisplayResult(false);
                customAlert.error(props.t("Component.ContactSearch.ContactSearchCard.Validation.TooManyResult"), { timeout: 0 })
            }
        } catch (error) {
            console.error("ContactSearchCard.doFetchContact:error", error)
        } finally {
            //setIsLoading(false)
        }
    }

    // ----------------------------------------------------------------------------------------------------------------
    // Def columns
    // ----------------------------------------------------------------------------------------------------------------
    const columns = [
        {
            dataField: "action",
            isDummyField: true,
            text: props.t("Common.Label.Action"),
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <Button size="sm" color="secondary" outline onClick={(e) => handleSelectOnClick(e, row)}>
                    <i className="mdi mdi-target font-size-18" />
                </Button>
            ),
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        },
        {
            dataField: 'fullName',
            text: props.t("Common.Label.Contact.Name")
        },
        {
            dataField: 'email',
            text: props.t("Common.Label.Contact.Email")
        },
        {
            dataField: 'phoneNumber',
            text: props.t("Common.Label.Contact.PhoneNumber1")
        },
        {
            dataField: 'phoneNumber2',
            text: props.t("Common.Label.Contact.PhoneNumber2")
        },
        {
            dataField: 'address',
            text: props.t("Common.Label.Contact.Address")
        },
        {
            dataField: 'city',
            text: props.t("Common.Label.Contact.City")
        },
        {
            dataField: 'stateOrProvince',
            text: props.t("Common.Label.Contact.StateOrProvince")
        },
        {
            dataField: 'zipOrPostalCode',
            text: props.t("Common.Label.Contact.ZipOrPostalCode")
        }
    ];

    // ----------------------------------------------------------------------------------------------------------------
    // Rendering view
    // ----------------------------------------------------------------------------------------------------------------
    return (
        <Card>
            <CardBody>
                <Formik
                    /*enableReinitialize={true}*/
                    initialValues={{
                        fullName: "",
                        email: "",
                        phoneNumber: "",
                        phoneNumber2: "",
                        address: "",
                        city: "",
                        stateOrProvince: "",
                        zipOrPostalCode: "",
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string().email(props.t("Common.Text.Contact.Email.Validation")).max(255),
                        phoneNumber: Yup.string().matches(PHONE_NO_REGEX, props.t("Common.Text.Contact.PhoneNumber.Validation")).nullable(),
                        phoneNumber2: Yup.string().matches(PHONE_NO_REGEX, props.t("Common.Text.Contact.PhoneNumber.Validation")).nullable()
                    })}
                    onSubmit={(values) => {
                        //console.log(JSON.stringify(values, null, 2))
                        //let newId = uuidv4();
                        const searchContactCriteria = {
                            /*contactType: values["contactType"],*/
                            fullName: values["fullName"] || null,
                            email: values["email"] || null,
                            phoneNumber: values["phoneNumber"] || null,
                            phoneNumber2: values["phoneNumber2"] || null,
                            address: values["address"] || null,
                            city: values["city"] || null,
                            stateOrProvince: values["stateOrProvince"] || null,
                            zipOrPostalCode: values["zipOrPostalCode"] || null,
                            // CHAMPS DENORM
                            fullNameDenom: isEmpty(values["fullName"]) ? null : values["fullName"].toLowerCase(),
                            emailDenom: isEmpty(values["email"]) ? null : values["email"].toLowerCase(),
                            phoneNumberDenom: isEmpty(values["phoneNumber"]) ? null : values["phoneNumber"],
                            phoneNumber2Denom: isEmpty(values["phoneNumber2"]) ? null : values["phoneNumber2"],
                            addressDenom: isEmpty(values["address"]) ? null : values["address"].toLowerCase(),
                            cityDenom: isEmpty(values["city"]) ? null : values["city"].toLowerCase(),
                            stateOrProvinceDenom: isEmpty(values["stateOrProvince"]) ? null : values["stateOrProvince"].toLowerCase(),
                            zipOrPostalCodeDenom: isEmpty(values["zipOrPostalCode"]) ? null : values["zipOrPostalCode"].toLowerCase(),
                        };
                        handleOnSubmit(searchContactCriteria);
                    }}>
                    {
                        formik => (
                            <React.Fragment>
                                <Form onSubmit={formik.handleSubmit}>
                                    {displayResult
                                        ?
                                        <Fragment>
                                            <CardTitle className="mb-2">
                                                {props.t("Component.ContactSearch.ContactSearchCard.Title.Result")}
                                            </CardTitle>
                                            <div className="mb-4">
                                                {/* {props.t('Component.ContactSearch.ContactSearchCard.Subtitle.Result', { count: searchResults.length })} */}
                                                {searchResults.length === 0 ?
                                                    props.t("Component.ContactSearch.ContactSearchCard.Subtitle.Result_zero", { count: searchResults.length })
                                                    :
                                                    (searchResults.length === 1)
                                                        ?
                                                        props.t("Component.ContactSearch.ContactSearchCard.Subtitle.Result_one", { count: searchResults.length })
                                                        :
                                                        props.t("Component.ContactSearch.ContactSearchCard.Subtitle.Result_other", { count: searchResults.length })
                                                }
                                            </div>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <CardTitle className="mb-2">
                                                {props.t("Component.ContactSearch.ContactSearchCard")}
                                            </CardTitle>
                                            <div className="mb-4">
                                                {props.t("Component.ContactSearch.ContactSearchCard.Subtitle")}
                                            </div>
                                        </Fragment>
                                    }
                                    <hr className="mt-0" />
                                    {!(displayResult && searchResults.length > 0) &&
                                        <Fragment>
                                            <FormGroup row>
                                                <Label sm="2" for="inputFullName">{props.t("Common.Label.Contact.Name")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputFullName"
                                                        name="fullName"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.fullName || ""}
                                                        invalid={
                                                            formik.touched.fullName && formik.errors.fullName ? true : false
                                                        }
                                                    />
                                                    {formik.touched.fullName && formik.errors.fullName ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.fullName}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm="2" for="inputEmail">{props.t("Common.Label.Contact.Email")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputEmail"
                                                        name="email"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email || ""}
                                                        invalid={
                                                            formik.touched.email && formik.errors.email ? true : false
                                                        }
                                                    />
                                                    {formik.touched.email && formik.errors.email ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.email}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm="2" for="inputPhoneNumber">{props.t("Common.Label.Contact.PhoneNumber1")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputPhoneNumber"
                                                        name="phoneNumber"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.phoneNumber || ""}
                                                        invalid={
                                                            formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false
                                                        }
                                                    />
                                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm="2" for="inputPhoneNumber2">{props.t("Common.Label.Contact.PhoneNumber2")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputPhoneNumber2"
                                                        name="phoneNumber2"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.phoneNumber2 || ""}
                                                        invalid={
                                                            formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ? true : false
                                                        }
                                                    />
                                                    {formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber2}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm="2" for="inputAddress">{props.t("Common.Label.Contact.Address")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputAddress"
                                                        name="address"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address || ""}
                                                        invalid={
                                                            formik.touched.address && formik.errors.address ? true : false
                                                        }
                                                    />
                                                    {formik.touched.address && formik.errors.address ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.address}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm="2" for="inputCity">{props.t("Common.Label.Contact.City")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputCity"
                                                        name="city"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.city || ""}
                                                        invalid={
                                                            formik.touched.city && formik.errors.city ? true : false
                                                        }
                                                    />
                                                    {formik.touched.city && formik.errors.city ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.city}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm="2" for="inputStateOrProvince">{props.t("Common.Label.Contact.StateOrProvince")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputStateOrProvince"
                                                        name="stateOrProvince"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.stateOrProvince || ""}
                                                        invalid={
                                                            formik.touched.stateOrProvince && formik.errors.stateOrProvince ? true : false
                                                        }
                                                    />
                                                    {formik.touched.stateOrProvince && formik.errors.stateOrProvince ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.stateOrProvince}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm="2" for="inputZipOrPostalCode">{props.t("Common.Label.Contact.ZipOrPostalCode")}</Label>
                                                <Col sm="4">
                                                    <Input
                                                        id="inputZipOrPostalCode"
                                                        name="zipOrPostalCode"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.zipOrPostalCode || ""}
                                                        invalid={
                                                            formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? true : false
                                                        }
                                                    />
                                                    {formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ?
                                                        <div className="text-danger font-size-10 mt-1">{formik.errors.zipOrPostalCode}</div>
                                                        : null}
                                                </Col>
                                            </FormGroup>
                                            <hr className="mt-0" />
                                        </Fragment>
                                    }

                                    {!displayResult &&
                                        <Button className="me-2" color="primary" type="submit" disabled={!(formik.isValid && formik.dirty) || !displaySearchContactButton}>
                                            {props.t("Common.Action.Search")}
                                        </Button>
                                    }
                                    <Button className="me-2" color={(searchResults.length === 0) ? "secondary" : "primary"} type="reset" outline={(searchResults.length === 0) ? true : false} disabled={!(formik.isValid && formik.dirty)} onClick={(e) => { formik.resetForm(); handleResetOnClick(e) }}>
                                        {props.t("Common.Action.Reset")}
                                    </Button>
                                    {displayAddNewContactButton &&
                                        <Button className="me-2" color="primary" onClick={(e) => handleAddNewContactOnClick(e, formik.values)}>
                                            {props.t("Component.ContactSearch.ContactSearchCard.Action.AddNew")}
                                        </Button>
                                    }
                                    {displayCancelButton &&
                                        <Button className="me-2" color="secondary" type="reset" outline onClick={(e) => { handleCancelOnClick(e) }}>
                                            {props.t("Common.Action.Cancel")}
                                        </Button>
                                    }
                                    {displayResult && searchResults.length > 0 &&
                                        <div>
                                            <hr />
                                            <BootstrapTable
                                                responsive
                                                bordered={false}
                                                condensed
                                                hover
                                                classes={"table align-middle "}
                                                headerWrapperClasses={"table-light"}
                                                keyField='id'
                                                data={searchResults}
                                                columns={columns}
                                            />
                                        </div>
                                    }
                                </Form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </CardBody>
        </Card>
    )
}

ContactSearchCard.defaultProps = {
    displayCancelButton: true,
}

ContactSearchCard.propTypes = {
    t: PropTypes.any,
    onSelect: PropTypes.func,
    onAddNew: PropTypes.func,
    onCancel: PropTypes.func,
    displayCancelButton: PropTypes.bool,
}

export default withTranslation()(ContactSearchCard)
