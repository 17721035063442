import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Dropdown, DropdownMenu, DropdownToggle, Table } from "reactstrap"

import RequestContactTableRow from "./ContactDetailRequestTableRow"

import { URL_REQUESTS } from "helpers/lea-url_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function ContactDetailRequestView(props) {
  const { contact, requestContacts } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  /* const [requestContacts, setRequestContacts] = useState([]) */
  const [openMenu, setOpenMenu] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  const handleViewRequest = (e, requestId) => {
    //console.log("RequestContactView.handleViewRequest:requestId", requestId);
    e.preventDefault()
    props.history.push(URL_REQUESTS + "/" + requestId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenu = useCallback(() => {
    setOpenMenu(!openMenu)
  }, [openMenu])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.ContactDetail.RequestView")}&nbsp;</CardTitle>
            <p className="text-muted mb-2">{props.t("Page.ContactDetail.RequestView.Subtitle")}</p>
          </div>
        </div>
        <div className="mt-2">
          {requestContacts.length === 0 ? (
            <Alert color="primary">{props.t("Page.ContactDetail.RequestView.Alert")}</Alert>
          ) : (
            <div className="table-responsive">
              <Table size="sm" className="table-nowrap">
                <thead>
                  <tr>
                    <th className="no-print" width="5%">{props.t("Common.Label.Action")}</th>
                    <th width="8%">{props.t("Common.Label.Status")}</th>
                    <th className="text-end">{props.t("Common.Label.Identifier")}</th>
                  </tr>
                </thead>
                <tbody>
                  {requestContacts.map(reqContact => (
                    <RequestContactTableRow key={reqContact.id} requestContact={reqContact} />
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

ContactDetailRequestView.defaultProps = {
  requestContacts: [],
}

ContactDetailRequestView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  contact: PropTypes.any,
  requestContacts: PropTypes.array,
}

export default withRouter(withTranslation()(ContactDetailRequestView))
