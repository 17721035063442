import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { withRouter } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Button, Card, CardBody, CardTitle, Col, Row, Spinner, Table } from "reactstrap"
import { isEmpty } from "lodash"

import TaskTypeCard from "./SettingsTaskTypeCard"
import TaskTypeTableRow from "./SettingsTaskTypeTableRow"

import { useCurrentUser } from "contexts/current-user-context"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { createSettingsTaskType, updateSettingsTaskType } from "graphql/mutations"
import { listSettingsTaskTypes } from "graphql/queries"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsTaskTypesView(props) {
  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Init
  const emptySettingTaskType = { name: null, order: 0, translations: null }

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openTaskTypeForm, setOpenTaskTypeForm] = useState(false)
  const [optionsTaskType, setOptionsTaskType] = useState([])

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get settings list of task
  const { isLoading, isFetching, isError, error } = useQuery("listSettingsTaskTypesInTaskTypesView", () => firstValueFrom(callAPI("listSettingsTaskTypes", listSettingsTaskTypes, { filter: { retired: { ne: true } } })), {
    retry: false,
    onSuccess: result => {
      //console.log("listSettingsTaskTypesInTaskTypesView.onSuccess:result", result)
      setOptionsTaskType(isEmpty(result?.items) ? [] : result.items)
    },
    onError: error => {
      console.error("TaskTypesView.listSettingsTaskTypesInTaskTypesView.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleTaskTypeCardOnSave(taskData) {
    //console.log("TaskTypesView.handleTaskTypeCardOnSave:taskData", taskData);
    toggleTaskTypeForm() // Hide form
    doCreateTask(taskData)
  }

  function handleTaskTypeTableRowOnDelete(taskData) {
    //console.log("TaskTypesView.handleTaskTypeTableRowOnDelete:taskData", taskData)
    doDeleteTask(taskData)
  }

  function handleTaskTypeTableRowOnSave(mandateData) {
    //console.log("TaskTypesView.handleTaskTypeTableRowOnSave:mandateData", mandateData)
    doUpdateTask(mandateData)
  }

  function doCreateTask(taskData) {
    //console.log("TaskTypesView.doCreateTask:taskData", taskData);
    const input = {
      ...taskData,
      creator: username,
    }
    //console.log("TaskTypesView.doCreateTask:input", input);
    let updateInput = removeGeneratedProperties(input)
    //console.log("TaskTypesView.doCreateTask:updateInput", updateInput)
    createTaskMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("TaskTypesView.doCreateTask.createTaskMutation.onSuccess:result", result)
        setOptionsTaskType([...optionsTaskType, result])
        queryClient.invalidateQueries("listSettingsTaskTypesInMandateTypesView") // Invalide la cache pour visualiser les changements dans l'autre vue
      },
      onError: error => {
        console.error("TaskTypesView.doCreateTask.createTaskMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsTaskTypesInTaskTypesView")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const createTaskMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createSettingsTaskType", createSettingsTaskType, {
        input: {
          ...data,
        },
      })
    )
  )

  function doUpdateTask(taskData) {
    //console.log("TaskTypesView.doUpdateTask:taskData", taskData)
    const input = {
      ...taskData,
      updateUser: username,
    }
    //console.log("TaskTypesView.doUpdateTask:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("TaskTypesView.doUpdateTask:updateInput", updateInput)
    updateTaskMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("TaskTypesView.doUpdateTask.updateTaskMutation.onSuccess:result", result)
        let newArr = optionsTaskType.map(element => (element.id !== result.id ? element : result))
        setOptionsTaskType(newArr)
        queryClient.invalidateQueries("listSettingsTaskTypesInMandateTypesView") // Invalide la cache pour visualiser les changements dans l'autre vue
      },
      onError: error => {
        console.error("TaskTypesView.doUpdateTask.updateTaskMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsTaskTypesInTaskTypesView")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  function doDeleteTask(taskData) {
    //console.log("TaskTypesView.doDeleteTask:taskData", taskData);
    const input = {
      ...taskData,
      updateUser: username,
      retired: true,
    }
    //console.log("TaskTypesView.doDeleteTask:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("TaskTypesView.doDeleteTask:updateInput", updateInput)
    updateTaskMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("TaskTypesView.doDeleteTask.updateTaskMutation.onSuccess:result", result)
        let newOptions = optionsTaskType.filter(opt => {
          return opt.id != taskData.id
        })
        setOptionsTaskType(newOptions)
        queryClient.invalidateQueries("listSettingsTaskTypesInMandateTypesView") // Invalide la cache pour visualiser les changements dans l'autre vue
      },
      onError: error => {
        console.error("TaskTypesView.doDeleteTask.updateTaskMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsTaskTypesInTaskTypesView")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const updateTaskMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateSettingsTaskType", updateSettingsTaskType, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleTaskTypeForm = useCallback(() => {
    setOpenTaskTypeForm(!openTaskTypeForm)
  }, [openTaskTypeForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const optionsTaskTypeSortedAsc = optionsTaskType.sort((objA, objB) => objA.order - objB.order)

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {(isLoading || isFetching) && <Spinner className="ms-2" color="primary" />}
      {(!isLoading && !isFetching && !isError) && 
        <CardBody className="bg-light">
          <CardTitle className="text-dark">
            {props.t("Page.Settings.TaskTypesView")}&nbsp;
            <sup>
              <span className={"badge rounded-pill badge-soft-info font-size-12"}>{optionsTaskType?.length}</span>
            </sup>
          </CardTitle>
          <div className="mb-0 mt-0">
            <p>{props.t("Page.Settings.TaskTypesView.Subtitle")}</p>
            <hr className="mb-2" />
            <Row>
              <Col xl={12}>
                <Row>
                  <div className="text-sm-start mb-4 mt-2">
                    <Button color="primary" className="btn-rounded" onClick={toggleTaskTypeForm}>
                      <i className="mdi mdi-plus me-1" />
                      {props.t("Page.Settings.TaskTypesView.Action.AddNew")}
                    </Button>
                  </div>
                  {openTaskTypeForm && <TaskTypeCard taskTypeInfo={emptySettingTaskType} onSave={handleTaskTypeCardOnSave} onCancel={toggleTaskTypeForm} />}
                </Row>
                <Row>
                  {!isEmpty(optionsTaskType) && (
                    <Card>
                      <CardBody>
                        <Table responsive striped size="sm">
                          {/*<table className="table align-middle table-nowrap mb-0">*/}
                          <thead>
                            <tr>
                              <th scope="col">{props.t("Page.Settings.TaskTypesView.Table.Field.Name")}</th>
                              <th scope="col">{props.t("Page.Settings.TaskTypesView.Table.Field.Order")}</th>
                              <th scope="col">{props.t("Common.Label.Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {optionsTaskTypeSortedAsc.map(row => (
                              <TaskTypeTableRow key={row.id} row={row} onSave={handleTaskTypeTableRowOnSave} onDelete={handleTaskTypeTableRowOnDelete} />
                            ))}
                          </tbody>
                          {/*</table>*/}
                        </Table>
                      </CardBody>
                    </Card>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </CardBody>
      }
    </React.Fragment>
  )
}

SettingsTaskTypesView.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SettingsTaskTypesView))
