import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button } from "reactstrap"
import { isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"

import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function RequestDetailContactTableRow(props) {
  const { request, requestContact, onEdit, onDelete, onSetAsPrimary } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleEditContactOnClick(e) {
    //console.log("RequestContactTableRow.handleActionEditContactOnClick");
    e.preventDefault()
    onEdit(requestContact)
  }

  function handleDeleteContactOnClick(e) {
    //console.log("RequestContactTableRow.handleActionDeleteContactOnClick");
    e.preventDefault()
    toggleDeleteConfirmationForm() // Open the form
  }

  function handleSetAsPrimaryContactOnClick(e) {
    //console.log("RequestContactTableRow.handleActionSetAsPrimaryContactOnClick");
    e.preventDefault()
    onSetAsPrimary(requestContact)
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("RequestContactTableRow.handleDeleteConfirmationFormOnSave:confirmationData", confirmationData);
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(requestContact)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function displayNamesOfRoles() {
    let roles = []
    if (isEmpty(requestContact.roles)) {
      return roles
    }
    requestContact.roles.map(role => {
      roles.push(props.t("Common.Enum.ContactRole." + role))
    })
    return roles
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={requestContact.id}>
      {!request.retired &&
        <td className="no-print" valign="middle" width="15%">
          <Button className="me-2" size="sm" color="secondary" outline onClick={e => handleEditContactOnClick(e)}>
            <i className="bx bx-pencil" />
          </Button>
          <Button className="me-2" size="sm" color="danger" outline onClick={e => handleDeleteContactOnClick(e)}>
            <i className="bx bx-trash-alt" />
          </Button>
          {!requestContact.primary &&
            <Button className="me-2" size="sm" color="success" outline onClick={e => handleSetAsPrimaryContactOnClick(e)}>
              P
            </Button>
          }
        </td>
      }
      <td valign="middle">
        {requestContact.primary ? "X" : ""}
      </td>
      <td valign="middle">
        <p className="mb-0">{requestContact.contact?.fullName}</p>
        <p className="mb-0">{requestContact.contact?.email}</p>
        <p className="mb-0">{isEmpty(requestContact.contact?.phoneExtension) ? formatPhoneNumberAsLocal(requestContact.contact?.phoneNumber) : props.t("Common.Text.Contact.PhoneNumber", { 0: formatPhoneNumberAsLocal(requestContact.contact?.phoneNumber), 1: requestContact.contact?.phoneExtension })}</p>
        <p className="mb-0">{isEmpty(requestContact.contact?.phoneExtension2) ? formatPhoneNumberAsLocal(requestContact.contact?.phoneNumber2) : props.t("Common.Text.Contact.PhoneNumber", { 0: formatPhoneNumberAsLocal(requestContact.contact?.phoneNumber2), 1: requestContact.contact?.phoneExtension2 })}</p>
      </td>
      <td className="text-wrap" valign="middle">
        {displayNamesOfRoles().join(", ")}
      </td>
      {openDeleteConfirmationForm && <DeleteConfirmationForm id={requestContact.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.RequestDetail.ContactView.Form.DeleteConfirmation.Title")} body={props.t("Page.RequestDetail.ContactView.Form.DeleteConfirmation.Body", { 0: requestContact.contact?.fullName })} />}
    </tr>
  )
}

RequestDetailContactTableRow.propTypes = {
  t: PropTypes.any,
  key: PropTypes.any,
  request: PropTypes.any,
  requestContact: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSetAsPrimary: PropTypes.func,
}

export default withRouter(withTranslation()(RequestDetailContactTableRow))
