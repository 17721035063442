import { API, graphqlOperation } from "aws-amplify"
import { updateMandate, updateRequest, updateTask } from "graphql/mutations"
import { listMandates, listTasks } from "graphql/queries"
import { removeGeneratedProperties } from "helpers/lea-graphql_helper"

// Async function to archive the request (delete cascade)
export async function archiveRequest(request) {
  //console.log("RequestService.archiveRequest:request", request)
  // 1) Get all mandate based on request
  const listMandatesVariables = {
    filter: { requestId: { eq: request.id }, retired: { ne: "true" } },
    limit: 10000,
  }
  const listOfMandate = await API.graphql(graphqlOperation(listMandates, listMandatesVariables))
  //console.log("RequestService.archiveRequest:listOfMandate", listOfMandate)

  // 2) Get all task based on request
  const listTaskVariables = {
    filter: { requestId: { eq: request.id }, retired: { ne: "true" } },
    limit: 10000,
  }
  const listOfTask = await API.graphql(graphqlOperation(listTasks, listTaskVariables))
  //console.log("RequestService.archiveRequest:listTasks", listOfTask)

  // 3) Set the "Retired" field to true for all task
  const deleteTask = async task => {
    const input = {
      ...task,
      retired: true,
    }
    //console.log("RequestService.archiveRequest.deleteTask:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestService.archiveRequest.deleteTask:updateInput", updateInput)
    return await API.graphql(graphqlOperation(updateTask, { input: updateInput }))
  }

  const deleteAllTask = async () => {
    return Promise.all(listOfTask.data.listTasks.items.map(task => deleteTask(task)))
  }

  // 4) Set the "Retired" field to true for all mandate
  const deleteMandate = async mandate => {
    const input = {
      ...mandate,
      retired: true,
    }
    //console.log("RequestService.archiveRequest.deleteMandate:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestService.archiveRequest.deleteMandate:updateInput", updateInput)
    return await API.graphql(graphqlOperation(updateMandate, { input: updateInput }))
  }

  const deleteAllMandate = async () => {
    return Promise.all(listOfMandate.data.listMandates.items.map(mandate => deleteMandate(mandate)))
  }

  // 5) Set retired to true for request
  const deleteRequest = async () => {
    const input = {
      ...request,
      retired: true,
    }
    //console.log("RequestService.archiveRequest.deleteRequest:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestService.archiveRequest.deleteRequest:updateInput", updateInput)
    return await API.graphql(graphqlOperation(updateRequest, { input: updateInput }))
  }

  // 6)  Execute
  deleteAllTask().then(() => {
    deleteAllMandate().then(() => {
      deleteRequest()
    })
  })
}
