import React from "react"
import PropTypes from "prop-types"

import { Route, Switch, withRouter } from "react-router-dom"

import MandatesMasterPage from "./master/MandatesMasterPage"
import MandateDetailPage from "./detail/MandateDetailPage"
import Page404 from "components/Page404/Page404"

// Component
function MandatesRouter(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/`} component={MandatesMasterPage}/>
      <Route exact path={`${props.match.path}/:mandateId`} component={MandateDetailPage}/>
      <Route exact from={`${props.match.path}/:mandateId/:Id`} component={Page404}/>
    </Switch>
  )
}

MandatesRouter.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(MandatesRouter)
