import React from "react"
import PropTypes from "prop-types"

import Moment from "react-moment"
import { withRouter } from "react-router-dom"
import { Button } from "reactstrap"
import { isNil } from "lodash"

import { invoiceStatusClasses } from "helpers/lea-status_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function RequestDetailInvoiceTableRow(props) {
  const { key, invoice, onView } = props

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleViewInvoice(e, row) {
    //console.log("ContactTableRow.handleSelectContact:row", row);
    e.preventDefault()
    onView(e, row)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={invoice.id}>
      <td className="no-print" valign="middle" width="15%">
        <Button size="sm" color="secondary" outline onClick={e => handleViewInvoice(e, invoice.id)}>
          <i className="mdi mdi-eye" />
        </Button>
      </td>
      <td valign="middle">
        {isNil(invoice.issueDate) ? "" : <Moment format="YYYY-MM-DD">{invoice.issueDate}</Moment>}
      </td>
      <td valign="middle">
        {isNil(invoice.dueDate) ? "" : <Moment format="YYYY-MM-DD">{invoice.dueDate}</Moment>}
      </td>
      <td valign="middle">
        {isNil(invoice.paidDate) ? "" : <Moment format="YYYY-MM-DD">{invoice.paidDate}</Moment>}
      </td>
      <td valign="middle">
        {invoice.totalAmount.toFixed(2)}
      </td>
      <td valign="middle" className="text-end">
        <span className={"badge rounded-pill badge-soft-" + invoiceStatusClasses[invoice.status]}>
          {invoice.retired && (
            <s>{props.t("Common.Enum.InvoiceStatus." + invoice.status)}</s>
          )}
          {!invoice.retired && (
            <>{props.t("Common.Enum.InvoiceStatus." + invoice.status)}</>
          )}
        </span>
        {invoice.retired && <span className={"badge rounded-pill badge-soft-" + invoiceStatusClasses["RETIRED"]}>{props.t("Common.Enum.InvoiceStatus." + (invoice.retired ? "RETIRED" : invoice.status))}</span>}
      </td>
    </tr>
  )
}

RequestDetailInvoiceTableRow.propTypes = {
  t: PropTypes.any,
  key: PropTypes.any,
  invoice: PropTypes.any,
  onView: PropTypes.func,
}

export default withRouter(withTranslation()(RequestDetailInvoiceTableRow))
