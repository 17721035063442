import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Card, CardBody, CardFooter, CardLink, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from "reactstrap"
import { isEmpty } from "lodash"

import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function ContactDetailSummaryView(props) {
  const { contact, users, onEdit, onDelete } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuContact, setOpenMenuContact] = useState(false)
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleActionBackOnClick(e) {
    //console.log("ContactView.handleViewContacts");
    e.preventDefault()
    //props.history.push(URL_CONTACTS);
    props.history.goBack()
  }

  function handleActionEditOnClick(e) {
    //console.log("ContactView.handleOnEdit");
    e.preventDefault()
    onEdit(e)
  }

  function handleActionDeleteOnClick(e) {
    //console.log("ContactView.handleActionDeleteOnClick");
    e.preventDefault()
    toggleDeleteConfirmationForm() // Open ConfirmationForm
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("ContactView.handleDeleteConfirmationFormOnSave:confirmationData", confirmationData);
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(contact)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuContact = useCallback(() => {
    setOpenMenuContact(!openMenuContact)
  }, [openMenuContact])

  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card className="overflow-hidden">
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.ContactDetail.SummaryView")}</CardTitle>
            <p className="text-muted mb-2">{props.t("Page.ContactDetail.SummaryView.Subtitle")}</p>
          </div>
          <Dropdown direction="left" className="ms-1" isOpen={openMenuContact} toggle={toggleMenuContact}>
            <DropdownToggle tag="a" className="text-muted">
              <i className="mdi mdi-dots-vertical font-size-18" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={e => handleActionEditOnClick(e)}>{props.t("Page.ContactDetail.SummaryView.Action.Edit")}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="mt-2">
          <div className="table-responsive">
            <Table size="sm" className="table-nowrap">
              <tbody>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.Name")}</th>
                  <td>{contact.fullName}</td>
                </tr>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.Email")}</th>
                  <td>{contact.email}</td>
                </tr>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.PhoneNumber1")}</th>
                  <td>{isEmpty(contact?.phoneExtension) ? formatPhoneNumberAsLocal(contact?.phoneNumber) : props.t("Common.Text.Contact.PhoneNumber", { 0: formatPhoneNumberAsLocal(contact?.phoneNumber), 1: contact?.phoneExtension })}</td>
                </tr>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.PhoneNumber2")}</th>
                  <td>{isEmpty(contact?.phoneExtension2) ? formatPhoneNumberAsLocal(contact?.phoneNumber2) : props.t("Common.Text.Contact.PhoneNumber", { 0: formatPhoneNumberAsLocal(contact?.phoneNumber2), 1: contact?.phoneExtension2 })}</td>
                </tr>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.Address")}</th>
                  <td>{contact.address}</td>
                </tr>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.City")}</th>
                  <td>{contact.city}</td>
                </tr>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.StateOrProvince")}</th>
                  <td>{contact.stateOrProvince}</td>
                </tr>
                <tr>
                  <th scope="row">{props.t("Common.Label.Contact.ZipOrPostalCode")}</th>
                  <td>{contact.zipOrPostalCode}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
      <CardFooter className="no-print">
        <CardLink onClick={e => handleActionBackOnClick(e)}>{props.t("Common.Action.Back")}</CardLink>
      </CardFooter>
    </Card>
  )
}

ContactDetailSummaryView.defaultProps = {
  onEdit: function () {
    alert("onEdit not implemented")
  },
  onDelete: function () {
    alert("onDelete not implemented")
  },
}

ContactDetailSummaryView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  contact: PropTypes.any,
  users: PropTypes.any,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default withRouter(withTranslation()(ContactDetailSummaryView))
