export const getContactDetail = /* GraphQL */ `
  query getContactDetail($id: ID!, $filterRequestContacts: ModelRequestContactFilterInput, $filterMandateContacts: ModelMandateContactFilterInput, $limit: Int, $nextToken: String) {
    getContact(id: $id) {
      _deleted
      _version
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
    },
    listRequestContacts(filter: $filterRequestContacts, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        contactId
        requestId
        primary
        createdAt
        id
        retired
        updatedAt
      }
      nextToken
      startedAt
    },
    listMandateContacts(filter: $filterMandateContacts, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        contactId
        mandateId
        primary
        createdAt
        id
        retired
        updatedAt
      }
      nextToken
      startedAt
    },
  }
`;

export const listContactDetailSettings = /* GraphQL */ `
  query listRequestDetailSettings(
    $filterSettingsMandate: ModelSettingsMandateTypeFilterInput, 
    $filterUser: ModelUserFilterInput,
    $limit: Int, 
    $nextToken: String) {
    listSettingsMandateTypes(filter: $filterSettingsMandate, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        id
        retired
        updatedAt
        name
        cost
        translations {
          locale
          text
        }
        tasks
        order
      }
      nextToken
      startedAt
    },
    listUsers(filter: $filterUser, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        email
        id
        userStatus
        updatedAt
        tenantId
        cognitoId
        username
      }
      nextToken
      startedAt
    }
  }
`;