//import usFlag from "assets/images/flags/us.jpg"
//import frenchFlag from "assets/images/flags/french.jpg"

const languages = {
  en: {
    label: "English",
    //flag: usFlag,
  },
  fr: {
    label: "Français",
    //flag: frenchFlag,
  },
}

export default languages
