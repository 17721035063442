/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID,
    "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_USERPOOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_LEA_WEBAPP_CLIENT_ID,
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_APPSYNC_GRAPHQL_API_ENDPOINT,
    "aws_appsync_region": process.env.REACT_APP_APPSYNC_REGION,
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": process.env.REACT_APP_CUSTOMER_DATA_BUCKET,
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "geo": {
        "AmazonLocationService": {
          "maps": {
            "items": {
              "lea-map-general-purpose": {
                // REQUIRED - Amazon Location Service Map resource name
                "style": 'VectorOpenDataStandardLight' // REQUIRED - String representing the style of map resource
              }
            },
            "default": 'lea-map-general-purpose' // REQUIRED - Amazon Location Service Map resource name to set as default
          },
          "search_indices": {
            "items": ['explore.place'], // REQUIRED - Amazon Location Service Place Index name
            "default": 'explore.place' // REQUIRED - Amazon Location Service Place Index name to set as default
          },
          "region": 'ca-central-1' // REQUIRED - Amazon Location Service Region
        }
      }
};


export default awsmobile;
