import { isNull } from "lodash";

function getOptionById(options, id) {
    //console.log("options", options)
    //console.log("id", id)
    if (isNull(id)) {
        return []
    }
    return options.filter(
        function (option) {
            return option.id === id
        }
    )[0];
}

function getOptionByValue(options, searchValue) {
  if (isNull(searchValue)) {
    return []
  }
  return options.filter(
    function (option) {
      return option.value == searchValue
    }
  )[0];
}

export {getOptionById, getOptionByValue }