import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { withRouter } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Button, Card, CardBody, CardTitle, Col, Row, Spinner, Table } from "reactstrap"
import { isEmpty } from "lodash"

import UserCard from "./SettingsUserCard"
import UserTableRow from "./SettingsUserTableRow"

import { useCurrentUser } from "contexts/current-user-context"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { createUser } from "graphql/mutations"
import { listUsers } from "graphql/queries"
import { UserStatus } from "models"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsUsersView(props) {
  const emptyUser = { email: null, userStatus: null }

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openUserForm, setOpenUserForm] = useState(false)
  const [users, setUsers] = useState([])

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get list of users
  const { isLoading, isFetching, isError, error } = useQuery("listUsers", () => firstValueFrom(callAPI("listUsers", listUsers)), {
    retry: false,
    onSuccess: result => {
      //console.log("Users.listUsers.onSuccess:result", result)
      setUsers(isEmpty(result?.items) ? [] : result.items)
    },
    onError: error => {
      console.error("UsersView.listUsers.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleUserCardOnSave(userData) {
    //console.log("UsersView.handleUserCardOnSave:userData", userData);
    toggleUserForm() // Hide form
    doCreateUser(userData)
  }

  function handleUserTableRowOnSave(userData) {
    //console.log("UsersView.handleUserTableRowOnSave:userData", userData);
    alert(props.t("Common.Text.Alert.NotImplemented")) // TODO pour l'intant on ne permet pas l'édition d'une ligne doUpdate(userData)
    queryClient.invalidateQueries("listUsers")
  }

  function doCreateUser(userData) {
    console.log("UsersView.doCreateUser:userData", userData)
    const input = {
      ...userData,
      creator: username,
      userStatus: UserStatus.INVITING,
    }
    //console.log("UsersView.doCreateUser:input", input);
    let updateInput = removeGeneratedProperties(input)
    //console.log("UsersView.doCreateUser:updateInput", updateInput)
    createUserMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("UsersView.doCreateUser.createUserMutation.onSuccess:result", result)
        setUsers([...users, result])
      },
      onError: error => {
        console.error("UsersView.doCreateUser.createUserMutation.onError:error", error)
        queryClient.invalidateQueries("listUsers")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const createUserMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createUser", createUser, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleUserForm = useCallback(() => {
    setOpenUserForm(!openUserForm)
  }, [openUserForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const usersSortedAsc = users.sort((objA, objB) => Number(new Date(objA.createdAt)) - Number(new Date(objB.createdAt)))

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {(isLoading || isFetching) && <Spinner className="ms-2" color="primary" />}
      {(!isLoading && !isFetching && !isError) && 
        <CardBody className="bg-light">
          <CardTitle className="text-dark">
            {props.t("Page.Settings.UsersView")}&nbsp;
            <sup>
              <span className={"badge rounded-pill badge-soft-info font-size-12"}>{users?.length || 0}</span>
            </sup>
          </CardTitle>
          <div className="mb-0 mt-0">
            <p>{props.t("Page.Settings.UsersView.Subtitle")}</p>
            <hr className="mb-2" />
            <Row>
              <Col xl={12}>
                <Row>
                  <div className="text-sm-start mb-4 mt-2">
                    <Button color="primary" className="btn-rounded" onClick={toggleUserForm}>
                      <i className="mdi mdi-plus me-1" />
                      {props.t("Page.Settings.UsersView.Action.AddNew")}
                    </Button>
                  </div>
                  {openUserForm && <UserCard userInfo={emptyUser} onSave={handleUserCardOnSave} onCancel={toggleUserForm} />}
                </Row>
                <Row>
                  <Card>
                    <CardBody>
                      <Table responsive={true} striped={true} size="sm">
                        <thead>
                          <tr>
                            <th scope="col">{props.t("Common.Label.Contact.Email")}</th>
                            <th scope="col">{props.t("Common.Label.Contact.Username")}</th>
                            <th className="text-end" scope="col">
                              {props.t("Common.Label.Status")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {usersSortedAsc.map(row => (
                            <UserTableRow key={row.id} row={row} onSave={handleUserTableRowOnSave} />
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>
          </div>
        </CardBody>
      }
    </React.Fragment>
  )
}

SettingsUsersView.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SettingsUsersView))
