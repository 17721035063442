import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { withRouter } from "react-router-dom"
import { Button, ButtonGroup, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, Table, UncontrolledDropdown } from "reactstrap"
import { isEmpty, toNumber } from "lodash"
import TextareaAutosize from "@mui/base/TextareaAutosize"

import InvoiceDetailInvoiceRecordPaymentCard from "./InvoiceDetailInvoiceRecordPaymentCard"
import InvoiceDetailInvoiceSendCard from "./InvoiceDetailInvoiceSendCard"

import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"
import { invoiceStatusClasses } from "helpers/lea-status_helper"
import { Absent } from "js2xmlparser"
import { InvoiceStatus } from "models"

//i18n
import { withTranslation } from "react-i18next"

// Component
function InvoiceDetailInvoiceView(props) {
  const { invoice, onEdit, onUpdate } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuInvoice, setOpenMenuInvoice] = useState(false)
  const [openSendMessageForm, setOpenSendMessageForm] = useState(false)
  const [openRecordPaymentForm, setOpenRecordPaymentForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function sendInvoice(entityInvoice, data) {
    //console.log("InvoiceView.sendInvoice:entityInvoice", entityInvoice);
    //console.log("InvoiceView.sendInvoice:data", data);
    //console.log("InvoiceView.sendInvoice:JSON", JSON.stringify(entityInvoice, null, 2))
    var options = {
      declaration: {
        include: true,
        encoding: "UTF-8",
        version: "1.1",
      },
      typeHandlers: {
        "[object Null]": function (value) {
          return Absent.instance
        },
      },
      wrapHandlers: {
        lines: function () {
          return "line"
        },
      },
    }
    var js2xmlparser = require("js2xmlparser")
    console.log("InvoiceDetail.sendInvoice:XML", js2xmlparser.parse("invoice", removeInvoiceProperties(entityInvoice), options))
    if (isEmpty(entityInvoice.sentDate)) {
      alert(props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Feature.NotImplemented"))
    } else {
      alert(props.t("Common.Text.Alert.NotImplemented"))
    }

    return
    entityInvoice.sentDate = new Date()
    entityInvoice.status = InvoiceStatus.SENT
    handleUpdateInvoice(entityInvoice)
    setOpenSendMessageForm(false)
    setInfoMessage(props.t("Page.InvoiceDetail.Card.Document.MessageForm.Alert.Message.Sent", { 0: data.recipient }))
    setShowInfo(true)
  }

  // Mark as draft
  function markAsDraft(e) {
    e.preventDefault()
    invoice.paidDate = null
    invoice.sentDate = null
    invoice.status = InvoiceStatus.DRAFT
    onUpdate(invoice)
  }

  // Mark as sent
  function markAsSent(e) {
    e.preventDefault()
    invoice.sentDate = new Date().toISOString().split("T")[0]
    invoice.status = InvoiceStatus.SENT
    onUpdate(invoice)
  }

  // Record payment
  function recordPayment(invoice) {
    toggleRecordPaymentForm()
    invoice.status = InvoiceStatus.PAID
    onUpdate(invoice)
  }

  // Remove payment
  function removePayment(e) {
    e.preventDefault()
    invoice.paidDate = null
    invoice.status = InvoiceStatus.SENT
    onUpdate(invoice)
  }

  // Print the Invoice
  function printInvoice(e) {
    //console.log("InvoiceDetail.printInvoice");
    e.preventDefault()
    window.print()
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuInvoice = useCallback(() => {
    setOpenMenuInvoice(!openMenuInvoice)
  }, [openMenuInvoice])

  const toggleSendMessageForm = useCallback(() => {
    setOpenSendMessageForm(!openSendMessageForm)
  }, [openSendMessageForm])

  const toggleRecordPaymentForm = useCallback(() => {
    setOpenRecordPaymentForm(!openRecordPaymentForm)
  }, [openRecordPaymentForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const renderBillFromAddress = () => {
    return (
      <address>
        <div>
          <h3>
            <strong>{invoice?.billFrom?.fullName}</strong>
          </h3>
          <p className="mb-0">{invoice?.billFrom?.address}</p>
          <p className="mb-0">
            {invoice?.billFrom?.city}
            {isEmpty(invoice?.billFrom?.stateOrProvince) ? ", " : ", " + invoice?.billFrom?.stateOrProvince}
            {isEmpty(invoice?.billFrom?.zipOrPostalCode) ? ", " : ", " + invoice?.billFrom?.zipOrPostalCode}
          </p>
          <p className="mb-0">{invoice?.billFrom?.email}</p>
          <p className="mb-0">{formatPhoneNumberAsLocal(invoice?.billFrom?.phoneNumber)}</p>
        </div>
      </address>
    )
  }

  const renderBillToAddress = () => {
    return (
      <address>
        <div>
          <strong>{props.t("Page.InvoiceDetail.InvoiceView.BillTo")}</strong>
        </div>
        <div>
          <p className="mb-0">{invoice?.billTo?.fullName}</p>
          <p className="mb-0">{invoice?.billTo?.address}</p>
          <p className="mb-0">
            {invoice?.billTo?.city}
            {isEmpty(invoice?.billTo?.stateOrProvince) ? ", " : ", " + invoice?.billTo?.stateOrProvince}
            {isEmpty(invoice?.billTo?.zipOrPostalCode) ? ", " : ", " + invoice?.billTo?.zipOrPostalCode}
          </p>
          <p className="mb-0">{invoice?.billTo?.email}</p>
          <p className="mb-0">{formatPhoneNumberAsLocal(invoice?.billTo?.phoneNumber)}</p>
        </div>
      </address>
    )
  }

  const renderInvoiceTitle = () => {
    return (
      <React.Fragment>
        <div>
          <h3 className="text-end">
            <strong>{props.t("Page.InvoiceDetail.InvoiceView")}</strong>
          </h3>
          {!isEmpty(invoice.invoiceNumber) &&
            <p className="mb-0 text-end">{props.t("Common.Text.Invoice.InvoiceNumber", {0 : invoice.invoiceNumber })}</p>
          }
          <p className="mb-0 text-end">{props.t("Common.Text.Date.IssueDate", {0 : moment(invoice.issueDate).format('YYYY-MM-DD')})}</p>
          <p className="mb-0 text-end">{props.t("Common.Text.Date.DueDate", {0 : moment(invoice.dueDate).format('YYYY-MM-DD'), 1: invoice.paymentTerm})}</p>
        </div>
      </React.Fragment>
    )
  }

  // const renderInvoiceDates = () => {
  //   return (
  //     <React.Fragment>
  //       <div>
  //         <p className="mb-0 text-end">{props.t("Common.Text.Date.IssueDate", {0 : moment(invoice.issueDate).format('YYYY-MM-DD')})}</p>
  //         <p className="mb-0 text-end">{props.t("Common.Text.Date.DueDate", {0 : moment(invoice.dueDate).format('YYYY-MM-DD'), 1: invoice.paymentTerm})}</p>
  //       </div>
  //     </React.Fragment>
  //   )
  // }

  const renderSummary = () => {
    return (
      <div className="table-responsive">
        <Table size="sm" className="table-nowrap">
          <thead>
            <tr>
              {/*<th className="text-start" style={{ width: "70px" }}>No.</th>*/}
              <th>{props.t("Page.InvoiceDetail.InvoiceView.Table.Field.Description")}</th>
              <th>{props.t("Page.InvoiceDetail.InvoiceView.Table.Field.Quantity")}</th>
              <th>{props.t("Page.InvoiceDetail.InvoiceView.Table.Field.UnitPrice")}</th>
              <th>{props.t("Page.InvoiceDetail.InvoiceView.Table.Field.Tax")}</th>
              <th>{props.t("Page.InvoiceDetail.InvoiceView.Table.Field.Tax2")}</th>
              <th className="text-end">{props.t("Common.Label.Amount")}</th>
            </tr>
          </thead>
          <tbody>
            {invoice.lines.map(lineItem => (
              <tr key={lineItem.id}>
                <td>
                  <Input tag={TextareaAutosize} rows={3} cols={60} id={"inputDescription-" + lineItem.id} type="textarea" defaultValue={lineItem.description} readOnly style={{ backgroundColor: "white", borderColor: "Transparent", resize: "none" }} />
                </td>
                <td>{toNumber(lineItem.quantity).toFixed(2)}</td>
                <td>{toNumber(lineItem.unitPrice).toFixed(2)}</td>
                <td>
                  <input defaultChecked={lineItem.isTaxed} type="checkbox" disabled />
                </td>
                <td>
                  <input defaultChecked={lineItem.isTaxed2} type="checkbox" disabled />
                </td>
                <td className="text-end">{toNumber(lineItem.amount).toFixed(2)}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="5" className="text-end">
                {props.t("Page.InvoiceDetail.InvoiceView.SubTotal")}
              </td>
              <td className="text-end">{toNumber(invoice.subtotalAmount).toFixed(2)}</td>
            </tr>
            {invoice.taxDiscount > 0 && (
              <tr>
                <td colSpan="5" className="text-end">
                  {props.t("Page.InvoiceDetail.InvoiceView.Discount", { 0: toNumber(invoice.taxDiscount).toFixed(2) })}
                </td>
                <td className="text-end">{toNumber(invoice.discountAmount).toFixed(2)}</td>
              </tr>
            )}
            <tr>
              <td colSpan="5" className="text-end">
                {props.t("Page.InvoiceDetail.InvoiceView.Tax", { 0: toNumber(invoice.tax).toFixed(3) })}
              </td>
              <td className="text-end">{toNumber(invoice.taxAmount).toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="5" className="text-end">
                {props.t("Page.InvoiceDetail.InvoiceView.Tax2", { 0: toNumber(invoice.tax2).toFixed(3) })}
              </td>
              <td className="text-end">{toNumber(invoice.tax2Amount).toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="5" className="border-0 text-end">
                <h4>{props.t("Page.InvoiceDetail.InvoiceView.Total")}</h4>
              </td>
              <td className="border-0 text-end">
                <h4 className="m-0">{toNumber(invoice.totalAmount).toFixed(2)}</h4>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }

  const removeInvoiceProperties = input => {
    const { _deleted, _version, ...rest } = input
    return rest
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Row>
        <div className="d-flex no-print">
          <div className="flex-grow-1 mb-3">
            {invoice.status === InvoiceStatus.DRAFT &&
              <React.Fragment>
                <Button size="sm" className="me-2" color="primary" onClick={toggleSendMessageForm}>
                    <i className="bx bx-send me-1" /> {props.t("Common.Action.Send")}
                </Button>
                {/* We do not allow to modification of an archived invoice OR when the "Send invoice" form is open */}
                {!invoice.retired && !openSendMessageForm &&
                  <Button size="sm" className="me-2" color="secondary" outline onClick={onEdit}>
                    <i className="bx bx-edit-alt me-1" /> {props.t("Common.Action.Edit")}
                  </Button>
                }
              </React.Fragment>
            }
            {invoice.status === InvoiceStatus.SENT && !openSendMessageForm && !openRecordPaymentForm &&
              <React.Fragment>
                <Button size="sm" className="me-2" color="primary" onClick={toggleRecordPaymentForm}>
                  <i className="bx bx-calendar-check me-1" /> {props.t("Page.InvoiceDetail.InvoiceView.Action.RecordPayment")}
                </Button>
                <Button size="sm" className="me-2" color="secondary" outline onClick={toggleSendMessageForm}>
                  <i className="bx bx-send me-1" /> {props.t("Common.Action.Resend")}
                </Button>
              </React.Fragment> 
            }
            {invoice.status === InvoiceStatus.PAID &&
              <Button size="sm" className="me-2" color="primary" onClick={e => removePayment(e)}>
                  <i className="bx bx-calendar-x me-1" /> {props.t("Page.InvoiceDetail.InvoiceView.Action.RemovePayment")}
              </Button>
            }
            {!openSendMessageForm && !openRecordPaymentForm &&
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle caret outline size="sm">
                  {props.t("Page.InvoiceDetail.InvoiceView.Action.OtherActions")}
                  </DropdownToggle>
                  <DropdownMenu>
                    {invoice.status === InvoiceStatus.DRAFT && 
                      <React.Fragment>
                        <DropdownItem onClick={e => markAsSent(e)}>
                          {props.t("Page.InvoiceDetail.InvoiceView.Action.MarkAsSent")}
                        </DropdownItem>
                        <DropdownItem divider />
                      </React.Fragment>
                    }
                    {invoice.status === InvoiceStatus.SENT &&
                      <React.Fragment>
                        <DropdownItem onClick={e => markAsDraft(e)}>
                          {props.t("Page.InvoiceDetail.InvoiceView.Action.MarkAsDraft")}
                        </DropdownItem>
                        <DropdownItem divider />
                      </React.Fragment>
                    }
                    <DropdownItem onClick={(e) => printInvoice(e)}>
                      {/* <i className="fa fa-print me-1" /> */}
                      {props.t("Common.Action.Print")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            }
            {openSendMessageForm && <InvoiceDetailInvoiceSendCard invoice={invoice} onCancel={toggleSendMessageForm} onSave={sendInvoice}></InvoiceDetailInvoiceSendCard>}
            {openRecordPaymentForm && <InvoiceDetailInvoiceRecordPaymentCard invoice={invoice} onCancel={toggleRecordPaymentForm} onSave={recordPayment}></InvoiceDetailInvoiceRecordPaymentCard>}
          </div>
        </div>
      </Row>
      <Card style={{boxShadow : "none"}}>
        <CardBody>
          {/* 
            ========================================  Status  ========================================
          */}
          <Row>
            <Col>
              <h4 className="text-center">
                <span className={"no-print badge rounded-pill badge-soft-" + invoiceStatusClasses[invoice.status]}>
                  {invoice.retired && <s>{props.t("Common.Enum.InvoiceStatus." + invoice.status)}</s>}
                  {!invoice.retired && props.t("Common.Enum.InvoiceStatus." + invoice.status)}
                </span>
                {invoice.retired && <span className={"badge rounded-pill badge-soft-" + invoiceStatusClasses["RETIRED"]}>{props.t("Common.Enum.InvoiceStatus." + (invoice.retired ? "RETIRED" : invoice.status))}</span>}
              </h4>
            </Col>
          </Row>
          {/* 
            ===================================  Bill from & Title  ===================================
          */}
          <Row>
            <div className="d-flex /*align-items-center*/">
              <div className="flex-grow-1">
                {renderBillFromAddress()}
              </div>
              {renderInvoiceTitle()}
            </div>
          </Row>
          {/* 
            ========================================  Bill to  ========================================
          */}
          <hr className="mt-0" />
          <Row>
            <div className="d-flex /*align-items-center*/">
              <div className="flex-grow-1">
                {renderBillToAddress()}
              </div>
              {/* {renderInvoiceDates()} */}
            </div>
          </Row>
          {/* 
            ========================================  Subject  ========================================
          */}
          {!isEmpty(invoice.subject) && (
            <Row>
              <Col xl={1}>
                <div>
                  <strong>{props.t("Page.InvoiceDetail.InvoiceView.Subject")}</strong>
                </div>
              </Col>
              <Col xl={11}>
                <div>{invoice.subject}</div>
              </Col>
            </Row>
          )}
          {/* 
            ========================================  Summary  ========================================
          */}
          {isEmpty(invoice.subject) ? <hr className="mt-0" /> : <hr />}
          <Row>
            <Col xs={12}>
              <h5>{props.t("Page.InvoiceDetail.InvoiceView.Table")}</h5>
            </Col>
            <Col xs={12}>
              {renderSummary()}
            </Col>
          </Row>
          {/* 
            =========================================  Notes  =========================================
          */}
          <Row>
            <Col xs={12}>
              <Label for="inputNotes">{props.t("Page.InvoiceDetail.InvoiceView.Notes")}</Label>
              <div className="d-flex">
                <Input tag={TextareaAutosize} id="inputNotes" type="textarea" defaultValue={invoice.notes} readOnly style={{ backgroundColor: "white" }} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

InvoiceDetailInvoiceView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  invoice: PropTypes.any,
  onEdit: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default withRouter(withTranslation()(InvoiceDetailInvoiceView))
