import React, { useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { useQuery } from "react-query"
import { Card, CardBody, CardTitle, Col, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap"
import { useCurrentUser } from "contexts/current-user-context"
import { isEmpty } from "lodash"

import DesktopRequestTableRow from "./DesktopRequestTableRow"

import { callAPI } from "helpers/lea-graphql_helper"
import { listRequests } from "graphql/queries"
import { RequestStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function DesktopRequestsView(props) {
  const {
    currentUser: { username },
  } = useCurrentUser()

  // State
  const [requests, setRequests] = useState([])
  const [requestsPending, setRequestsPending] = useState([])
  const [requestsStarted, setRequestsStarted] = useState([])
  const [requestsCompleted, setRequestsCompleted] = useState([])
  const [users, setUsers] = useState([])

  const [showAlert, setShowAlert] = useState(false)
  const [showError, setShowError] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  // Get list of requests
  const { isError, isFetching, isIdle, isLoading, error } = useQuery(
    "listRequests",
    () =>
      firstValueFrom(
        callAPI("listRequests", listRequests, {
          filter: {
            assignee: { eq: username },
            retired: { ne: true },
          },
          limit: 10000,
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("DesktopRequestsView.listRequests.onSuccess:result", result)
        setRequests(isEmpty(result?.items) ? [] : result.items)
        setRequestsPending(
          result?.items.filter(item => {
            return item.status === RequestStatus.PENDING
          })
        )
        setRequestsStarted(
          result?.items.filter(item => {
            return item.status === RequestStatus.INPROGRESS
          })
        )
        setRequestsCompleted(
          result?.items.filter(item => {
            return item.status === RequestStatus.COMPLETED
          })
        )
      },
      onError: error => {
        console.error("DesktopRequestsView.listRequests.onError:error", error)
        setErrorMessage(error.errors[0].message)
        setShowError(true)
      },
    }
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {(isLoading || isFetching) && <Spinner className="ms-2" color="primary" />}
      {(!isLoading && !isFetching && !isError) &&
        <Card>
          <CardBody>
            <Row>
              <Col sm={4}>
                <Card color="danger" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{requestsPending?.length > 0 ? requestsPending?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-empty font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Pending")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={4}>
                <Card color="warning" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{requestsStarted?.length > 0 ? requestsStarted?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-half font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Inprogress")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={4}>
                <Card color="success" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{requestsCompleted?.length > 0 ? requestsCompleted?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-full font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Completed")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {requests.length > 0 &&
              <Row className="mt-4">
                <Col lg={12}>
                  <Table hover responsive size="sm">
                    <thead>
                      <tr>
                        <th width="8%">{props.t("Common.Label.Action")}</th>
                        <th width="10%">{props.t("Common.Label.Status")}</th>
                        <th>{props.t("Common.Label.PrimaryContact")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestsPending.map(req => (
                        <DesktopRequestTableRow key={req.id} request={req} />
                      ))}
                      {requestsStarted.map(req => (
                        <DesktopRequestTableRow key={req.id} request={req} />
                      ))}
                      {requestsCompleted.map(req => (
                        <DesktopRequestTableRow key={req.id} request={req} />
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            }
          </CardBody>
        </Card>
      }
    </React.Fragment>
  )
}

DesktopRequestsView.propTypes = {
  t: PropTypes.any,
  mandates: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(DesktopRequestsView))
