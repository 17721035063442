/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        id
        createdAt
        updatedAt
        address
        city
        phoneNumber
        stateOrProvince
        zipOrPostalCode
        phoneNumberDenom
        stateOrProvinceDenom
        zipOrPostalCodeDenom
        cityDenom
        addressDenom
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAddresses = /* GraphQL */ `
  query SyncAddresses(
    $filter: ModelAddressFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncAddresses(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        id
        createdAt
        updatedAt
        address
        city
        phoneNumber
        stateOrProvince
        zipOrPostalCode
        phoneNumberDenom
        stateOrProvinceDenom
        zipOrPostalCodeDenom
        cityDenom
        addressDenom
      }
      nextToken
      startedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        creator
        updateUser
        id
        createdAt
        updatedAt
        address
        city
        contactType
        email
        fullName
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
        addressDenom
        cityDenom
        emailDenom
        fullNameDenom
        phoneNumberDenom
        phoneNumber2Denom
        stateOrProvinceDenom
        zipOrPostalCodeDenom
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncContacts(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        creator
        updateUser
        id
        createdAt
        updatedAt
        address
        city
        contactType
        email
        fullName
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
        addressDenom
        cityDenom
        emailDenom
        fullNameDenom
        phoneNumberDenom
        phoneNumber2Denom
        stateOrProvinceDenom
        zipOrPostalCodeDenom
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeed = /* GraphQL */ `
  query GetDeed($id: ID!) {
    getDeed(id: $id) {
      _deleted
      _version
      createdAt
      dateOfSale
      id
      images {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      inscriptionNumber
      lots {
        address
        city
        id
        lotNumber
        stateOrProvince
        surface
        zipOrPostalCode
        zonages {
          id
          notes
          servitude
          usage
          zone
        }
      }
      purchasers {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      sellingPrice
      status
      updatedAt
      vendors {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
    }
  }
`;
export const listDeeds = /* GraphQL */ `
  query ListDeeds(
    $filter: ModelDeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        dateOfSale
        id
        images {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        inscriptionNumber
        lots {
          address
          city
          id
          lotNumber
          stateOrProvince
          surface
          zipOrPostalCode
          zonages {
            id
            notes
            servitude
            usage
            zone
          }
        }
        purchasers {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        sellingPrice
        status
        updatedAt
        vendors {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeeds = /* GraphQL */ `
  query SyncDeeds(
    $filter: ModelDeedFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncDeeds(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        createdAt
        dateOfSale
        id
        images {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        inscriptionNumber
        lots {
          address
          city
          id
          lotNumber
          stateOrProvince
          surface
          zipOrPostalCode
          zonages {
            id
            notes
            servitude
            usage
            zone
          }
        }
        purchasers {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        sellingPrice
        status
        updatedAt
        vendors {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getIndexation = /* GraphQL */ `
  query GetIndexation($id: ID!) {
    getIndexation(id: $id) {
      _deleted
      _version
      city
      createdAt
      id
      rates {
        dateOfRate
        overrides {
          dateOfRate
          rate
          reason
        }
        rate
      }
      updatedAt
    }
  }
`;
export const listIndexations = /* GraphQL */ `
  query ListIndexations(
    $filter: ModelIndexationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndexations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        city
        createdAt
        id
        rates {
          dateOfRate
          overrides {
            dateOfRate
            rate
            reason
          }
          rate
        }
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIndexations = /* GraphQL */ `
  query SyncIndexations(
    $filter: ModelIndexationFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncIndexations(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        city
        createdAt
        id
        rates {
          dateOfRate
          overrides {
            dateOfRate
            rate
            reason
          }
          rate
        }
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      _deleted
      _version
      billFrom {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      billTo {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      creator
      updateUser
      currencyCode
      discountAmount
      dueDate
      id
      isEstimate
      issueDate
      lines {
        amount
        description
        id
        isTaxed
        isTaxed2
        tax2Amount
        taxAmount
        mandateId
        quantity
        unitPrice
      }
      notes
      paidDate
      paymentMethod
      paymentNote
      paymentTerm
      requestId
      retired
      sentDate
      status
      subject
      subtotalAmount
      tax
      tax2
      tax2Amount
      taxAmount
      taxDiscount
      totalAmount
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        billFrom {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        billTo {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        logo {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        createdAt
        creator
        updateUser
        currencyCode
        discountAmount
        dueDate
        id
        isEstimate
        issueDate
        lines {
          amount
          description
          id
          isTaxed
          isTaxed2
          tax2Amount
          taxAmount
          mandateId
          quantity
          unitPrice
        }
        notes
        paidDate
        paymentMethod
        paymentNote
        paymentTerm
        requestId
        retired
        sentDate
        status
        subject
        subtotalAmount
        tax
        tax2
        tax2Amount
        taxAmount
        taxDiscount
        totalAmount
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInvoices = /* GraphQL */ `
  query SyncInvoices(
    $filter: ModelInvoiceFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncInvoices(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        billFrom {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        billTo {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        logo {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        createdAt
        creator
        updateUser
        currencyCode
        discountAmount
        dueDate
        id
        isEstimate
        issueDate
        lines {
          amount
          description
          id
          isTaxed
          isTaxed2
          tax2Amount
          taxAmount
          mandateId
          quantity
          unitPrice
        }
        notes
        paidDate
        paymentMethod
        paymentNote
        paymentTerm
        requestId
        retired
        sentDate
        status
        subject
        subtotalAmount
        tax
        tax2
        tax2Amount
        taxAmount
        taxDiscount
        totalAmount
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMandate = /* GraphQL */ `
  query GetMandate($id: ID!) {
    getMandate(id: $id) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      estimedDeliveryDate
      deliveryDate
      location {
        city
        stateOrProvince
        address
        zipOrPostalCode
        category
        coordinates
        lotNumber
        subcategory
      }
      goal
      goalReason
      mandateType
      order
      paperVersion
      priority
      purpose
      purposeReason
      reportType
      requestId
      retired
      status
    }
  }
`;
export const listMandates = /* GraphQL */ `
  query ListMandates(
    $filter: ModelMandateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMandates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        id
        createdAt
        creator
        updatedAt
        updateUser
        assignee
        primaryContact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        estimedDeliveryDate
        deliveryDate
        location {
          city
          stateOrProvince
          address
          zipOrPostalCode
          category
          coordinates
          lotNumber
          subcategory
        }
        goal
        goalReason
        mandateType
        order
        paperVersion
        priority
        purpose
        purposeReason
        reportType
        requestId
        retired
        status
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMandates = /* GraphQL */ `
  query SyncMandates(
    $filter: ModelMandateFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncMandates(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        id
        createdAt
        creator
        updatedAt
        updateUser
        assignee
        primaryContact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        estimedDeliveryDate
        deliveryDate
        location {
          city
          stateOrProvince
          address
          zipOrPostalCode
          category
          coordinates
          lotNumber
          subcategory
        }
        goal
        goalReason
        mandateType
        order
        paperVersion
        priority
        purpose
        purposeReason
        reportType
        requestId
        retired
        status
      }
      nextToken
      startedAt
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      _deleted
      _version
      body
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      subject
      taskId
      updatedAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        body
        createdAt
        creator
        updateUser
        id
        mandateId
        requestId
        retired
        subject
        taskId
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotes = /* GraphQL */ `
  query SyncNotes(
    $filter: ModelNoteFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncNotes(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        body
        createdAt
        creator
        updateUser
        id
        mandateId
        requestId
        retired
        subject
        taskId
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      communicationChannel
      estimedDeliveryDate
      deliveryDate
      requestDate
      referrer
      retired
      status
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        id
        createdAt
        creator
        updatedAt
        updateUser
        assignee
        primaryContact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        communicationChannel
        estimedDeliveryDate
        deliveryDate
        requestDate
        referrer
        retired
        status
      }
      nextToken
      startedAt
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      _deleted
      _version
      assignee
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      status
      taskType
      updatedAt
      description
      order
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        assignee
        createdAt
        creator
        updateUser
        id
        mandateId
        requestId
        retired
        status
        taskType
        updatedAt
        description
        order
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTasks = /* GraphQL */ `
  query SyncTasks(
    $filter: ModelTaskFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncTasks(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        assignee
        createdAt
        creator
        updateUser
        id
        mandateId
        requestId
        retired
        status
        taskType
        updatedAt
        description
        order
      }
      nextToken
      startedAt
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      updatedAt
      id
      name
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        creator
        updateUser
        createdAt
        updatedAt
        id
        name
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      email
      id
      userStatus
      updatedAt
      tenantId
      cognitoId
      username
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        creator
        updateUser
        createdAt
        email
        id
        userStatus
        updatedAt
        tenantId
        cognitoId
        username
      }
      nextToken
      startedAt
    }
  }
`;
export const getMandateContact = /* GraphQL */ `
  query GetMandateContact($id: ID!) {
    getMandateContact(id: $id) {
      _deleted
      _version
      creator
      updateUser
      contactId
      mandateId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const listMandateContacts = /* GraphQL */ `
  query ListMandateContacts(
    $filter: ModelMandateContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMandateContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        creator
        updateUser
        contactId
        mandateId
        roles
        contact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        primary
        createdAt
        id
        retired
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRequestContact = /* GraphQL */ `
  query GetRequestContact($id: ID!) {
    getRequestContact(id: $id) {
      _deleted
      _version
      creator
      updateUser
      contactId
      requestId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const listRequestContacts = /* GraphQL */ `
  query ListRequestContacts(
    $filter: ModelRequestContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        creator
        updateUser
        contactId
        requestId
        roles
        contact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        primary
        createdAt
        id
        retired
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSettingsCompanyInformation = /* GraphQL */ `
  query GetSettingsCompanyInformation($id: ID!) {
    getSettingsCompanyInformation(id: $id) {
      _deleted
      _version
      id
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      updatedAt
      creator
      updateUser
    }
  }
`;
export const listSettingsCompanyInformations = /* GraphQL */ `
  query ListSettingsCompanyInformations(
    $filter: ModelSettingsCompanyInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingsCompanyInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        id
        contact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        logo {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        createdAt
        updatedAt
        creator
        updateUser
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSettingsCompanyInformation = /* GraphQL */ `
  query SyncSettingsCompanyInformation(
    $filter: ModelSettingsCompanyInformationFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncSettingsCompanyInformation(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        id
        contact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        logo {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        createdAt
        updatedAt
        creator
        updateUser
      }
      nextToken
      startedAt
    }
  }
`;
export const getSettingsMandateType = /* GraphQL */ `
  query GetSettingsMandateType($id: ID!) {
    getSettingsMandateType(id: $id) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      cost
      translations {
        locale
        text
      }
      tasks
      order
    }
  }
`;
export const listSettingsMandateTypes = /* GraphQL */ `
  query ListSettingsMandateTypes(
    $filter: ModelSettingsMandateTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingsMandateTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        creator
        updateUser
        createdAt
        id
        retired
        updatedAt
        name
        cost
        translations {
          locale
          text
        }
        tasks
        order
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSettingsMandateTypes = /* GraphQL */ `
  query SyncSettingsMandateTypes(
    $filter: ModelSettingsMandateTypeFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncSettingsMandateTypes(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        creator
        updateUser
        createdAt
        id
        retired
        updatedAt
        name
        cost
        translations {
          locale
          text
        }
        tasks
        order
      }
      nextToken
      startedAt
    }
  }
`;
export const getSettingsTaskType = /* GraphQL */ `
  query GetSettingsTaskType($id: ID!) {
    getSettingsTaskType(id: $id) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      translations {
        locale
        text
      }
      order
    }
  }
`;
export const listSettingsTaskTypes = /* GraphQL */ `
  query ListSettingsTaskTypes(
    $filter: ModelSettingsTaskTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingsTaskTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        creator
        updateUser
        createdAt
        id
        retired
        updatedAt
        name
        translations {
          locale
          text
        }
        order
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSettingsTaskTypes = /* GraphQL */ `
  query SyncSettingsTaskTypes(
    $filter: ModelSettingsTaskTypeFilterInput
    $lastSync: AWSTimestamp
    $limit: Int
    $nextToken: String
  ) {
    syncSettingsTaskTypes(
      filter: $filter
      lastSync: $lastSync
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        _deleted
        _version
        creator
        updateUser
        createdAt
        id
        retired
        updatedAt
        name
        translations {
          locale
          text
        }
        order
      }
      nextToken
      startedAt
    }
  }
`;
