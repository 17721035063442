import React, { useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"

import DesktopTasksView from "./tasks/DesktopTasksView"
import DesktopRequestsView from "./requests/DesktopRequestsView"
import DesktopMandatesView from "./mandates/DesktopMandatesView"

//i18n
import { withTranslation } from "react-i18next"

// Component
function DesktopPage(props) {
  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [verticalActiveTab, setVerticalActiveTab] = useState("1")

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleVerticalTab = tab => {
    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab)
    }
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Desktop</title>
      </MetaTags>
      <Container fluid={true}>
        {/* <h4>{props.t("Page.Desktop")}</h4> */}
        <div className="checkout-tabs">
          <Row>
            <Col sm="2">
              <Nav className="flex-column" pills>
                <NavItem>
                  <NavLink
                    className={classnames({ active: verticalActiveTab === "2" })}
                    onClick={() => {
                      toggleVerticalTab("2")
                    }}
                  >
                  <i className="fas fa-seedling d-block check-nav-icon mt-4 mb-2"></i>
                  <p className="font-weight-bold mb-4">{props.t("Page.Desktop.Text.MyRequests")}</p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: verticalActiveTab === "3" })}
                    onClick={() => {
                      toggleVerticalTab("3")
                    }}
                  >
                  <i className="fas fa-file-alt d-block check-nav-icon mt-4 mb-2"></i>
                  <p className="font-weight-bold mb-4">{props.t("Page.Desktop.Text.MyMandates")}</p>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: verticalActiveTab === "1" })}
                    onClick={() => {
                      toggleVerticalTab("1")
                    }}
                  >
                  <i className="fas fa-tasks d-block check-nav-icon mt-4 mb-2"></i>
                  <p className="font-weight-bold mb-4">{props.t("Page.Desktop.Text.MyTasks")}</p>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: verticalActiveTab === "4" })}
                    onClick={() => {
                      toggleVerticalTab("4")
                    }}
                    >
                    <i className="fas fa-calendar-check d-block check-nav-icon mt-4 mb-2"></i>
                    <p className="font-weight-bold mb-4">{props.t("Page.Desktop.Text.MyAppointments")}</p>
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Col>
            <Col sm="10">
              <TabContent activeTab={verticalActiveTab}>
                <TabPane tabId="1">
                  <DesktopTasksView />
                </TabPane>
                <TabPane tabId="2">
                  <DesktopRequestsView/>
                </TabPane>
                <TabPane tabId="3">
                  <DesktopMandatesView />
                </TabPane>
                <TabPane tabId="4">
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

DesktopPage.propTypes = {
  t: PropTypes.any,
  props: PropTypes.any,
}

export default withRouter(withTranslation()(DesktopPage))
