/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $condition: ModelAddressConditionInput
    $input: CreateAddressInput!
  ) {
    createAddress(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $condition: ModelAddressConditionInput
    $input: DeleteAddressInput!
  ) {
    deleteAddress(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $condition: ModelAddressConditionInput
    $input: UpdateAddressInput!
  ) {
    updateAddress(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $condition: ModelContactConditionInput
    $input: CreateContactInput!
  ) {
    createContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $condition: ModelContactConditionInput
    $input: DeleteContactInput!
  ) {
    deleteContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $condition: ModelContactConditionInput
    $input: UpdateContactInput!
  ) {
    updateContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      id
      createdAt
      updatedAt
      address
      city
      contactType
      email
      fullName
      phoneNumber
      phoneExtension
      phoneNumber2
      phoneExtension2
      stateOrProvince
      zipOrPostalCode
      addressDenom
      cityDenom
      emailDenom
      fullNameDenom
      phoneNumberDenom
      phoneNumber2Denom
      stateOrProvinceDenom
      zipOrPostalCodeDenom
    }
  }
`;
export const createDeed = /* GraphQL */ `
  mutation CreateDeed(
    $condition: ModelDeedConditionInput
    $input: CreateDeedInput!
  ) {
    createDeed(condition: $condition, input: $input) {
      _deleted
      _version
      createdAt
      dateOfSale
      id
      images {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      inscriptionNumber
      lots {
        address
        city
        id
        lotNumber
        stateOrProvince
        surface
        zipOrPostalCode
        zonages {
          id
          notes
          servitude
          usage
          zone
        }
      }
      purchasers {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      sellingPrice
      status
      updatedAt
      vendors {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
    }
  }
`;
export const deleteDeed = /* GraphQL */ `
  mutation DeleteDeed(
    $condition: ModelDeedConditionInput
    $input: DeleteDeedInput!
  ) {
    deleteDeed(condition: $condition, input: $input) {
      _deleted
      _version
      createdAt
      dateOfSale
      id
      images {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      inscriptionNumber
      lots {
        address
        city
        id
        lotNumber
        stateOrProvince
        surface
        zipOrPostalCode
        zonages {
          id
          notes
          servitude
          usage
          zone
        }
      }
      purchasers {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      sellingPrice
      status
      updatedAt
      vendors {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
    }
  }
`;
export const updateDeed = /* GraphQL */ `
  mutation UpdateDeed(
    $condition: ModelDeedConditionInput
    $input: UpdateDeedInput!
  ) {
    updateDeed(condition: $condition, input: $input) {
      _deleted
      _version
      createdAt
      dateOfSale
      id
      images {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      inscriptionNumber
      lots {
        address
        city
        id
        lotNumber
        stateOrProvince
        surface
        zipOrPostalCode
        zonages {
          id
          notes
          servitude
          usage
          zone
        }
      }
      purchasers {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      sellingPrice
      status
      updatedAt
      vendors {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
    }
  }
`;
export const createIndexation = /* GraphQL */ `
  mutation CreateIndexation(
    $condition: ModelIndexationConditionInput
    $input: CreateIndexationInput!
  ) {
    createIndexation(condition: $condition, input: $input) {
      _deleted
      _version
      city
      createdAt
      id
      rates {
        dateOfRate
        overrides {
          dateOfRate
          rate
          reason
        }
        rate
      }
      updatedAt
    }
  }
`;
export const deleteIndexation = /* GraphQL */ `
  mutation DeleteIndexation(
    $condition: ModelIndexationConditionInput
    $input: DeleteIndexationInput!
  ) {
    deleteIndexation(condition: $condition, input: $input) {
      _deleted
      _version
      city
      createdAt
      id
      rates {
        dateOfRate
        overrides {
          dateOfRate
          rate
          reason
        }
        rate
      }
      updatedAt
    }
  }
`;
export const updateIndexation = /* GraphQL */ `
  mutation UpdateIndexation(
    $condition: ModelIndexationConditionInput
    $input: UpdateIndexationInput!
  ) {
    updateIndexation(condition: $condition, input: $input) {
      _deleted
      _version
      city
      createdAt
      id
      rates {
        dateOfRate
        overrides {
          dateOfRate
          rate
          reason
        }
        rate
      }
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $condition: ModelInvoiceConditionInput
    $input: CreateInvoiceInput!
  ) {
    createInvoice(condition: $condition, input: $input) {
      _deleted
      _version
      billFrom {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      billTo {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      creator
      updateUser
      currencyCode
      discountAmount
      dueDate
      id
      isEstimate
      issueDate
      lines {
        amount
        description
        id
        isTaxed
        isTaxed2
        tax2Amount
        taxAmount
        mandateId
        quantity
        unitPrice
      }
      notes
      paidDate
      paymentMethod
      paymentNote
      paymentTerm
      requestId
      retired
      sentDate
      status
      subject
      subtotalAmount
      tax
      tax2
      tax2Amount
      taxAmount
      taxDiscount
      totalAmount
      updatedAt
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $condition: ModelInvoiceConditionInput
    $input: DeleteInvoiceInput!
  ) {
    deleteInvoice(condition: $condition, input: $input) {
      _deleted
      _version
      billFrom {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      billTo {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      creator
      updateUser
      currencyCode
      discountAmount
      dueDate
      id
      isEstimate
      issueDate
      lines {
        amount
        description
        id
        isTaxed
        isTaxed2
        tax2Amount
        taxAmount
        mandateId
        quantity
        unitPrice
      }
      notes
      paidDate
      paymentMethod
      paymentNote
      paymentTerm
      requestId
      retired
      sentDate
      status
      subject
      subtotalAmount
      tax
      tax2
      tax2Amount
      taxAmount
      taxDiscount
      totalAmount
      updatedAt
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $condition: ModelInvoiceConditionInput
    $input: UpdateInvoiceInput!
  ) {
    updateInvoice(condition: $condition, input: $input) {
      _deleted
      _version
      billFrom {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      billTo {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      creator
      updateUser
      currencyCode
      discountAmount
      dueDate
      id
      isEstimate
      issueDate
      lines {
        amount
        description
        id
        isTaxed
        isTaxed2
        tax2Amount
        taxAmount
        mandateId
        quantity
        unitPrice
      }
      notes
      paidDate
      paymentMethod
      paymentNote
      paymentTerm
      requestId
      retired
      sentDate
      status
      subject
      subtotalAmount
      tax
      tax2
      tax2Amount
      taxAmount
      taxDiscount
      totalAmount
      updatedAt
    }
  }
`;
export const createMandate = /* GraphQL */ `
  mutation CreateMandate(
    $condition: ModelMandateConditionInput
    $input: CreateMandateInput!
  ) {
    createMandate(condition: $condition, input: $input) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      estimedDeliveryDate
      deliveryDate
      location {
        city
        stateOrProvince
        address
        zipOrPostalCode
        category
        coordinates
        lotNumber
        subcategory
      }
      goal
      goalReason
      mandateType
      order
      paperVersion
      priority
      purpose
      purposeReason
      reportType
      requestId
      retired
      status
    }
  }
`;
export const deleteMandate = /* GraphQL */ `
  mutation DeleteMandate(
    $condition: ModelMandateConditionInput
    $input: DeleteMandateInput!
  ) {
    deleteMandate(condition: $condition, input: $input) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      estimedDeliveryDate
      deliveryDate
      location {
        city
        stateOrProvince
        address
        zipOrPostalCode
        category
        coordinates
        lotNumber
        subcategory
      }
      goal
      goalReason
      mandateType
      order
      paperVersion
      priority
      purpose
      purposeReason
      reportType
      requestId
      retired
      status
    }
  }
`;
export const updateMandate = /* GraphQL */ `
  mutation UpdateMandate(
    $condition: ModelMandateConditionInput
    $input: UpdateMandateInput!
  ) {
    updateMandate(condition: $condition, input: $input) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      estimedDeliveryDate
      deliveryDate
      location {
        city
        stateOrProvince
        address
        zipOrPostalCode
        category
        coordinates
        lotNumber
        subcategory
      }
      goal
      goalReason
      mandateType
      order
      paperVersion
      priority
      purpose
      purposeReason
      reportType
      requestId
      retired
      status
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $condition: ModelNoteConditionInput
    $input: CreateNoteInput!
  ) {
    createNote(condition: $condition, input: $input) {
      _deleted
      _version
      body
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      subject
      taskId
      updatedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $condition: ModelNoteConditionInput
    $input: DeleteNoteInput!
  ) {
    deleteNote(condition: $condition, input: $input) {
      _deleted
      _version
      body
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      subject
      taskId
      updatedAt
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $condition: ModelNoteConditionInput
    $input: UpdateNoteInput!
  ) {
    updateNote(condition: $condition, input: $input) {
      _deleted
      _version
      body
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      subject
      taskId
      updatedAt
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $condition: ModelRequestConditionInput
    $input: CreateRequestInput!
  ) {
    createRequest(condition: $condition, input: $input) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      communicationChannel
      estimedDeliveryDate
      deliveryDate
      requestDate
      referrer
      retired
      status
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $condition: ModelRequestConditionInput
    $input: DeleteRequestInput!
  ) {
    deleteRequest(condition: $condition, input: $input) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      communicationChannel
      estimedDeliveryDate
      deliveryDate
      requestDate
      referrer
      retired
      status
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $condition: ModelRequestConditionInput
    $input: UpdateRequestInput!
  ) {
    updateRequest(condition: $condition, input: $input) {
      _deleted
      _version
      id
      createdAt
      creator
      updatedAt
      updateUser
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      communicationChannel
      estimedDeliveryDate
      deliveryDate
      requestDate
      referrer
      retired
      status
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $condition: ModelTaskConditionInput
    $input: CreateTaskInput!
  ) {
    createTask(condition: $condition, input: $input) {
      _deleted
      _version
      assignee
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      status
      taskType
      updatedAt
      description
      order
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $condition: ModelTaskConditionInput
    $input: DeleteTaskInput!
  ) {
    deleteTask(condition: $condition, input: $input) {
      _deleted
      _version
      assignee
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      status
      taskType
      updatedAt
      description
      order
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $condition: ModelTaskConditionInput
    $input: UpdateTaskInput!
  ) {
    updateTask(condition: $condition, input: $input) {
      _deleted
      _version
      assignee
      createdAt
      creator
      updateUser
      id
      mandateId
      requestId
      retired
      status
      taskType
      updatedAt
      description
      order
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $condition: ModelTenantConditionInput
    $input: CreateTenantInput!
  ) {
    createTenant(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      updatedAt
      id
      name
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $condition: ModelTenantConditionInput
    $input: DeleteTenantInput!
  ) {
    deleteTenant(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      updatedAt
      id
      name
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $condition: ModelTenantConditionInput
    $input: UpdateTenantInput!
  ) {
    updateTenant(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      updatedAt
      id
      name
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $condition: ModelUserConditionInput
    $input: CreateUserInput!
  ) {
    createUser(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      email
      id
      userStatus
      updatedAt
      tenantId
      cognitoId
      username
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $condition: ModelUserConditionInput
    $input: DeleteUserInput!
  ) {
    deleteUser(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      email
      id
      userStatus
      updatedAt
      tenantId
      cognitoId
      username
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $condition: ModelUserConditionInput
    $input: UpdateUserInput!
  ) {
    updateUser(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      email
      id
      userStatus
      updatedAt
      tenantId
      cognitoId
      username
    }
  }
`;
export const createMandateContact = /* GraphQL */ `
  mutation CreateMandateContact(
    $condition: ModelMandateContactConditionInput
    $input: CreateMandateContactInput!
  ) {
    createMandateContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      contactId
      mandateId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const deleteMandateContact = /* GraphQL */ `
  mutation DeleteMandateContact(
    $condition: ModelMandateContactConditionInput
    $input: DeleteMandateContactInput!
  ) {
    deleteMandateContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      contactId
      mandateId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const updateMandateContact = /* GraphQL */ `
  mutation UpdateMandateContact(
    $condition: ModelMandateContactConditionInput
    $input: UpdateMandateContactInput!
  ) {
    updateMandateContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      contactId
      mandateId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const createRequestContact = /* GraphQL */ `
  mutation CreateRequestContact(
    $condition: ModelRequestContactConditionInput
    $input: CreateRequestContactInput!
  ) {
    createRequestContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      contactId
      requestId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const deleteRequestContact = /* GraphQL */ `
  mutation DeleteRequestContact(
    $condition: ModelRequestContactConditionInput
    $input: DeleteRequestContactInput!
  ) {
    deleteRequestContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      contactId
      requestId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const updateRequestContact = /* GraphQL */ `
  mutation UpdateRequestContact(
    $condition: ModelRequestContactConditionInput
    $input: UpdateRequestContactInput!
  ) {
    updateRequestContact(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      contactId
      requestId
      roles
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      primary
      createdAt
      id
      retired
      updatedAt
    }
  }
`;
export const createSettingsCompanyInformation = /* GraphQL */ `
  mutation CreateSettingsCompanyInformation(
    $condition: ModelSettingsCompanyInformationConditionInput
    $input: CreateSettingsCompanyInformationInput!
  ) {
    createSettingsCompanyInformation(condition: $condition, input: $input) {
      _deleted
      _version
      id
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      updatedAt
      creator
      updateUser
    }
  }
`;
export const deleteSettingsCompanyInformation = /* GraphQL */ `
  mutation DeleteSettingsCompanyInformation(
    $condition: ModelSettingsCompanyInformationConditionInput
    $input: DeleteSettingsCompanyInformationInput!
  ) {
    deleteSettingsCompanyInformation(condition: $condition, input: $input) {
      _deleted
      _version
      id
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      updatedAt
      creator
      updateUser
    }
  }
`;
export const updateSettingsCompanyInformation = /* GraphQL */ `
  mutation UpdateSettingsCompanyInformation(
    $condition: ModelSettingsCompanyInformationConditionInput
    $input: UpdateSettingsCompanyInformationInput!
  ) {
    updateSettingsCompanyInformation(condition: $condition, input: $input) {
      _deleted
      _version
      id
      contact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      updatedAt
      creator
      updateUser
    }
  }
`;
export const createSettingsMandateType = /* GraphQL */ `
  mutation CreateSettingsMandateType(
    $condition: ModelSettingsMandateTypeConditionInput
    $input: CreateSettingsMandateTypeInput!
  ) {
    createSettingsMandateType(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      cost
      translations {
        locale
        text
      }
      tasks
      order
    }
  }
`;
export const deleteSettingsMandateType = /* GraphQL */ `
  mutation DeleteSettingsMandateType(
    $condition: ModelSettingsMandateTypeConditionInput
    $input: DeleteSettingsMandateTypeInput!
  ) {
    deleteSettingsMandateType(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      cost
      translations {
        locale
        text
      }
      tasks
      order
    }
  }
`;
export const updateSettingsMandateType = /* GraphQL */ `
  mutation UpdateSettingsMandateType(
    $condition: ModelSettingsMandateTypeConditionInput
    $input: UpdateSettingsMandateTypeInput!
  ) {
    updateSettingsMandateType(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      cost
      translations {
        locale
        text
      }
      tasks
      order
    }
  }
`;
export const createSettingsTaskType = /* GraphQL */ `
  mutation CreateSettingsTaskType(
    $condition: ModelSettingsTaskTypeConditionInput
    $input: CreateSettingsTaskTypeInput!
  ) {
    createSettingsTaskType(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      translations {
        locale
        text
      }
      order
    }
  }
`;
export const deleteSettingsTaskType = /* GraphQL */ `
  mutation DeleteSettingsTaskType(
    $condition: ModelSettingsTaskTypeConditionInput
    $input: DeleteSettingsTaskTypeInput!
  ) {
    deleteSettingsTaskType(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      translations {
        locale
        text
      }
      order
    }
  }
`;
export const updateSettingsTaskType = /* GraphQL */ `
  mutation UpdateSettingsTaskType(
    $condition: ModelSettingsTaskTypeConditionInput
    $input: UpdateSettingsTaskTypeInput!
  ) {
    updateSettingsTaskType(condition: $condition, input: $input) {
      _deleted
      _version
      creator
      updateUser
      createdAt
      id
      retired
      updatedAt
      name
      translations {
        locale
        text
      }
      order
    }
  }
`;
