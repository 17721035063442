import React from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap"
import TextareaAutosize from "@mui/base/TextareaAutosize"

import { isEmpty } from "lodash"
import { Field, Formik } from "formik"
import * as Yup from "yup"

//i18n
import { withTranslation } from "react-i18next"

// Component
function InvoiceDetailInvoiceSendCard(props) {
  const { invoice, onCancel, onSave } = props

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card color="success" className="border mt-3" outline>
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={{
            recipient: isEmpty(invoice?.billTo?.email) ? "" : invoice.billTo.email,
            messageSubject: "",
            messageBody: "",
            sendMe: false,
            includePDF: true,
          }}
          validationSchema={Yup.object({
            recipient: Yup.string().required(props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Recipient.Validation.Required")),
            messageSubject: Yup.string().required(props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Subject.Validation.Required")),
            messageBody: Yup.string().required(props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Body.Validation.Required")),
          })}
          onSubmit={values => {
            //alert(JSON.stringify(values, null, 2))
            let message = {
              recipient: values["recipient"],
              sendMe: values["sendMe"],
              messageSubject: values["messageSubject"],
              messageBody: values["messageBody"],
              includePDF: values["includePDF"],
            }
            if (onSave) {
              onSave(invoice, message)
            }
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup row>
                <Label sm="2" for="inputMessageRecipient">
                  {props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Recipient")}
                </Label>
                <Col sm="10">
                  <Input id="inputMessageRecipient" name="recipient" type="text" value={formik.values.recipient} readOnly invalid={isEmpty(formik.values.recipient) ? true : false} />
                  <FormFeedback>{props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Recipient.Validation.Required")}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup>
                <Label sm="2" for="inputSendMe" />
                <Input className="me-2" id="inputSendMe" name={"sendMe"} tag={Field} type="checkbox" />
                <Label>{props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Recipient.SendMe")}</Label>
              </FormGroup>
              <FormGroup row>
                <Label sm="2" for="inputMessageSubject">
                  {props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Subject")}
                </Label>
                <Col sm="10">
                  <Input id="inputMessageSubject" name="messageSubject" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.messageSubject} invalid={formik.touched.messageSubject && formik.errors.messageSubject ? true : false} />
                  <FormFeedback>{formik.errors.messageSubject}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="2" for="inputMessageBody">
                  {props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.Body")}
                </Label>
                <Col sm="10">
                  <Input id="inputMessageBody" name="messageBody" tag={TextareaAutosize} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.messageBody} invalid={formik.touched.messageBody && formik.errors.messageBody ? true : false} />
                  {formik.touched.messageBody && formik.errors.messageBody ? <FormFeedback>{formik.errors.messageBody}</FormFeedback> : null}
                </Col>
              </FormGroup>
              <FormGroup>
                <Label sm="2" for="inputMessageBody" />
                <Input className="me-2" id="inputIncludePDF" name={"includePDF"} tag={Field} type="checkbox" />
                <Label>{props.t("Page.InvoiceDetail.InvoiceView.Form.SendInvoice.IncludePdf")}</Label>
              </FormGroup>
              <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">
                {isEmpty(invoice.sentDate) && props.t("Common.Action.Send")}
                {!isEmpty(invoice.sentDate) && props.t("Common.Action.Resend")}
              </Button>
              <Button className="me-2" color="secondary" outline onClick={onCancel}>
                {props.t("Common.Action.Cancel")}
              </Button>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

InvoiceDetailInvoiceSendCard.propTypes = {
  t: PropTypes.any,
  invoice: PropTypes.any,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default withRouter(withTranslation()(InvoiceDetailInvoiceSendCard))
