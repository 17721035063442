import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "reactstrap";
import Dropzone from "react-dropzone";

//i18n
import { withTranslation } from "react-i18next";

/**
 * Formats the size
 */
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

// Component
function Uploader(props) {

    const { onAddClick, maxFiles } = props
    const [saving, setSaving] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])

    const handleAcceptedFiles = useCallback(
        (files) => {
            files.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size),
                })
            )
            setselectedFiles(files)
        },
        [selectedFiles],
    )

    const onAddImagesClick = useCallback(
        () => {
            onAddClick(selectedFiles)
        },
        [onAddClick, selectedFiles],
    )

    return (
        <Form>
            <Dropzone maxFiles={maxFiles}
                onDrop={handleAcceptedFiles}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                        <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>{props.t("Component.Uploader.Dropzone", { count: maxFiles })}</h4>
                        </div>
                    </div>
                )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles.map((f, i) => {
                    return (
                        <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                        >
                            <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                        <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                        >
                                            {f.name}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    )
                })}
            </div>
            <Row>
                <div className="text-end mt-2">
                    <Button color="primary" disabled={saving || selectedFiles.length === 0} onClick={onAddImagesClick}>
                        {props.t("Common.Action.Save")}
                    </Button>
                </div>
            </Row>
        </Form>
    )
}

Uploader.propTypes = {
    t: PropTypes.any,
    onAddClick: PropTypes.func,
    maxFiles: PropTypes.number,
}

export default withTranslation()(Uploader)
