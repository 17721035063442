import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import Moment from "react-moment"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardFooter, CardLink, CardText, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Label, Row } from "reactstrap"
import { isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"

import { invoiceStatusClasses } from "helpers/lea-status_helper"
import { URL_INVOICES, URL_REQUESTS } from "helpers/lea-url_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function InvoiceDetailSummaryView(props) {
  const { invoice, users, onDelete } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuSummary, setOpenMenuSummary] = useState(false)
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("DetailView.handleDeleteConfirmationFormOnSave:confirmationData", confirmationData);
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(invoice)
  }

  function handleviewInvoices(e) {
    //console.log("DetailView.handleviewInvoices");
    e.preventDefault()
    props.history.push(URL_INVOICES)
  }

  function handleViewRequest(e, requestId) {
    //console.log("DetailView.handleViewRequest:requestId", requestId);
    e.preventDefault()
    props.history.push(URL_REQUESTS + "/" + requestId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuSummary = useCallback(() => {
    setOpenMenuSummary(!openMenuSummary)
  }, [openMenuSummary])

  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function creatorDisplayName() {
    let usr = users.find(item => {
      return item.cognitoId === invoice.creator
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Form>
      <Card className="no-print">
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              {/* 
              <span className={"badge rounded-pill badge-soft-" + invoiceStatusClasses[invoice.status]}>
                {invoice.retired && (
                  <s>{props.t("Common.Enum.InvoiceStatus." + invoice.status)}</s>
                )}
                {!invoice.retired && (
                  <>{props.t("Common.Enum.InvoiceStatus." + invoice.status)}</>
                )}
              </span> 
              {invoice.retired && <span className={"badge rounded-pill badge-soft-" + invoiceStatusClasses["RETIRED"]}>{props.t("Common.Enum.InvoiceStatus." + (invoice.retired ? "RETIRED" : invoice.status))}</span>}
              */}
              <CardTitle>{props.t("Page.InvoiceDetail.SummaryView")}</CardTitle>
            </div>
            {!invoice.retired && (
              <Dropdown direction="left" className="ms-1" isOpen={openMenuSummary} toggle={toggleMenuSummary}>
                <DropdownToggle tag="a" className="text-muted">
                  <i className="mdi mdi-dots-vertical font-size-18" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleDeleteConfirmationForm}>{props.t("Page.InvoiceDetail.SummaryView.Action.Delete")}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          {openDeleteConfirmationForm && <DeleteConfirmationForm id={invoice.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.InvoiceDetail.SummaryView.Form.DeleteConfirmation.Title")} body={props.t("Page.InvoiceDetail.SummaryView.Form.DeleteConfirmation.Body", { 0: invoice.id })} />}
          <Row className="mt-2">
            <Col xl="12" xs="12">
              <Label>
                <i className="bx bx-user me-1 text-primary" />
                {props.t("Common.Label.Creator")}
              </Label>
              <div>{creatorDisplayName()}</div>
            </Col>
            {/* TODO Pour l'instant on ne permet d'assigner un utilisateur a un responsable */}
          </Row>
          <Row className="mt-3">
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-calendar me-1 text-primary" />
                {props.t("Common.Label.Date.IssueDate")}
              </Label>
              <div>{isEmpty(invoice?.issueDate) ? "" : <Moment format="YYYY-MM-DD">{invoice.issueDate}</Moment>}</div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-calendar-check me-1 text-primary" />
                {props.t("Common.Label.Date.SendDate")}
              </Label>
              <div>{isEmpty(invoice?.sentDate) ? "" : <Moment format="YYYY-MM-DD">{invoice.sentDate}</Moment>}</div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-calendar-x me-1 text-primary" />
                {props.t("Common.Label.Date.PaidDate")}
              </Label>
              <div>{isEmpty(invoice?.paidDate) ? "" : <Moment format="YYYY-MM-DD">{invoice.paidDate}</Moment>}</div>
            </Col>
          </Row>
        </CardBody>
        {/* <CardFooter>
          <CardLink onClick={e => handleviewInvoices(e)}>{props.t("Common.Action.BackToInvoices")}</CardLink>
          <CardLink onClick={e => handleViewRequest(e, invoice.requestId)}>{props.t("Common.Action.ViewRequest")}</CardLink>
        </CardFooter> */}
        <CardFooter className="no-print">
          <div className="d-flex">
            <div className="flex-grow-1">
              <CardLink onClick={e => handleviewInvoices(e)}>{props.t("Common.Action.BackToInvoices")}</CardLink>
              <CardLink onClick={e => handleViewRequest(e, invoice.requestId)}>{props.t("Common.Action.ViewRequest")}</CardLink>
            </div>
            <CardText className="text-end">
              <small className="text-muted">
                {props.t("Common.Label.Date.CreatedAt")} : &nbsp;
                {isEmpty(invoice.createdAt) ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{invoice.createdAt}</Moment>}
              </small>
            </CardText>
          </div>
        </CardFooter>
      </Card>
    </Form>
  )
}

InvoiceDetailSummaryView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  invoice: PropTypes.any,
  users: PropTypes.any,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(InvoiceDetailSummaryView))
