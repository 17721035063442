import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { useAlert } from "react-alert"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Button, Card, CardBody, CardTitle, Col, Row, Spinner, Table } from "reactstrap"
import { isEmpty } from "lodash"

import MandateTypeCard from "./SettingsMandateTypeCard"
import MandateTypeTableRow from "./SettingsMandateTypeTableRow"

import { useCurrentUser } from "contexts/current-user-context"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { createSettingsMandateType, updateSettingsMandateType } from "graphql/mutations"
import { listSettingsMandateTypes, listSettingsTaskTypes } from "graphql/queries"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsMandateTypesView(props) {
  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Init
  const emptySettingMandateType = { name: null, cost: null, order: 0, tasks: null, translations: null }

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMandateTypeForm, setOpenMandateTypeForm] = useState(false)
  const [optionsMandateType, setOptionsMandateTypes] = useState([])
  const [optionsTaskType, setOptionsTaskType] = useState([])

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get list of mandate
  const { isLoading, isIdle, isFetching, isError, error } = useQuery("listSettingsMandateTypesInMandateTypesView", () => firstValueFrom(callAPI("listSettingsMandateTypes", listSettingsMandateTypes, { filter: { retired: { ne: true } } })), {
    retry: false,
    onSuccess: result => {
      //console.log("listSettingsMandateTypesInMandateTypesView.onSuccess:result", result)
      setOptionsMandateTypes(isEmpty(result?.items) ? [] : result.items)
    },
    onError: error => {
      console.error("MandateTypesView.listSettingsMandateTypesInMandateTypesView.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // Get list of task after the previous query
  const {
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks,
    isError: isErrorTasks,
    error: errorTasks,
  } = useQuery("listSettingsTaskTypesInMandateTypesView", () => firstValueFrom(callAPI("listSettingsTaskTypes", listSettingsTaskTypes)), {
    retry: false,
    enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("listSettingsTaskTypesInMandateTypesView.onSuccess:result", result)
      // Mapping pour pouvoir utiliser les attributs: disabled, value et label (OOTB pour le component react-dropdown-select)
      let optionsTask = []
      if (!isEmpty(result?.items)) {
        result?.items.map(row => optionsTask.push({ id: row.id, createdAt: row.createdAt, value: row.id, label: row.name, disabled: row.retired, order: row.order }))
      }
      setOptionsTaskType(optionsTask)
    },
    onError: error => {
      console.error("MandateTypesView.listSettingsTaskTypesInMandateTypesView.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMandateTypeCardOnSave(mandateData) {
    //console.log("MandateTypesView.handleMandateTypeCardOnSave:mandateData", mandateData);
    toggleMandateTypeForm() // Hide form
    doCreateMandate(mandateData)
  }

  function handleMandateTypeTableRowOnDelete(mandateData) {
    //console.log("MandateTypesView.handleMandateTypeTableRowOnDelete:mandateData", mandateData)
    doDeleteMandate(mandateData)
  }

  function handleMandateTypeTableRowOnSave(mandateData) {
    //console.log("MandateTypesView.handleMandateTypeTableRowOnSave:mandateData", mandateData)
    doUpdateMandate(mandateData)
  }

  function doCreateMandate(mandateData) {
    //console.log("MandateTypesView.doCreateMandate:mandateData", mandateData);
    const input = {
      ...mandateData,
      creator: username,
    }
    //console.log("MandateTypesView.doCreateMandate:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateTypesView.doCreateMandate:updateInput", updateInput)
    createMandateMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("MandateTypesView.doCreateMandate.createMandateMutation.onSuccess:result", result)
        setOptionsMandateTypes([...optionsMandateType, result])
      },
      onError: error => {
        console.error("MandateTypesView.doCreateMandate.createMandateMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsMandateTypesInMandateTypesView")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const createMandateMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createSettingsMandateType", createSettingsMandateType, {
        input: {
          ...data,
        },
      })
    )
  )

  function doUpdateMandate(mandateData) {
    //console.log("MandateTypesView.doUpdateMandate:mandateData", mandateData)
    const input = {
      ...mandateData,
      updateUser: username,
    }
    //console.log("MandateTypesView.doDeleteMandate:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateTypesView.doUpdateMandate:updateInput", updateInput)
    updateMandateMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("MandateTypesView.doUpdateMandate.updateMandateMutation.onSuccess:result", result)
        let newArr = optionsMandateType.map(element => (element.id !== result.id ? element : result))
        setOptionsMandateTypes(newArr)
      },
      onError: error => {
        console.error("MandateTypesView.doUpdateMandate.updateMandateMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsMandateTypesInMandateTypesView")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  function doDeleteMandate(mandateData) {
    //console.log("MandateTypesView.doDeleteMandate:mandateData", mandateData)
    const input = {
      ...mandateData,
      updateUser: username,
      retired: true,
    }
    //console.log("MandateTypesView.doDeleteMandate:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateTypesView.doDeleteMandate:updateInput", updateInput)
    updateMandateMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("MandateTypesView.doDeleteMandate.updateMandateMutation.onSuccess:result", result)
        let newOptions = optionsMandateType.filter(opt => {
          return opt.id != mandateData.id
        })
        setOptionsMandateTypes(newOptions)
      },
      onError: error => {
        console.error("MandateTypesView.doDeleteMandate.updateMandateMutation.onError:error", error)
        queryClient.invalidateQueries("listSettingsMandateTypesInMandateTypesView")
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const updateMandateMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateSettingsMandateType", updateSettingsMandateType, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMandateTypeForm = useCallback(() => {
    setOpenMandateTypeForm(!openMandateTypeForm)
  }, [openMandateTypeForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const optionsMandateTypeSortedAsc = optionsMandateType.sort((objA, objB) => objA.order - objB.order)

  const optionsTaskTypeSortedAsc = optionsTaskType.sort((objA, objB) => objA.order - objB.order)

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {(isLoading || isFetching || isLoadingTasks || isFetchingTasks) && <Spinner className="ms-2" color="primary" />}
      {(!isLoading && !isFetching && !isError && !isLoadingTasks && !isFetchingTasks && !isErrorTasks) &&
        <CardBody className="bg-light">
          <CardTitle className="text-dark">
            {props.t("Page.Settings.MandateTypesView")}&nbsp;
            <sup>
              <span className={"badge rounded-pill badge-soft-info font-size-12"}>{optionsMandateType?.length}</span>
            </sup>
          </CardTitle>
          <div className="mb-0 mt-0">
            <p>{props.t("Page.Settings.MandateTypesView.Subtitle")}</p>
            <hr className="mb-2" />
            <Row>
              <Col xl={12}>
                <Row>
                  <div className="text-sm-start mb-4 mt-2">
                    <Button color="primary" className="btn-rounded" onClick={toggleMandateTypeForm}>
                      <i className="mdi mdi-plus me-1" />
                      {props.t("Page.Settings.MandateTypesView.Action.AddNew")}
                    </Button>
                  </div>
                  {openMandateTypeForm && <MandateTypeCard mandateTypeInfo={emptySettingMandateType} options={optionsTaskTypeSortedAsc} selectedValues={[]} onSave={handleMandateTypeCardOnSave} onCancel={toggleMandateTypeForm} />}
                </Row>
                <Row>
                  {!isEmpty(optionsMandateType) && (
                    <Card>
                      <CardBody>
                        <Table responsive striped size="sm">
                          <thead>
                            <tr>
                              <th scope="col">{props.t("Page.Settings.MandateTypesView.Table.Field.Name")}</th>
                              <th scope="col">{props.t("Page.Settings.MandateTypesView.Table.Field.Task")}</th>
                              <th scope="col">{props.t("Page.Settings.MandateTypesView.Table.Field.Order")}</th>
                              <th scope="col">{props.t("Page.Settings.MandateTypesView.Table.Field.UnitPrice")}</th>
                              <th scope="col">{props.t("Common.Label.Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {optionsMandateTypeSortedAsc.map(row => (
                              <MandateTypeTableRow key={row.id} row={row} options={optionsTaskTypeSortedAsc} onSave={handleMandateTypeTableRowOnSave} onDelete={handleMandateTypeTableRowOnDelete} />
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </CardBody>
      }
    </React.Fragment>
  )
}

SettingsMandateTypesView.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SettingsMandateTypesView))
