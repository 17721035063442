import React from "react"
import PropTypes from "prop-types"

import { Route, Switch, withRouter } from "react-router-dom"
import { URL_SETTINGS } from "helpers/lea-url_helper"

import SettingsPage from "./SettingsPage"
import Page404 from "components/Page404/Page404"

// Component
function SettingsRouter(props) {
  return (
    <Switch>
      <Route exact path={URL_SETTINGS} component={SettingsPage} />
      <Route exact from={`${props.match.path}/:Id`} component={Page404} />
    </Switch>
  )
}

SettingsRouter.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(SettingsRouter)
