import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table } from "reactstrap"

import RequestDetailInvoiceTableRow from "./RequestDetailInvoiceTableRow"

import { URL_INVOICES } from "helpers/lea-url_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function RequestDetailInvoiceView(props) {
  const { request, invoices, onAddNew } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuInvoice, setOpenMenuInvoice] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleViewInvoice(e, invoideId) {
    //console.log("InvoiceView.handleViewInvoice:invoideId", invoideId);
    e.preventDefault()
    props.history.push(URL_INVOICES + "/" + invoideId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuInvoice = useCallback(() => {
    setOpenMenuInvoice(!openMenuInvoice)
  }, [openMenuInvoice])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const invoicesSortedAsc = useMemo(() => invoices.sort((objA, objB) => new Date(objA.createdAt).getTime() - new Date(objB.createdAt).getTime()), [invoices])

  const countAllInvoices = invoices => {
    return invoices.reduce((cumul, inv) => {
      return cumul + 1
    }, 0)
  }

  const countAllActiveInvoices = invoices => {
    return invoices.reduce((cumul, inv) => {
      if (inv.retired === false) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.RequestDetail.InvoiceView")}</CardTitle>
          </div>
          {!request.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuInvoice} toggle={toggleMenuInvoice}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={e => onAddNew(e)}>{props.t("Page.RequestDetail.InvoiceView.Action.CreateInvoice")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {(!request.retired && countAllActiveInvoices(invoices) === 0) || (request.retired && countAllInvoices(invoices) === 0) ? (
          <Row className="mt-2">
            <Col xl="12">
              <Alert color="danger">
                <i className="mdi mdi-alert-outline me-1" />
                {props.t("Common.Text.Alert.Invoices")}
              </Alert>
            </Col>
          </Row>
        ) : (
          <Row className="mt-2">
            <Col xl="12">
              <div className="table-responsive">
                <Table size="sm" className="table-nowrap">
                  <thead>
                    <tr>
                      <th className="no-print">{props.t("Common.Label.Action")}</th>
                      <th>{props.t("Common.Label.Date.IssueDate")}</th>
                      <th>{props.t("Common.Label.Date.DueDate")}</th>
                      <th>{props.t("Common.Label.Date.PaidDate")}</th>
                      <th>{props.t("Common.Label.Amount")}</th>
                      <th className="text-end">{props.t("Common.Label.Status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {request.retired
                      ? // Quand le statut de la demande est archivé (request.retired === true), on affiche toutes les factures (archivés ou non)
                        invoicesSortedAsc.map(invoice => <RequestDetailInvoiceTableRow key={invoice.id} invoice={invoice} onView={handleViewInvoice} />)
                      : // Quand le statut de la demande n'est pas archivé (request.retired === false), on affiche seulement les factures qui ne
                        // sont pas archivés (retired === false)
                        invoicesSortedAsc.map(invoice => (invoice.retired ? null : <RequestDetailInvoiceTableRow key={invoice.id} invoice={invoice} onView={handleViewInvoice} />))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

RequestDetailInvoiceView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  request: PropTypes.any,
  invoices: PropTypes.any,
  onAddNew: PropTypes.func,
}

export default withRouter(withTranslation()(RequestDetailInvoiceView))
