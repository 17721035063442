import React, { useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { useAlert } from "react-alert"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

import ContactSearchView from "components/ContactSearch/ContactSearchView"

import { useCurrentUser } from "contexts/current-user-context"
import { URL_CONTACTS } from "helpers/lea-url_helper"

// i18n
import { withTranslation } from "react-i18next"

// Component
function ContactsMasterPage(props) {
  // Alert
  const customAlert = useAlert()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleContactSearchViewOnSelect(entityContact) {
    //console.log("ContactsMasterPage.handleContactSearchViewOnSelect:entityContact", entityContact);
    props.history.push(URL_CONTACTS + "/" + entityContact.id)
  }

  function handleContactSearchViewOnAddNew(entityContact) {
    //console.log("ContactsMasterPage.handleContactSearchViewOnAddNew:entityContact", entityContact);
    props.history.push(URL_CONTACTS + "/" + entityContact.id)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Contacts</title>
      </MetaTags>
      <Container fluid>
        {/* <h4>{props.t("Page.ContactsMaster")}</h4> */}
        <Row>
          <Col xl="12">
            <ContactSearchView onSelect={handleContactSearchViewOnSelect} onAddNew={handleContactSearchViewOnAddNew} displayCancelButton={false} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

ContactsMasterPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(ContactsMasterPage))
