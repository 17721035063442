import React from "react";
import PropTypes from "prop-types";

import { Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { Formik } from "formik";
import * as Yup from "yup";

// i18n
import { withTranslation } from "react-i18next";

// Component
function DeleteConfirmationForm(props) {

    const { id, toggle, onSave, title, body } = props

    const validate = values => {
        let errors = {}
        if (!values.confirmation) {
            errors.confirmation = props.t("Component.DeleteConfirmationForm.Confirmation.Validation.Required")
        } else if (!("delete" === values.confirmation)) {
            errors.confirmation = props.t("Component.DeleteConfirmationForm.Confirmation.Validation.Error")
        }
        return errors;
    }

    return (
        <Modal centered isOpen={true} backdrop="static">
            <Formik
                enableReinitialize
                initialValues={{
                    confirmation: ""
                }}
                validationSchema={Yup.object({
                })}
                validate={validate}
                onSubmit={(values) => {
                    //console.log("DeleteConfirmationForm:values", values)
                    let newConfirmation = {
                        id: id,
                        confirmation: values["confirmation"]
                    };
                    if (onSave) {
                        onSave(newConfirmation)
                    }
                }}>
                {
                    formik => (
                        <React.Fragment>
                            <Form onSubmit={formik.handleSubmit}>
                                <ModalHeader toggle={toggle} tag="h4">
                                    {title}
                                </ModalHeader>
                                <ModalBody>
                                    <span>
                                        {body}
                                    </span>
                                    <FormGroup row className="mt-3">
                                        <Label for="inputConfirmation">{props.t("Component.DeleteConfirmationForm.Label")}</Label>
                                        <Input
                                            id="inputConfirmation"
                                            name="confirmation"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.confirmation}
                                            invalid={
                                                formik.touched.confirmation && formik.errors.confirmation ? true : false
                                            }
                                            innerRef={function (input) {
                                                if (input != null) {
                                                    input.focus();
                                                }
                                            }}
                                        />
                                        {formik.touched.confirmation && formik.errors.confirmation ? (
                                            <FormFeedback className="font-size-10 mt-1" type="invalid">{formik.errors.confirmation}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" outline onClick={() => { toggle(); }}>
                                        {props.t("Common.Action.Cancel")}
                                    </Button>
                                    <Button color="danger" type="submit" disabled={!(formik.isValid && formik.dirty)}>
                                        {props.t("Common.Action.Delete")}
                                    </Button>
                                </ModalFooter>
                            </Form>
                        </React.Fragment>
                    )
                }
            </Formik>
        </Modal >
    )
}

DeleteConfirmationForm.propTypes = {
    t: PropTypes.any,
    id: PropTypes.any,
    onSave: PropTypes.func,
    toggle: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.string,
}

export default withTranslation()(DeleteConfirmationForm)
