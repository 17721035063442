export const getTaskDetail = /* GraphQL */ `
  query getTaskDetail($id: ID!, $filterUser: ModelUserFilterInput, $filterSettingsTask: ModelSettingsTaskTypeFilterInput, $limit: Int, $nextToken: String) {
    getTask(id: $id) {
      _deleted
      _version
      assignee
      createdAt
      creator
      id
      mandateId
      requestId
      retired
      status
      taskType
      updatedAt
      description
      order
    },
    listUsers(filter: $filterUser, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        email
        id
        userStatus
        updatedAt
        tenantId
        cognitoId
        username
      }
      nextToken
      startedAt
    },
    listSettingsTaskTypes(filter: $filterSettingsTask, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        id
        retired
        updatedAt
        name
        translations {
          locale
          text
        }
        order
      }
      nextToken
      startedAt
    }
  }
`;
