import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import filterFactory, { dateFilter, selectFilter } from "react-bootstrap-table2-filter"

import MetaTags from "react-meta-tags"
import Moment from "react-moment"
//import * as moment from "moment";
//import Moment  from 'moment-business-days'
import { useAlert } from "react-alert"
import { Link, withRouter } from "react-router-dom"
import { useQuery } from "react-query"
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import { isEmpty, isNull, isUndefined } from "lodash"

import { useCurrentUser } from "contexts/current-user-context"
import { getOptionById } from "helpers/lea-options_helper"
import { taskStatusClasses } from "helpers/lea-status_helper"
import { URL_TASKS } from "helpers/lea-url_helper"

import { API, graphqlOperation } from "aws-amplify"
import { callAPI } from "helpers/lea-graphql_helper"
import { listTasks, listSettingsTaskTypes, listUsers } from "graphql/queries"
import { TaskStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Will be use to keep filter names
let taskTypeFilter
let statusFilter
let assigneeFilter
let creationDateFilter

// Component
function TasksMasterPage(props) {
  // Alert
  const customAlert = useAlert()

  // We use React Refs to directly access the components -> BootstrapTable
  const node = useRef()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [tasks, setTasks] = useState([])

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState(1000)
  const [nextTokenAWS, setNextTokenAWS] = useState()
  const [nextNextTokenAWS, setNextNextTokenAWS] = useState()
  const [previousTokensAWS, setPreviousTokensAWS] = useState([])
  const [hasMoreResults, setHasMoreResults] = useState(false)

  const [filterPosition, setFilterPosition] = useState("top")
  const [taskTypeFilterOptions, setTaskTypeFilterOptions] = useState([])
  const [userFilterOptions, setUserFilterOptions] = useState([])

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Function to load tasks when the form is finishing to refresh (see useEffect())
  const fetch = async () => {
    setIsLoading(true)
    try {
      const variables = {
        filter: { retired: { ne: "true" } }, // exclure les retired = true
        nextToken: nextTokenAWS,
        limit: limit,
      }
      Object.keys(variables).forEach(key => {
        if (isNull(variables[key]) || isUndefined(variables[key])) {
          delete variables[key]
        }
      })
      //console.log("fetch.variables=", variables)
      const result = await API.graphql(graphqlOperation(listTasks, variables))
      //console.log("useEffect:result", result)

      setNextNextTokenAWS(result.data.listTasks.nextToken)
      if (isUndefined(result.data.listTasks.nextToken) || isNull(result.data.listTasks.nextToken)) {
        setHasMoreResults(false)
        //console.log("useEffect:hasNext = ", false)
      } else {
        setHasMoreResults(true)
        //console.log("useEffect:hasNext = ", true)
      }

      let listOfTasks = tasks.concat(result.data.listTasks.items)
      setTasks(listOfTasks)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetch()
  }, [nextTokenAWS, limit]) // execute l'effet que si le token a changé

  // Get list of tasks settings
  const {
    isLoading: isLoadingSettingsTaskType,
    isFetching: isFetchingSettingsTaskType,
    isError: isErrorSettingsTaskType,
    error: errorSettingsTaskType,
  } = useQuery("listSettingsTaskTypes", () => firstValueFrom(callAPI("listSettingsTaskTypes", listSettingsTaskTypes)), {
    retry: false,
    //enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("listSettingsTaskTypes.onSuccess:result", result)
      // Mapping pour pouvoir utiliser le filter de recherche
      let arrayOfTaskTypeOption = []
      if (!isEmpty(result?.items)) {
        result?.items.map(row =>
          arrayOfTaskTypeOption.push({
            id: row.id,
            value: row.id,
            label: row.retired ? row.name + " (archive) " : row.name,
            order: row.order,
          })
        )
      }
      const taskTypeFilterOptionsSortedAsc = arrayOfTaskTypeOption.sort((objA, objB) => objA.order - objB.order)
      /* let data = arrayOfTaskTypeOption.sort((a, b) => {
                    if (a.order < b.order) {
                        return -1;
                    }
                }); */
      setTaskTypeFilterOptions(taskTypeFilterOptionsSortedAsc)
    },
    onError: error => {
      console.error("TasksMasterPage.listSettingsTaskTypes.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // Get list of users
  const {
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
    isError: isErrorUsers,
    error: errorUsers,
  } = useQuery("listUsers", () => firstValueFrom(callAPI("listUsers", listUsers)), {
    retry: false,
    //enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("TaskMaster.listUsers.onSuccess:result", result)
      let optionsUsers = []
      if (!isEmpty(result?.items)) {
        result?.items.map(row =>
          optionsUsers.push({
            cognitoId: row.cognitoId,
            value: row.cognitoId,
            label: isEmpty(row.username) ? row.email : row.username,
            disabled: row.retired,
            username: row.username,
          })
        )
      }
      setUserFilterOptions(optionsUsers)
    },
    onError: error => {
      console.error("TasksMasterPage.listUsers.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleLoadMoreResult() {
    //console.log("TasksMasterPage.handleLoadMoreResult");
    setPreviousTokensAWS(prev => [...prev, nextTokenAWS])
    setNextTokenAWS(nextNextTokenAWS)
    setNextNextTokenAWS(null)
  }

  function handleClearAllFilters() {
    //console.log("TasksMasterPage.handleClearAllFilterClick");
    taskTypeFilter("")
    statusFilter("")
    assigneeFilter("")
    creationDateFilter("")
  }

  function handleViewTask(e, taskId) {
    //console.log("TasksMasterPage.handleViewTask:taskId", taskId);
    e.preventDefault()
    props.history.push(URL_TASKS + "/" + taskId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function assigneeDisplayName(row) {
    let usr = userFilterOptions.find(item => {
      return item.cognitoId === row.assignee
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.label
    }
    return usr.username
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Def columns
  // ----------------------------------------------------------------------------------------------------------------
  const columns = [
    /*
    {
      dataField: "rowCount",
      isDummyField: true,
      text: "#",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleEditTask(e, row.id)
        },
      },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex) => <div>{rowIndex + 1}</div>,
      headerStyle: (colum, colIndex) => {
        return { width: "5%", textAlign: "center" }
      },
      style: {
        cursor: "default",
        textAlign: "center",
      },
    },
    */
    {
      dataField: "status",
      isDummyField: false,
      text: props.t("Common.Label.Status"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewTask(e, row.id)
        },
      },
      filter: selectFilter({
        className: 'no-print',
        options: [
          {
            value: TaskStatus.PENDING,
            label: props.t("Common.Enum.TaskStatus.PENDING"),
          },
          {
            value: TaskStatus.STARTED,
            label: props.t("Common.Enum.TaskStatus.STARTED"),
          },
          {
            value: TaskStatus.COMPLETED,
            label: props.t("Common.Enum.TaskStatus.COMPLETED"),
          },
        ],
        getFilter: filter => {
          statusFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => <span className={"badge rounded-pill badge-soft-" + taskStatusClasses[row.status]}>{props.t("Common.Enum.TaskStatus." + row.status)}</span>,
      /*headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "createdAt",
      isDummyField: false,
      text: props.t("Common.Label.Date.CreatedAt"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewTask(e, row.id)
        },
      },
      filter: dateFilter({
        className: 'no-print',
        getFilter: filter => {
          creationDateFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (isEmpty(row?.createdAt) ? "" : <Moment format="YYYY-MM-DD">{row.createdAt}</Moment>),
      /*headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "taskType",
      isDummyField: false,
      text: props.t("Common.Label.TaskType"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewTask(e, row.id)
        },
      },
      filter: selectFilter({
        className: 'no-print',
        options: taskTypeFilterOptions,
        getFilter: filter => {
          taskTypeFilter = filter
        },
        //placeholder: 'Select Status...',
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => getOptionById(taskTypeFilterOptions, cell).label,
      /*headerStyle: (colum, colIndex) => {
        return { width: "35%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "assignee",
      isDummyField: false,
      text: props.t("Common.Label.Assignee"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewTask(e, row.id)
        },
      },
      filter: selectFilter({
        className: 'no-print',
        options: userFilterOptions,
        getFilter: filter => {
          assigneeFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => <div>{isEmpty(row.assignee) ? row.assignee : assigneeDisplayName(row)}</div>,
      /*headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    /*
    {
      dataField: "action",
      isDummyField: true,
      text: props.t("Common.Label.Action"),
      headerAlign: 'center',
      align: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="text-muted">
          <i className="mdi mdi-dots-horizontal font-size-18" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={(e) => handleEditTask(e, row.id)}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            {props.t("Common.Action.Edit")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      ),
    },
    */
  ]

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Tasks</title>
      </MetaTags>
      <Container fluid>
        {/* <h4>{props.t("Page.TasksMaster")}</h4> */}
        {(isLoading || isLoadingSettingsTaskType || isFetchingSettingsTaskType || isLoadingUsers || isFetchingUsers) && <Spinner className="ms-2" color="primary" />}
        {(!isLoading && !isLoadingSettingsTaskType && !isFetchingSettingsTaskType && !isLoadingUsers && !isFetchingUsers) &&
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <ToolkitProvider bootstrap4 keyField="id" data={tasks} columns={columns}>
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 no-print">
                          <Col sm="12" className="d-none d-sm-block">
                            <Button color="secondary" outline onClick={handleClearAllFilters}>
                              <i className="mdi mdi-filter-remove-outline me-1" />
                              {props.t("Common.Action.ClearFilters")}
                            </Button>
                            {/* <Button color="primary" outline onClick={ (e) => console.log(node)}>
                                GetCurrentData
                              </Button> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              bordered={false}
                              condensed
                              //BootstrapTable
                              //classes={"table align-middle table-nowrap"}
                              //classes={"table align-middle responsiveTable"}
                              filter={filterFactory()}
                              filterPosition={filterPosition}
                              hover
                              headerWrapperClasses={"table-light"}
                              keyField="id"
                              ref={node}
                              {...toolkitProps.baseProps}
                              wrapperClasses="table-responsive"
                            />
                            {/* <ExportCSVButton {...toolkitProps.csvProps}>Export CSV!!</ExportCSVButton> */}
                          </Col>
                        </Row>
                        {hasMoreResults && (
                          <Row>
                            <Col xs="12">
                              <div className="text-center my-3">
                                <Link to="#" className="text-success" onClick={handleLoadMoreResult}>
                                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                  {props.t("Common.Action.LoadMore")}
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

TasksMasterPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.any,
  csvProps: PropTypes.any,
}

export default withRouter(withTranslation()(TasksMasterPage))
