import React from "react"
import PropTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap"

import { URL_DESKTOP } from "helpers/lea-url_helper";

import error from "assets/images/error-img.png"

// i18n
import { withTranslation } from "react-i18next";

// Component
function Row404(props) {
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <div className="text-center mb-5">
            <h1 className="display-2 font-weight-medium">
              4<i className="bx bx-buoy bx-spin text-primary display-3" />
              4
            </h1>
            <h4 className="text-uppercase"> {props.t("Component.Page404.Text")} </h4>
            <div className="mt-5 text-center">
              <Link className="btn btn-primary " to={URL_DESKTOP}>
                {props.t("Common.Action.BackToDesktop")}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8" xl="6">
          <div>
            <img src={error} alt="" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

Row404.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Row404))