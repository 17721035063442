import PropTypes from 'prop-types';
import React from "react";

import { connect } from "react-redux";

// Redux store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from 'store/actions';

// Vertical layout related components
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
//import FullscreenToggle from "components/Layout/CommonForBoth/TopbarDropdown/FullscreenToggle";
//import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//i18n
import { withTranslation } from "react-i18next";

/**
 * Vertical header component
 */
const Header = props => {

  //const [search, setsearch] = useState(false);
  //const [megaMenu, setmegaMenu] = useState(false);
  //const [socialDrp, setsocialDrp] = useState(false);

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">

          {/* Partie gauche du menu horizontale */}
          <div className="d-flex">
            <button id="vertical-menu-btn" type="button" onClick={() => { tToggle(); }} className="btn btn-sm px-3 font-size-16 header-item">
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          {/* Partie droite du menu horizontale */}
          <div className="d-flex">
            <LanguageDropdown />
            {/* <FullscreenToggle /> */}
            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>

        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
