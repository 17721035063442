import React from "react"
import PropTypes from "prop-types";

import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap"

import Row404 from "./Row404";

// i18n
import { withTranslation } from "react-i18next";

// Component
function Page404(props) {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>404 Error Page</title>
        </MetaTags>
        <Container>
          <Row404 />
        </Container>
      </div>
    </React.Fragment>
  )
}

Page404.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Page404))