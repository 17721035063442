import React from "react"
import PropTypes from 'prop-types'

import { Amplify, Geo, Storage } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { transitions, positions, Provider as AlertProvider } from "react-alert"
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from "react-query/devtools"
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom"
import { connect } from "react-redux"
//import AlertTemplate from 'react-alert-template-basic'
import AlertMUITemplate from "react-alert-template-mui";

/* Custom Provider */
import { CurrentUserProvider } from "./contexts/current-user-context"

// Import css
import '@aws-amplify/ui-react/styles.css'
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
//import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'

// Import scss
import "./assets/scss/theme.scss";

// Bootstrap CSS
//import "bootstrap/dist/css/bootstrap.css";

//import 'lightgallery/scss/lightgallery.scss'
//import 'lightgallery/scss/lg-zoom.scss'
//import 'lightgallery/scss/lg-thumbnail.scss'

// layouts
import VerticalLayout from "layout/VerticalLayout"
import HorizontalLayout from "layout/HorizontalLayout"
//import NonAuthLayout from "layout/NonAuthLayout/NonAuthLayout"

// Import all routes
import { URL_CONTACTS, URL_DASHBOARD_COMPANY, URL_DASHBOARD_PERSONAL, URL_DESKTOP, URL_INVOICES, URL_MANDATES, URL_NOTIFICATIONS, URL_PROFILE, URL_REQUESTS, URL_SETTINGS, URL_TASKS } from "helpers/lea-url_helper"
//import DashboardsRouter from "pages/dashboards/DashboardsRouter"
import DesktopRouter from "pages/desktop/DesktopRouter"
import RequestsRouter from "pages/requests/RequestsRouter"
import MandatesRouter from "pages/mandates/MandatesRouter"
import TasksRouter from "pages/tasks/TasksRouter"
import InvoicesRouter from "pages/invoices/InvoicesRouter"
import ContactsRouter from "pages/contacts/ContactsRouter"
import SettingsRouter from "pages/settings/SettingsRouter"
import UserRouter from "pages/user/UserRouter"
import Page404 from "./components/Page404/Page404"

// optional configuration
const optionsAlertTemplate = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
}

/*
// Simple alert
alert.show("Oh look, an alert!");

//Alert with title
alert.show("This is an alert with title!", {
  title: "Random Alert Title",
});

//Change copy on close button
alert.show("This is an alert with title!", {
  title: "Random Alert Title",
  closeCopy: "Cancel",
});

//Alert with extra actions
alert.show("This is an alert with extra actions!", {
  title: "Alert with extra actions!",
  actions: [
    {
      copy: "Do something",
      onClick: () => setAction("Actioned!"),
    },
  ],
});
*/

/* react-query configuration */
const queryOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
}

const queryClient = new QueryClient(queryOptions)

/* Amplify configuration */
import awsExports from './aws-exports'
Amplify.configure(awsExports)

//Configure Storage with S3 bucket information
/* export function SetS3Config(bucket, level){
  Storage.configure({ 
         bucket: bucket,
         level: level,
         region: 'us-east-1',  
         identityPoolId: process.env.REACT_APP_identityPoolId 
      });
} */

//console.log(Geo.searchByText("8041 rue de burgos") )

//
// Main
// 
function App(props, { signOut, user }) {

  function getLayout() {
    let layoutCls = HorizontalLayout
    if (localStorage.getItem("LEA_LAYOUT_TYPE")) {
      switch (localStorage.getItem("LEA_LAYOUT_TYPE")) {
        case "horizontal":
          layoutCls = HorizontalLayout
          break
        default:
          layoutCls = VerticalLayout
          break
      }
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <QueryClientProvider client={queryClient}>
      <AlertProvider template={AlertMUITemplate} {...optionsAlertTemplate}>
        <CurrentUserProvider>
          <BrowserRouter>
            <Layout>
              <Switch>
                <Redirect exact from="/" to={URL_DESKTOP} />
                <Route path={URL_DESKTOP} component={DesktopRouter} />
                {/* 
                <Route path={URL_DASHBOARD_COMPANY} component={DashboardsRouter} />
                */}
                <Route path={URL_REQUESTS} component={RequestsRouter} />
                <Route path={URL_MANDATES} component={MandatesRouter} />
                <Route path={URL_TASKS} component={TasksRouter} />
                <Route path={URL_INVOICES} component={InvoicesRouter} />
                <Route path={URL_CONTACTS} component={ContactsRouter} />
                <Route path={URL_SETTINGS} component={SettingsRouter} />
                <Route path={URL_PROFILE} component={UserRouter} />
                <Route path={URL_NOTIFICATIONS} component={UserRouter} />
                <Route component={Page404} />
              </Switch>
            </Layout>
          </BrowserRouter>
        </CurrentUserProvider>
      </AlertProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  signOut: PropTypes.any,
  user: PropTypes.any,
  props: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default (connect(mapStateToProps, null))(withAuthenticator(App, false))
