import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Alert, Button, Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap"
import { isEmpty } from "lodash"
import { Storage } from "aws-amplify"
import { ImageGallery, ImageModalUploader } from "components/Image"
import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"

import img3 from "assets/images/no-image-company.jpg"

//i18n
import { withTranslation } from "react-i18next"

// Component
function CompanyView(props) {
  const { companyInformation, onEdit } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openImageUploader, setOpenImageUploader] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handlerImageModalUploaderOnAddClick(selectedFiles) {
    //addImages(images)
    alert(JSON.stringify(selectedFiles))
    Storage.put()
    alert(props.t("Common.Text.Alert.NotImplemented"))
    toggleImageUploader() // Hide form
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleImageUploader = useCallback(() => {
    setOpenImageUploader(!openImageUploader)
  }, [openImageUploader])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Row>
      <Col xl={6}>
        <Card>
          <CardBody>
            <Row>
              <FormGroup row>
                <Label sm="4" for="inputFullName">
                  {props.t("Common.Label.Contact.Name")}
                </Label>
                <Col sm="8">
                  <Input id="inputFullName" name="fullName" readOnly type="text" defaultValue={companyInformation?.contact?.fullName} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="inputEmail">
                  {props.t("Common.Label.Contact.Email")}
                </Label>
                <Col sm="8">
                  <Input id="inputEmail" name="email" readOnly type="text" defaultValue={companyInformation?.contact?.email} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="inputPhoneNumber">
                  {props.t("Common.Label.Contact.PhoneNumber")}
                </Label>
                <Col sm="8">
                  <Input id="inputPhoneNumber" name="phoneNumber" readOnly type="text" defaultValue={formatPhoneNumberAsLocal(companyInformation?.contact?.phoneNumber)} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="inputAddress">
                  {props.t("Common.Label.Contact.Address")}
                </Label>
                <Col sm="8">
                  <Input id="inputAddress" name="address" readOnly type="text" defaultValue={companyInformation?.contact?.address} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="inputCity">
                  {props.t("Common.Label.Contact.City")}
                </Label>
                <Col sm="8">
                  <Input id="inputCity" name="city" readOnly type="text" defaultValue={companyInformation?.contact?.city} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="inputStateOrProvince">
                  {props.t("Common.Label.Contact.StateOrProvince")}
                </Label>
                <Col sm="8">
                  <Input id="inputStateOrProvince" name="stateOrProvince" readOnly type="text" defaultValue={companyInformation?.contact?.stateOrProvince} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4" for="inputZipOrPostalCode">
                  {props.t("Common.Label.Contact.ZipOrPostalCode")}
                </Label>
                <Col sm="8">
                  <Input id="inputZipOrPostalCode" name="zipOrPostalCode" disabled type="text" defaultValue={companyInformation?.contact?.zipOrPostalCode} />
                </Col>
              </FormGroup>
            </Row>
            <Row>
              <div>
                <Button color="primary" className="me-2" onClick={onEdit}>
                  {props.t("Common.Action.Edit")}
                </Button>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl={6}>
        <Card>
          <CardBody>
            <CardTitle>{props.t("Page.Settings.CompanyView.Logo")}</CardTitle>
            <hr />
            <Row>
              <Col xl={3}>
                <CardImg src={img3} />
              </Col>
              <Col xl={9}>
                <CardSubtitle className="mb-3">{props.t("Page.Settings.CompanyView.Logo.Subtitle")}</CardSubtitle>
                <Button className="me-2" color="secondary" outline disabled={isEmpty(companyInformation?.contact?.id)} onClick={toggleImageUploader}>
                  {props.t("Page.Settings.CompanyView.Logo.Button")}
                </Button>
                {isEmpty(companyInformation?.contact?.id) && (
                  <Alert className="mt-1" color="danger">
                    <i className="mdi mdi-alert-outline me-1" />
                    {props.t("Page.Settings.CompanyView.Logo.Button.Disabled.Alert")}
                  </Alert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        {openImageUploader && <ImageModalUploader maxFiles={1} toggle={toggleImageUploader} onAddClick={handlerImageModalUploaderOnAddClick} />}
      </Col>
    </Row>
  )
}

CompanyView.propTypes = {
  t: PropTypes.any,
  companyInformation: PropTypes.any,
  onEdit: PropTypes.func,
}

export default withRouter(withTranslation()(CompanyView))
