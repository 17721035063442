import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { useMutation } from "react-query"
import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledTooltip } from "reactstrap"
import { isNil } from "lodash"

import RequestDetailContactTableRow from "./RequestDetailContactTableRow"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { updateRequestContact } from "graphql/mutations"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"

// Component
function RequestDetailContactView(props) {
  const { username, request, contacts, onAddNew, onEdit, onDelete, onSetAsPrimary } = props

  // Alert
  const customAlert = useAlert()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuContact, setOpenMenuContact] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMenuActionAddNewContactOnClick(e) {
    //console.log("RequestContactView.handleMenuActionAddNewContactOnClick");
    e.preventDefault()
    onAddNew()
  }

  function handleRequestContactTableRowOnEdit(entityRequestContact) {
    //console.log("RequestContactView.handleContactTableRowOnEdit:entityRequestContact", entityRequestContact);
    onEdit(entityRequestContact)
  }

  async function handleRequestContactTableRowOnDelete(entityRequestContact) {
    //console.log("RequestContactView.handleContactTableRowOnDelete:entityRequestContact", entityRequestContact);
    let entityRequestContactDelete = await doDeleteRequestContact(entityRequestContact)
    if (isNil(entityRequestContactDelete)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de supprimer le contact" }))
      return
    }
    // Success
    onDelete(entityRequestContactDelete)
  }

  async function handleRequestContactTableRowOnSetAsPrimary(entityRequestContact) {
    //console.log("RequestContactView.handleContactTableRowOnSetAsPrimary:entityRequestContact", entityRequestContact);
    // find the actual requestContact with a primary flag
    let entityRequestContactPrevious = contacts.find(item => item.primary === true)
    if (!isNil(entityRequestContactPrevious)) {
      // Update request contact
      entityRequestContactPrevious.primary = false
      let entityRequestContactPreviousUpdate = await doUpdateRequestContact(entityRequestContactPrevious)
      if (isNil(entityRequestContactPreviousUpdate)) {
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de retirer le flag sur le contact précédent" }))
        return
      }
      // Mettre à jour le state pour que le contact soit à jour avec la dernière version de l'objet qui a été mis à jour
      for (var i in contacts) {
        if (contacts[i].id === entityRequestContactPreviousUpdate.id) {
          contacts[i].primary = entityRequestContactPreviousUpdate.primary
          contacts[i]._version = entityRequestContactPreviousUpdate._version
          break //Stop this loop, we found it!
        }
      }
    }

    // Set the primary flag for the new assignation
    entityRequestContact.primary = true
    // Update request contact
    let entityRequestContactUpdate = await doUpdateRequestContact(entityRequestContact)
    if (isNil(entityRequestContactUpdate)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de mettre a jour le contact" }))
      return
    }
    // Mettre à jour le state pour que le contact soit à jour avec la dernière version de l'objet qui a été mis à jour
    for (var i in contacts) {
      if (contacts[i].id === entityRequestContactUpdate.id) {
        contacts[i].primary = entityRequestContactUpdate.primary
        contacts[i]._version = entityRequestContactUpdate._version
        break //Stop this loop, we found it!
      }
    }

    // Success
    onSetAsPrimary(entityRequestContactUpdate)
  }

  async function doDeleteRequestContact(entityRequestContact) {
    //console.log("RequestContactView.doDeleteRequestContact:entityRequestContact", entityRequestContact);
    const input = {
      ...entityRequestContact,
      updateUser: username,
      retired: true,
    }
    //console.log("RequestContactView.doDeleteRequestContact:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestContactView.doDeleteRequestContact:updateInput", updateInput)
    try {
      const result = await updateRequestContactMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("RequestContactView.doDeleteRequestContact:error", error)
      return null
    }
  }

  async function doUpdateRequestContact(entityRequestContact) {
    //console.log("RequestContactView.doUpdateRequestContact:entityRequestContact", entityRequestContact);
    const input = {
      ...entityRequestContact,
      updateUser: username,
    }
    //console.log("RequestContactView.doUpdateRequestContact:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestContactView.doUpdateRequestContact:updateInput", updateInput)
    try {
      const result = await updateRequestContactMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("RequestContactView.doUpdateRequestContact:error", error)
      return null
    }
  }

  const updateRequestContactMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateRequestContact", updateRequestContact, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuContact = useCallback(() => {
    setOpenMenuContact(!openMenuContact)
  }, [openMenuContact])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const requestContactsSortedAsc = useMemo(() => contacts.sort((objA, objB) => new Date(objA.createdAt).getTime() - new Date(objB.createdAt).getTime()), [contacts])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.RequestDetail.ContactView")}</CardTitle>
          </div>
          {!request.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuContact} toggle={toggleMenuContact}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={e => handleMenuActionAddNewContactOnClick(e)}>{props.t("Page.RequestDetail.ContactView.Action.AddNewContact")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {contacts.length === 0 && (
          <Row className="mt-2">
            <Col xl="12">
              <Alert color="danger">
                <i className="mdi mdi-alert-outline me-1" />
                {props.t("Common.Text.Alert.Contacts")}
              </Alert>
            </Col>
          </Row>
        )}
        {contacts.length > 0 && (
          <Row className="mt-2">
            <Col xl="12">
              <div className="table-responsive">
                <Table size="sm" className="table-nowrap">
                  <thead>
                    <tr>
                      {!request.retired &&
                        <th className="no-print">
                          {props.t("Common.Label.Action")}
                          <sup>
                            <i id="action-tooltip" className="bx bx-info-circle font-size-16" />
                          </sup>
                          <UncontrolledTooltip placement="right" target="action-tooltip">
                            {props.t("Common.Text.Tooltip.Primary")}
                          </UncontrolledTooltip>
                        </th>
                      }
                      <th>{props.t("Common.Label.Primary")}</th>
                      <th>{props.t("Common.Label.Informations")}</th>
                      <th>{props.t("Common.Label.Roles")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requestContactsSortedAsc.map(requestContact => (
                      <RequestDetailContactTableRow key={requestContact.id} request={request} requestContact={requestContact} onEdit={handleRequestContactTableRowOnEdit} onDelete={handleRequestContactTableRowOnDelete} onSetAsPrimary={handleRequestContactTableRowOnSetAsPrimary} />
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

RequestDetailContactView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  username: PropTypes.string,
  request: PropTypes.any,
  contacts: PropTypes.any,
  onAddNew: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSetAsPrimary: PropTypes.func,
}

export default withRouter(withTranslation()(RequestDetailContactView))
