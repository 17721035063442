import React, { useState } from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "store/actions";

// Horizontal layout related components
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
//import FullscreenToggle from "components/Layout/CommonForBoth/TopbarDropdown/FullscreenToggle";
//import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logothemelight from "assets/images/lea-black-logo-no-background.svg"
import logothemedark from "assets/images/lea-color-logo-no-background.svg"

//i18n
import { withTranslation } from "react-i18next";

/**
 * Horizontal header component
 */
const Header = props => {

  //const [menu, setMenu] = useState(false);
  //const [isSearch, setSearch] = useState(false);
  //const [socialDrp, setsocialDrp] = useState(false);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">

          {/* Partie gauche du menu horizontale */}
          <div className="d-flex">
            <div className="navbar-brand-box">
              {/* Logo pour le theme Light */}
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logothemelight} alt="" height="28" />
                </span>
                <span className="logo-lg">
                  <img src={logothemelight} alt="" height="48" />
                  <span className="text-dark">
                    {/* ACME Corp. */}
                  </span>
                </span>
              </Link>

              {/* Logo pour le theme Dark */}
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logothemedark} alt="" height="28" />
                </span>
                <span className="logo-lg">
                  <img src={logothemedark} alt="" height="56" />
                </span>
              </Link>
            </div>

            {/* Logo #3 - les logos #1 & #2 disparraissent et un bouton avec logo plus petit apparaît */}
            <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-toggle="collapse" onClick={() => { props.toggleLeftmenu(!props.leftMenu); }} data-target="#topnav-menu-content">
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          {/* Partie droite du menu horizontale */}
          <div className="d-flex">
            <LanguageDropdown />
            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>

        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
