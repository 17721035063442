import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { MetaTags } from "react-meta-tags"
import { useAlert } from "react-alert"
import { useParams, withRouter } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Col, Container, Row, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import InvoiceDetailSummaryView from "./views/summary/InvoiceDetailSummaryView"
import InvoiceDetailInvoiceView from "./views/invoice/InvoiceDetailInvoiceView"
import InvoiceDetailInvoiceViewEdit from "./views/invoice/InvoiceDetailInvoiceViewEdit"
import Row404 from "components/Page404/Row404"

import { useCurrentUser } from "contexts/current-user-context"
import { URL_INVOICES } from "helpers/lea-url_helper"

import { callAPI, callAPI2, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { getInvoiceDetail } from "./graphql/queries"
import { updateInvoice } from "graphql/mutations"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function InvoiceDetailPage(props) {
  // Get invoice id from the current URL (query param)
  const { invoiceId } = useParams()

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [invoice, setInvoice] = useState(null)
  const [users, setUsers] = useState([])
  const [openEditInvoiceView, setOpenEditInvoiceView] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  const { isError, isLoading, isFetching, isIdle, error } = useQuery(
    "getInvoiceDetail-" + invoiceId,
    () =>
      firstValueFrom(
        callAPI2("getInvoiceDetail", getInvoiceDetail, {
          id: invoiceId,
          /* EXAMPLE
            filterUser : { userStatus: { eq: UserStatus.ACTIVE } },
            */
          limit: 1000,
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("InvoiceDetail.getInvoiceDetail.onSuccess:result", result)
        setInvoice(isEmpty(result?.getInvoice) ? {} : result.getInvoice)
        setUsers(isEmpty(result?.listUsers?.items) ? [] : result?.listUsers?.items)
      },
      onError: error => {
        console.error("InvoiceDetailPage.getInvoiceDetail.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }), { timeout: 0 })
      },
    }
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleDetailViewOnDelete(entityInvoice) {
    //console.log("InvoiceDetailPage.handleDetailViewOnDelete:entityInvoice", entityInvoice);
    doDeleteInvoice(entityInvoice)
  }

  function handleInvoiceViewOnUpdate(invoiceData) {
    //console.log("InvoiceDetailPage.handleInvoiceViewOnUpdate:invoiceData", invoiceData);
    doUpdateInvoice(invoiceData)
  }

  function handleInvoiceViewOnEdit() {
    //console.log("InvoiceDetailPage.handleInvoiceViewOnEdit");
    toggleEditInvoiceView() // Open EditInvoiceView
  }

  function handleEditInvoiceViewOnCancel() {
    //console.log("InvoiceDetailPage.handleEditInvoiceViewOnCancel");
    toggleEditInvoiceView() // Close EditInvoiceView
  }

  function handleEditInvoiceViewOnSave(invoiceData) {
    //console.log("InvoiceDetailPage.handleEditInvoiceViewOnSave:invoiceData", invoiceData);
    toggleEditInvoiceView() // Close EditInvoiceView
    doUpdateInvoice(invoiceData)
  }

  function doDeleteInvoice(entityInvoice) {
    //console.log("InvoiceDetailPage.doDeleteInvoice:entityInvoice", entityInvoice);
    const input = {
      ...entityInvoice,
      updateUser: username,
      retired: true,
    }
    //console.log("InvoiceDetailPage.doDeleteInvoice:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("InvoiceDetailPage.doDeleteInvoice:updateInput", updateInput)
    updateInvoiceMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("InvoiceDetailPage.doDeleteInvoice.updateInvoiceMutation.onSuccess:result", result)
        props.history.push(URL_INVOICES)
      },
      onError: error => {
        console.error("InvoiceDetailPage.doDeleteInvoice.updateInvoiceMutation.onError:error", error)
        queryClient.invalidateQueries("getInvoiceDetail-" + invoiceId)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  function doUpdateInvoice(invoiceData) {
    //console.log("InvoiceDetailPage.doUpdateInvoice:invoiceData", invoiceData);
    // On a rien de plus à faire puisque l'objet invoiceData correspond a l'objet invoice
    const input = {
      ...invoiceData,
      updateUser: username,
    }
    //console.log("InvoiceDetailPage.doUpdateInvoice:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("InvoiceDetailPage.doUpdateInvoice:updateInput", updateInput)
    updateInvoiceMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("InvoiceDetailPage.doUpdateInvoice.updateInvoiceMutation.onSuccess:result", result)
        setInvoice(result)
      },
      onError: error => {
        console.error("InvoiceDetailPage.doUpdateInvoice.updateInvoiceMutation.onError:error", error)
        queryClient.invalidateQueries("getInvoiceDetail-" + invoiceId)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const updateInvoiceMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateInvoice", updateInvoice, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleEditInvoiceView = useCallback(() => {
    setOpenEditInvoiceView(!openEditInvoiceView)
  }, [openEditInvoiceView])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Invoice detail</title>
      </MetaTags>
      <Container fluid={true}>
        {(isLoading || isFetching) && <Spinner className="ms-2" color="primary" />}
        {(!isLoading && !isFetching && !isError) &&
          <React.Fragment>
            {!isEmpty(invoice) && (
              <Row>
                <Col md={12} xl={5}>
                  <InvoiceDetailSummaryView invoice={invoice} users={users} onDelete={handleDetailViewOnDelete} />
                </Col>
                <Col md={12} xl={7}>
                  {openEditInvoiceView ? <InvoiceDetailInvoiceViewEdit invoice={invoice} onCancel={handleEditInvoiceViewOnCancel} onSave={handleEditInvoiceViewOnSave} /> : <InvoiceDetailInvoiceView invoice={invoice} onEdit={handleInvoiceViewOnEdit} onUpdate={handleInvoiceViewOnUpdate} />}
                </Col>
              </Row>
            )}
            {isEmpty(invoice) && <Row404 />}
          </React.Fragment>
        }
      </Container>
    </div>
  )
}

InvoiceDetailPage.propTypes = {
  t: PropTypes.any,
  tasks: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(InvoiceDetailPage))
