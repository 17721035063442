import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { useAlert } from "react-alert"
import { useQuery, useQueryClient } from "react-query"
import { useParams, withRouter } from "react-router-dom"
import { Col, Container, Row, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import ContactDetailSummaryView from "./views/summary/ContactDetailSummaryView"
import ContactDetailSummaryViewEdit from "./views/summary/ContactDetailSummaryViewEdit"
import MandateContactView from "./views/mandate/ContactDetailMandateView"
import RequestContactView from "./views/request/ContactDetailRequestView"
import Row404 from "components/Page404/Row404"

import { useCurrentUser } from "contexts/current-user-context"

import { callAPI2 } from "helpers/lea-graphql_helper"
import { getContactDetail, listContactDetailSettings } from "./graphql/queries"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function ContactDetailPage(props) {
  // Get request id from the current URL (query param)
  const { contactId } = useParams()

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  //const [addBusinessDaysForRequest, setAddBusinessDaysForRequest] = useState(15);
  //const [addBusinessDaysForInvoice, setAddBusinessDaysForInvoice] = useState(addBusinessDaysForRequest + 30);
  const [contact, setContact] = useState({})
  const [requestContacts, setRequestContacts] = useState([])
  const [mandateContacts, setMandateContacts] = useState([])
  const [optionsMandateTypes, setOptionsMandateTypes] = useState([])
  const [users, setUsers] = useState([])

  const [openEditContactView, setOpenEditContactView] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get info contact
  const {
    isError: isErrorContactDetail,
    isIdle: isIdleContactDetail,
    isLoading: isLoadingContactDetail,
    isFetching: isFetchingContactDetail,
    error: errorContactDetail,
  } = useQuery(
    "getContactDetail-" + contactId,
    () =>
      firstValueFrom(
        callAPI2("getContactDetail", getContactDetail, {
          id: contactId,
          filterRequestContacts: { contactId: { eq: contactId }, and: { retired: { ne: true } } },
          filterMandateContacts: { contactId: { eq: contactId }, and: { retired: { ne: true } } },
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("ContactDetailPage.getContact.onSuccess:result", result)
        setContact(isEmpty(result.getContact) ? {} : result.getContact)
        setRequestContacts(isEmpty(result.listRequestContacts?.items) ? [] : result.listRequestContacts?.items)
        setMandateContacts(isEmpty(result.listMandateContacts?.items) ? [] : result.listMandateContacts?.items)
      },
      onError: error => {
        console.error("ContactDetailPage.getContactDetail.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }), { timeout: 0 })
      },
    }
  )

  // Get settings data
  const {
    isError: isErrorListContactDetailSettings,
    isIdle: isIdleListContactDetailSettings,
    isLoading: isLoadingListContactDetailSettings,
    isFetching: isFetchingListContactDetailSettings,
    error: errorListContactDetailSettingss,
  } = useQuery(
    "listContactDetailSettings-" + contactId,
    () =>
      firstValueFrom(
        callAPI2("listContactDetailSettings", listContactDetailSettings, {
          /* EXAMPLE
            filterSettingsMandate: { xxx: { eq: ??? } },
            filterSettingsTask: { xxx: { eq: ??? } },
            filterUser : { userStatus: { eq: UserStatus.ACTIVE } },
            */
          limit: 1000,
        })
      ),
    {
      retry: false,
      /*enabled: !isIdle && !isLoading && !isFetching,*/ /* on n'a pas besoin d'attendre le retour de la première requête */
      onSuccess: result => {
        //console.log("ContactDetailPage.listContactDetailSettings.onSuccess:result", result)
        setOptionsMandateTypes(isEmpty(result?.listSettingsMandateTypes?.items) ? [] : result?.listSettingsMandateTypes?.items)
        setUsers(isEmpty(result?.listUsers?.items) ? [] : result?.listUsers?.items)
      },
      onError: error => {
        console.error("ContactDetailPage.listContactDetailSettings.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    }
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleContactViewOnEdit(entityContact) {
    //console.log("ContactDetailPage.handleContactViewOnEdit:entityContact", entityContact);
    toggleEditContactView() // Open EditContactView
  }

  function handleContactViewOnDelete(entityContact) {
    //console.log("ContactDetailPage.handleContactViewOnDelete:entityContact", entityContact);
  }

  function handleEditContactViewOnSave(entityContact) {
    //console.log("ContactDetailPage.handleEditContactVienOnSave:entityContact", entityContact);
    toggleEditContactView() // Close EditContactView
    setContact(entityContact)
  }

  function handleEditContactViewOnCancel() {
    //console.log("ContactDetailPage.handleEditContactViewOnCancel");
    toggleEditContactView() // Close EditContactView
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleEditContactView = useCallback(() => {
    setOpenEditContactView(!openEditContactView)
  }, [openEditContactView])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Contact detail</title>
      </MetaTags>
      <Container fluid={true}>
        {(isLoadingContactDetail || isFetchingContactDetail || isLoadingListContactDetailSettings || isFetchingListContactDetailSettings) && <Spinner className="ms-2" color="primary" />}
        {(!isLoadingContactDetail && !isFetchingContactDetail && !isErrorContactDetail && !isLoadingListContactDetailSettings && !isFetchingListContactDetailSettings && !isErrorListContactDetailSettings) &&
          <React.Fragment>
            {!isEmpty(contact) ? (
              <Row>
                {!openEditContactView && (
                  <React.Fragment>
                    <Col xl={4}>
                      <ContactDetailSummaryView contact={contact} users={users} onEdit={handleContactViewOnEdit} onDelete={handleContactViewOnDelete} />
                    </Col>
                    <Col xl={8}>
                      <RequestContactView contact={contact} requestContacts={requestContacts} />
                      <MandateContactView contact={contact} mandateContacts={mandateContacts} optionsMandateTypes={optionsMandateTypes} />
                    </Col>
                  </React.Fragment>
                )}
                <Col xl={12}>{openEditContactView && <ContactDetailSummaryViewEdit username={username} contact={contact} onSave={handleEditContactViewOnSave} onCancel={handleEditContactViewOnCancel} />}</Col>
              </Row>
            ) : (
              <Row404 />
            )}
          </React.Fragment>
        }
      </Container>
    </div>
  )
}

ContactDetailPage.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(ContactDetailPage))
