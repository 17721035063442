import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"

import SettingsUserCard from "./SettingsUserCard"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsUserTableRow(props) {
  const { row, onSave } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openUserForm, setOpenUserForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleUserCardOnSave(userData) {
    //console.log("UserTableRow.handleUpdateUser.userData", userData);
    toggleUserForm() // Hide form
    onSave(userData)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleUserForm = useCallback(() => {
    setOpenUserForm(!openUserForm)
  }, [openUserForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={row.id}>
      {!openUserForm && (
        <React.Fragment>
          <td className="align-middle" style={{ width: "45%" }}>
            {row.email}
          </td>
          <td className="align-middle" style={{ width: "35%" }}>
            {row.username}
          </td>
          <td className="align-middle text-end">{row.userStatus}</td>
        </React.Fragment>
      )}
      {openUserForm && (
        <td colSpan={2}>
          <SettingsUserCard userInfo={row} onSave={handleUserCardOnSave} onCancel={toggleUserForm} />
        </td>
      )}
    </tr>
  )
}

SettingsUserTableRow.propTypes = {
  t: PropTypes.any,
  props: PropTypes.any,
  row: PropTypes.any,
  onSave: PropTypes.func,
}

export default withRouter(withTranslation()(SettingsUserTableRow))
