import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress, Table } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

// Component
function TaskDetailNotesView(props) {
  const { task, notes, users, optionsTaskType, onAddNew, onDelete } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openAddNoteForm, setOpenAddNoteForm] = useState(false)
  const [openMenuNote, setOpenMenuNote] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMenuActionOnAddNewNote(e) {
    //console.log("TaskDetailNotesView.handleMenuActionOnAddNewNote");
    alert(props.t("Common.Text.Alert.NotImplemented"))
    toggleAddNoteForm() // Open AddNoteForm
  }

  function handleAddNoteFormOnSave(entityNote) {
    //console.log("TaskDetailNotesView.handleAddNoteFormOnSave:note", entityNote);
    toggleAddNoteForm() // Hide AddNoteForm
    onAddNew(entityNote)
  }

  function handleNoteTableRowOnDelete(entityNote) {
    //console.log("TaskDetailNotesView.handleNoteTableRowOnDelete:entityNote", entityNote);
    onDelete(entityNote)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleAddNoteForm = useCallback(() => {
    setOpenAddNoteForm(!openAddNoteForm)
  }, [openAddNoteForm])

  const toggleMenuNote = useCallback(() => {
    setOpenMenuNote(!openMenuNote)
  }, [openMenuNote])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const notesSortedAsc = notes.sort((objA, objB) => objA.order - objB.order)

  const countAllNote = () => {
    return notes.reduce((cumul, tsk) => {
      return cumul + 1
    }, 0)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.TaskDetail.NotesView")}</CardTitle>
          </div>
          {!task.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuNote} toggle={toggleMenuNote}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={e => handleMenuActionOnAddNewNote(e)}> {props.t("Page.TaskDetail.NotesView.Action.AddNewNote")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {/* {openAddNoteForm && <MandataDetailTaskModalForm taskTypesOptions={optionsTaskType} toggle={toggleAddTaskForm} onSave={handleAddTaskFormOnSave} />} */}
        <div className="mt-2">
          {countAllNote() === 0 ? (
            <Alert color="danger">
              <i className="mdi mdi-alert-outline me-1" />
              {props.t("Common.Text.Alert.Notes")}
            </Alert>
          ) : (
            <div className="table-responsive">
              <Table size="sm" className="table-nowrap">
                <thead>
                  <tr>
                    <th>{props.t("Common.Label.Action")}</th>
                    <th>{props.t("Common.Label.TaskType")}</th>
                    <th>{props.t("Common.Label.Assignee")}</th>
                    <th className="text-end">{props.t("Common.Label.Status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {notesSortedAsc.map(note => (note.retired || (!task.retired && !note.retired) 
                    ? null // ? <MandateDetailTaskTableRow key={task.id} task={task} optionsTaskType={optionsTaskType} users={users} onDelete={handleNoteTableRowOnDelete} /> 
                    : null)
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

TaskDetailNotesView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  task: PropTypes.any,
  notes: PropTypes.array,
  users: PropTypes.array,
  optionsTaskType: PropTypes.array,
  onAddNew: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(TaskDetailNotesView))
