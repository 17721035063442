import React, { useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { useQuery } from "react-query"
import { Card, CardBody, CardTitle, Col, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap"
import { useCurrentUser } from "contexts/current-user-context"
import { isEmpty } from "lodash"

import DesktopTaskTableRow from "./DesktopTaskTableRow"

import { callAPI } from "helpers/lea-graphql_helper"
import { listTasks, listSettingsTaskTypes, listSettingsMandateTypes } from "graphql/queries"
import { TaskStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function DesktopTasksView(props) {
  const {
    currentUser: { username },
  } = useCurrentUser()

  // State
  const [tasks, setTasks] = useState([])
  const [tasksPending, setTasksPending] = useState([])
  const [tasksStarted, setTasksStarted] = useState([])
  const [tasksCompleted, setTasksCompleted] = useState([])
  const [optionsMandateType, setOptionsMandateTypes] = useState([])
  const [optionsTaskType, setOptionsTaskType] = useState([])

  const [displayModeCard, setDisplayMode] = useState(true)

  const [showAlert, setShowAlert] = useState(false)
  const [showError, setShowError] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  // Get list of tasks
  const { isError, isFetching, isIdle, isLoading, error } = useQuery(
    "listTasks",
    () =>
      firstValueFrom(
        callAPI("listTasks", listTasks, {
          filter: {
            assignee: { eq: username },
            retired: { ne: true },
          },
          limit: 10000,
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("DesktopTasksView.listTasks.onSuccess:result", result)
        setTasks(isEmpty(result?.items) ? [] : result.items)
        setTasksPending(
          result?.items.filter(task => {
            return task.status === TaskStatus.PENDING
          })
        )
        setTasksStarted(
          result?.items.filter(task => {
            return task.status === TaskStatus.STARTED
          })
        )
        setTasksCompleted(
          result?.items.filter(task => {
            return task.status === TaskStatus.COMPLETED
          })
        )
      },
      onError: error => {
        console.error("DesktopTasksView.listTasks.onError:error", error)
        setErrorMessage(error.errors[0].message)
        setShowError(true)
      },
    }
  )

  // Get list of mandate types
  const {
    isLoading: isLoadingSettingsMandateType,
    isIdle: isIdleSettingsMandateType,
    isFetching: isFetchingSettingsMandateType,
    isError: isErrorSettingsMandateType,
    error: errorSettingsMandateType,
  } = useQuery("listSettingsMandateTypes", () => firstValueFrom(callAPI("listSettingsMandateTypes", listSettingsMandateTypes)), {
    retry: false,
    //enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("DesktopTasksView.listSettingsMandateTypes.onSuccess:result", result)
      setOptionsMandateTypes(isEmpty(result?.items) ? [] : result.items)
    },
    onError: error => {
      console.error("DesktopTasksView.listSettingsMandateTypes.onError:error", error)
    },
  })

  // Get list of task types
  const {
    isLoading: isLoadingSettingsTaskType,
    isFetching: isFetchingSettingsTaskType,
    isError: isErrorSettingsTaskType,
    error: errorSettingsTaskType,
  } = useQuery("listSettingsTaskTypes", () => firstValueFrom(callAPI("listSettingsTaskTypes", listSettingsTaskTypes)), {
    retry: false,
    //enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("DashboardPersonalPage.listSettingsTaskTypes.onSuccess:result", result)
      setOptionsTaskType(isEmpty(result?.items) ? [] : result.items)
    },
    onError: error => {
      console.error("DashboardPersonalPage.listSettingsTaskTypes.onError:error", error)
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {(isLoading || isFetching || isLoadingSettingsMandateType || isFetchingSettingsMandateType || isLoadingSettingsTaskType || isFetchingSettingsTaskType) && <Spinner className="ms-2" color="primary" />}
      {(!isLoading && !isFetching && !isError && !isLoadingSettingsMandateType && !isFetchingSettingsMandateType && !isErrorSettingsMandateType && !isLoadingSettingsTaskType && !isFetchingSettingsTaskType && !isErrorSettingsTaskType) &&
        <Card>
          <CardBody>
            <Row>
              <Col sm={4}>
                <Card color="danger" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{tasksPending?.length > 0 ? tasksPending?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-empty font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Pending")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={4}>
                <Card color="warning" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{tasksStarted?.length > 0 ? tasksStarted?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-half font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Inprogress")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={4}>
                <Card color="success" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{tasksCompleted?.length > 0 ? tasksCompleted?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-full font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Completed")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {tasks.length > 0 &&
              <Row className="mt-4">
                <Col lg={12}>
                  <Table hover responsive size="sm">
                    <thead>
                      <tr>
                        <th width="8%">{props.t("Common.Label.Action")}</th>
                        <th width="10%">{props.t("Common.Label.Status")}</th>
                        <th>{props.t("Common.Label.TaskType")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tasksPending.map(task => (
                        <DesktopTaskTableRow key={task.id} task={task} optionsTaskTypes={optionsTaskType} optionsMandateTypes={optionsMandateType} />
                      ))}
                      {tasksStarted.map(task => (
                        <DesktopTaskTableRow key={task.id} task={task} optionsTaskTypes={optionsTaskType} optionsMandateTypes={optionsMandateType} />
                      ))}
                      {tasksCompleted.map(task => (
                        <DesktopTaskTableRow key={task.id} task={task} optionsTaskTypes={optionsTaskType} optionsMandateTypes={optionsMandateType} />
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            }
          </CardBody>
        </Card>
      }
    </React.Fragment>
  )
}

DesktopTasksView.propTypes = {
  t: PropTypes.any,
  mandates: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(DesktopTasksView))
