import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import classname from "classnames";
import { connect } from "react-redux";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";

import { URL_DASHBOARD_COMPANY, URL_DASHBOARD_PERSONAL, URL_REQUESTS, URL_MANDATES, URL_TASKS, URL_INVOICES, URL_SETTINGS, URL_CONTACTS, URL_DESKTOP } from "helpers/lea-url_helper"

//i18n
import { withTranslation } from "react-i18next";

/**
 * Horizontal Navbar component
 */
const Navbar = props => {

  // assigning location variable
  const location = useLocation();

  // destructuring pathname from location
  const { pathname } = location;

  const [dashboard, setdashboard] = useState(false);
  const [admin, setadmin] = useState(false);

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav id="navigation" className="navbar navbar-expand-lg topnav-menu">
            <Collapse id="topnav-menu-content" isOpen={props.leftMenu} className="navbar-collapse">
              <ul className="navbar-nav">

                {/* ++++ MENU DASHBOARD ++++ */}
                {/*
                <li className={classname('nav-item', { 'active': (pathname.startsWith(URL_DASHBOARD_COMPANY) || pathname.startsWith(URL_DASHBOARD_PERSONAL)) }, 'dropdown')}>
                  <Link to="/#" onClick={e => { e.preventDefault(); { setdashboard(!dashboard); } }} className={classname('nav-link', { 'active': (pathname.startsWith(URL_DASHBOARD_COMPANY) || pathname.startsWith(URL_DASHBOARD_PERSONAL)) }, 'dropdown-togglez arrow-none')}>
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Menu.Dashboard")}
                    <div className="arrow-down" />
                  </Link>
                  <div className={classname('dropdown-menu', { show: dashboard }, { 'active': (pathname.startsWith(URL_DASHBOARD_COMPANY) || pathname.startsWith(URL_DASHBOARD_PERSONAL)) })}>
                   
                    <Link to={URL_DEEDSOFSALE} onClick={e => setadmin(false)} className={classname('dropdown-item', { 'active': pathname.startsWith(URL_DEEDSOFSALE) })}>
                      {props.t("Menu.DeedsOfSale")}
                    </Link>
                    <Link to={URL_INDEXATION} onClick={e => setadmin(false)} className={classname('dropdown-item', { 'active': pathname.startsWith(URL_INDEXATION) })}>
                      {props.t("Menu.Admin.Indexation")}
                    </Link>
                    
                    <Link to={URL_DASHBOARD_COMPANY} onClick={e => setdashboard(false)} className={classname('dropdown-item', { 'active': pathname.startsWith(URL_DASHBOARD_COMPANY) })}>
                      {props.t("Menu.Dashboard.Company")}
                    </Link>
                    <Link to={URL_DASHBOARD_PERSONAL} onClick={e => setdashboard(false)} className={classname('dropdown-item', { 'active': pathname.startsWith(URL_DASHBOARD_PERSONAL) })}>
                      {props.t("Menu.Dashboard.Personal")}
                    </Link>
                  </div>
                </li>
                */}
                <li className={classname('nav-item', { 'active': pathname.startsWith(URL_DESKTOP) })}>
                  <Link to={URL_DESKTOP} className={classname('nav-link', { 'active': pathname.startsWith(URL_DESKTOP) })}>
                    <i className="fas fa-th-large me-2"></i>
                    {props.t("Menu.Desktop")}
                  </Link>
                </li>

                <li className={classname('nav-item', { 'active': pathname.startsWith(URL_REQUESTS) })}>
                  <Link to={URL_REQUESTS} className={classname('nav-link', { 'active': pathname.startsWith(URL_REQUESTS) })}>
                    <i className="fas fa-seedling me-2"></i>
                    {props.t("Menu.Requests")}
                  </Link>
                </li>

                <li className={classname('nav-item', { 'active': pathname.startsWith(URL_MANDATES) })}>
                  <Link to={URL_MANDATES} className={classname('nav-link', { 'active': pathname.startsWith(URL_MANDATES) })}>
                    <i className="fas fa-file-alt me-2"></i>
                    {props.t("Menu.Mandates")}
                  </Link>
                </li>

                <li className={classname('nav-item', { 'active': pathname.startsWith(URL_TASKS) })}>
                  <Link to={URL_TASKS} className={classname('nav-link', { 'active': pathname.startsWith(URL_TASKS) })}>
                    <i className="fas fa-tasks me-2"></i>
                    {props.t("Menu.Tasks")}
                  </Link>
                </li>

                <li className={classname('nav-item', { 'active': pathname.startsWith(URL_INVOICES) })}>
                  <Link to={URL_INVOICES} className={classname('nav-link', { 'active': pathname.startsWith(URL_INVOICES) })}>
                    <i className="fas fa-file-invoice-dollar me-2"></i>
                    {props.t("Menu.Invoices")}
                  </Link>
                </li>

                <li className={classname('nav-item', { 'active': pathname.startsWith(URL_CONTACTS) })}>
                  <Link to={URL_CONTACTS} className={classname('nav-link', { 'active': pathname.startsWith(URL_CONTACTS) })}>
                    <i className="fas fa-address-book me-2"></i>
                    {props.t("Menu.Contacts")}
                  </Link>
                </li>

                <li className={classname('nav-item', { 'active': pathname.startsWith(URL_SETTINGS) })}>
                  <Link to={URL_SETTINGS} className={classname('nav-link', { 'active': pathname.startsWith(URL_SETTINGS) })}>
                    <i className="fas fa-sliders-h me-2"></i>
                    {props.t("Menu.Admin.Settings")}
                  </Link>
                </li>

                {/* ++++ MENU ADMIN ++++ */}
                {/*
                <li className={classname('nav-item', { 'active': (pathname.startsWith(URL_SETTINGS)) }, 'dropdown')}>
                  <Link to="/#" onClick={e => { e.preventDefault(); { setadmin(!admin); } }} className={classname('nav-link', { 'active': (pathname.startsWith(URL_SETTINGS)) }, 'dropdown-togglez arrow-none')}>
                    <i className="bx bx-slider me-2"></i>
                    {props.t("Menu.Admin")}
                    <div className="arrow-down" />
                  </Link>
                  <div className={classname('dropdown-menu', { show: admin }, { 'active': (pathname.startsWith(URL_SETTINGS)) })}>
                    
                    <Link to={URL_DEEDSOFSALE} onClick={e => setadmin(false)} className={classname('dropdown-item', { 'active': pathname.startsWith(URL_DEEDSOFSALE) })}>
                      {props.t("Menu.DeedsOfSale")}
                    </Link>
                    <Link to={URL_INDEXATION} onClick={e => setadmin(false)} className={classname('dropdown-item', { 'active': pathname.startsWith(URL_INDEXATION) })}>
                      {props.t("Menu.Admin.Indexation")}
                    </Link>
                   
                    <Link to={URL_SETTINGS} onClick={e => setadmin(false)} className={classname('dropdown-item', { 'active': pathname.startsWith(URL_SETTINGS) })}>
                      {props.t("Menu.Admin.Settings")}
                    </Link>
                  </div>
                </li>
                */}

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
