import React from "react";
import PropTypes from 'prop-types'

import Imgix from "react-imgix";
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const buildURL = imageObjectKey =>
    `${process.env.REACT_APP_IMGIX_URL}/${imageObjectKey}`;
    
// Component
function ImageGallery({ images = [], thumbnail:{width, height} }) {
    
    return (
        <LightGallery
            plugins={[lgThumbnail, lgZoom]}
        >
            {images.map(({ id, objectKey }) => (
                <a
                    key={id}
                    data-src={`${buildURL(objectKey)}?auto=format&q=65`}
                >
                    <Imgix
                        sizes="(min-width: 960px) 33vw, (min-width: 640px) 50vw, 100vw"
                        src={buildURL(objectKey)}
                        key={id}
                        imgixParams={{
                            auto: 'format',
                            fit: "scale",
                            fm: "jpg"
                        }}
                        width={width}
                        height={height}
                    />
                </a>
            ))}
        </LightGallery>
    )
}

ImageGallery.defaultProps = {
    images : [],
    thumbnail: {
        width: 240,
        height: 240
    }
}

ImageGallery.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        objectKey: PropTypes.string.isRequired
    })),
    thumbnail: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
    })
}

export default ImageGallery;
