import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { isNil } from "lodash";

import AddContactCard from "./AddContactCard";
import ContactSearchCard from "./ContactSearchCard";

// i18n
import { withTranslation } from "react-i18next";

// Component
function ContactSearchView(props) {

    const { onSelect, onAddNew, onCancel, displayCancelButton } = props

    // ----------------------------------------------------------------------------------------------------------------
    // State
    // ----------------------------------------------------------------------------------------------------------------
    const [openAddContactCard, setOpenAddContactCard] = useState(false)
    const [contactToAdd, setContactToAdd] = useState({})

    // ----------------------------------------------------------------------------------------------------------------
    // Handlers
    // ----------------------------------------------------------------------------------------------------------------
    function handleContactSearchCardOnAddNew(e, contactData) {
        //console.log("ContactSearchView.handleContactSearchCardOnAddNew:contactData", contactData);
        e.preventDefault();
        setContactToAdd(contactData);
        toggleOpenAddContactCard() // Display AddContactCard 
    }

    function handleContactSearchCardOnCancel(e) {
        //console.log("ContactSearchView.handleContactSearchCardOnCancel:");
        e.preventDefault();
        onCancel()
    }

    function handleAddContactCardOnSave(entityContact) {
        //console.log("ContactSearchView.handleAddContactCardOnSave:entityContact", entityContact);
        if (!isNil(entityContact)) {
            toggleOpenAddContactCard() // Hide AddContactCard
            onAddNew(entityContact)
        }
    }

    function handleAddContactCardOnCancel(e) {
        //console.log("ContactSearchView.handleAddContactCardOnCancel");
        e.preventDefault();
        toggleOpenAddContactCard()
    }

    function handleContactSearchCardOnSelect(e, entityContact) {
        //console.log("ContactSearchView.handleContactSearchCardOnSelect:entityContact", entityContact);
        e.preventDefault();
        onSelect(entityContact)
    }

    // ----------------------------------------------------------------------------------------------------------------
    // Toggles
    // ----------------------------------------------------------------------------------------------------------------
    const toggleOpenAddContactCard = useCallback(() => {
        setOpenAddContactCard(!openAddContactCard);
    }, [openAddContactCard])

    // ----------------------------------------------------------------------------------------------------------------
    // Rendering view
    // ----------------------------------------------------------------------------------------------------------------
    return (
        <div>
            {(openAddContactCard)
                ?
                <AddContactCard contactinfo={{ contact: { ...contactToAdd } }}
                    onSave={handleAddContactCardOnSave}
                    onCancel={handleAddContactCardOnCancel}
                />
                :
                <ContactSearchCard
                    onSelect={handleContactSearchCardOnSelect}
                    onAddNew={handleContactSearchCardOnAddNew}
                    onCancel={handleContactSearchCardOnCancel}
                    displayCancelButton={displayCancelButton}
                />
            }
        </div>
    );

}

ContactSearchView.defaultProps = {
    displayCancelButton: false,
    onSave: function () { alert("onSave not implemented") },
    onSelect: function () { alert("onSelect not implemented") },
    onAddNew: function () { alert("onAddNew not implemented") },
    onCancel: function () { alert("onCancel not implemented") },
}

ContactSearchView.propTypes = {
    t: PropTypes.any,
    onSelect: PropTypes.func,
    onAddNew: PropTypes.func,
    onCancel: PropTypes.func,
    displayCancelButton: PropTypes.bool,
}

export default withTranslation()(ContactSearchView)
