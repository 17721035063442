import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button } from "reactstrap"

import MandateTypeCard from "./SettingsMandateTypeCard"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsMandateTypeTableRow(props) {
  const { row, options, onSave, onDelete } = props

  const selectedValues = options.filter(option => {
    return row.tasks?.find(task => {
      return option.id === task
    })
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  const [openMandateTypeForm, setOpenMandateTypeForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMandateTypeCardOnSave(mandateData) {
    //console.log("MandateTypeTableRow.handleMandateTypeCardOnSave:mandateData", mandateData);
    toggleMandateTypeForm() // close form
    onSave(mandateData)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMandateTypeForm = useCallback(() => {
    setOpenMandateTypeForm(!openMandateTypeForm)
  }, [openMandateTypeForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={row.id}>
      {!openMandateTypeForm && (
        <React.Fragment>
          <td className="align-middle">{row.name}</td>
          <td className="align-middle">
            {selectedValues.map(item => (
              <span key={item.id}> {item.label}, </span>
            ))}
          </td>
          <td className="align-middle">{row.order}</td>
          <td className="align-middle">{row.cost.toFixed(2)}</td>
          <td>
            <Button className="p-1 m-1" outline size="sm" onClick={toggleMandateTypeForm}>
              {props.t("Common.Action.Edit")}
            </Button>
            <Button className="p-1 m-1" outline size="sm" onClick={() => onDelete(row)}>
              {props.t("Common.Action.Delete")}
            </Button>
          </td>
        </React.Fragment>
      )}
      {openMandateTypeForm && (
        <td colSpan={5}>
          <MandateTypeCard mandateTypeInfo={row} options={options} selectedValues={selectedValues} onSave={handleMandateTypeCardOnSave} onCancel={toggleMandateTypeForm} />
        </td>
      )}
    </tr>
  )
}

SettingsMandateTypeTableRow.propTypes = {
  t: PropTypes.any,
  props: PropTypes.any,
  row: PropTypes.any,
  options: PropTypes.array,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(SettingsMandateTypeTableRow))
