import React from "react"
import PropTypes from "prop-types"

import { Route, Switch, withRouter } from "react-router-dom"

import RequestsMasterPage from "./master/RequestsMasterPage"
import RequestDetailPage from "./detail/RequestDetailPage"
import Page404 from "components/Page404/Page404"

// Component
function RequestsRouter(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/`} component={RequestsMasterPage}/>
      <Route exact path={`${props.match.path}/:requestId`} component={RequestDetailPage}/>
      <Route exact from={`${props.match.path}/:requestId/:Id`} component={Page404}/>
    </Switch>
  )
}

RequestsRouter.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(RequestsRouter)
