import React from "react"
import PropTypes from "prop-types"

import { Route, Switch, withRouter } from "react-router-dom"

import InvoicesMasterPage from "./master/InvoicesMasterPage"
import InvoiceDetailPage from "./detail/InvoiceDetailPage"
import Page404 from "components/Page404/Page404"

// Component
function InvoicesRouter(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/`} component={InvoicesMasterPage} />
      <Route exact path={`${props.match.path}/:invoiceId`} component={InvoiceDetailPage} />
      <Route exact from={`${props.match.path}/:invoiceId/:Id`} component={Page404}/>
    </Switch>
  )
};

InvoicesRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(InvoicesRouter);
