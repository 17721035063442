import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button } from "reactstrap"

import TaskTypeCard from "./SettingsTaskTypeCard"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsTaskTypeTableRow(props) {
  const { row, onSave, onDelete } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openTaskTypeForm, setOpenTaskTypeForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleTaskTypeCardOnSave(taskData) {
    //console.log("TaskTypeTableRow.handleTaskTypeCardOnSave:taskData", taskData);
    toggleTaskTypeForm() // close form
    onSave(taskData)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleTaskTypeForm = useCallback(() => {
    setOpenTaskTypeForm(!openTaskTypeForm)
  }, [openTaskTypeForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={row.id}>
      {!openTaskTypeForm && (
        <React.Fragment>
          <td className="align-middle" style={{ width: "75%" }}>
            {row.name}
          </td>
          <td className="align-middle" style={{ width: "10%" }}>
            {row.order}
          </td>
          <td>
            <Button className="p-1 m-1" outline size="sm" onClick={toggleTaskTypeForm}>
              {props.t("Common.Action.Edit")}
            </Button>
            <Button className="p-1 m-1" outline size="sm" onClick={() => onDelete(row)}>
              {props.t("Common.Action.Delete")}
            </Button>
          </td>
        </React.Fragment>
      )}
      {openTaskTypeForm && (
        <td colSpan={3}>
          <TaskTypeCard taskTypeInfo={row} onSave={handleTaskTypeCardOnSave} onCancel={toggleTaskTypeForm} />
        </td>
      )}
    </tr>
  )
}

SettingsTaskTypeTableRow.propTypes = {
  t: PropTypes.any,
  props: PropTypes.any,
  row: PropTypes.any,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(SettingsTaskTypeTableRow))
