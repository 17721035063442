import React, { useState } from "react"
import PropTypes from "prop-types"

import Select from "react-dropdown-select"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap"
import { Formik } from "formik"
import { isEmpty, isNil } from "lodash"
import * as Yup from "yup"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsMandateTypeCard(props) {
  const { mandateTypeInfo, options, selectedValues, onSave, onCancel } = props

  const noDataRenderer = () => {
    return <p style={{ textAlign: "center" }}>{props.t("Page.Settings.MandateTypesView.Tasks.NoData")}</p>
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card color="info" className="border-5" outline>
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={{
            name: isEmpty(mandateTypeInfo?.name) ? "" : mandateTypeInfo.name,
            order: isNil(mandateTypeInfo?.order) ? 0 : mandateTypeInfo.order,
            cost: isNil(mandateTypeInfo?.cost) ? 0 : mandateTypeInfo.cost,
            tasks: selectedValues,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(props.t("Page.Settings.MandateTypesView.Name.Validation.Required")),
            cost: Yup.number().min(0, props.t("Page.Settings.MandateTypesView.UnitPrice.Validation.MinValue")).required(props.t("Page.Settings.MandateTypesView.UnitPrice.Validation.Required")),
          })}
          onSubmit={values => {
            //alert(JSON.stringify(values, null, 2))
            const newMandate = {
              name: isEmpty(values["name"]) ? "" : values["name"],
              cost: isNil(values["cost"]) ? 0 : values["cost"],
              tasks: values["tasks"],
              order: isNil(values["order"]) ? 0 : values["order"],
            }
            let updateTasks = []
            newMandate.tasks.map(t => updateTasks.push(t.id))

            mandateTypeInfo.name = newMandate.name
            mandateTypeInfo.cost = newMandate.cost
            mandateTypeInfo.tasks = updateTasks
            mandateTypeInfo.order = newMandate.order
            if (onSave) {
              onSave(mandateTypeInfo)
            }
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup floating>
                <Input
                  id="inputName"
                  name="name"
                  //placeholder="Name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  invalid={formik.touched.name && formik.errors.name ? true : false}
                />
                {formik.touched.name && formik.errors.name ? <div className="text-danger font-size-10 mt-1">{formik.errors.name}</div> : null}
                <Label for="inputName">{props.t("Page.Settings.MandateTypesView.Name")}</Label>
              </FormGroup>
              <FormGroup floating>
                <Input id="inputCost" name="cost" type="number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.cost} invalid={formik.touched.cost && formik.errors.cost ? true : false} />
                {formik.touched.cost && formik.errors.cost ? <div className="text-danger font-size-10 mt-1">{formik.errors.cost}</div> : null}
                <Label for="inputCost">{props.t("Page.Settings.MandateTypesView.UnitPrice")}</Label>
              </FormGroup>
              <Label>{props.t("Page.Settings.MandateTypesView.Tasks")}</Label>
              <FormGroup>
                <Select
                  multi
                  name="tasks"
                  clearable
                  //closeOnSelect // FONCTIONNE PAS AVEC L'ATTRIBUT PORTAL
                  //contentRenderer={customContentRenderer} // FINALEMENT JE PREFERE VOIR LES OPTIONS SELECTIONNEES
                  sortBy="createdAt"
                  values={selectedValues}
                  options={options}
                  onChange={values => formik.setFieldValue("tasks", values)}
                  noDataLabel={noDataRenderer()}
                  portal={typeof document !== `undefined` && document.body}
                />
              </FormGroup>
              <FormGroup floating>
                <Input
                  id="inputOrder"
                  name="order"
                  //placeholder="Order"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.order}
                  invalid={formik.touched.order && formik.errors.order ? true : false}
                />
                {formik.touched.order && formik.errors.order ? <div className="text-danger font-size-10 mt-1">{formik.errors.order}</div> : null}
                <Label for="inputOrder">{props.t("Page.Settings.MandateTypesView.Order")}</Label>
              </FormGroup>
              <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">
                {props.t("Common.Action.Save")}
              </Button>
              <Button
                className="me-2"
                color="secondary"
                outline
                onClick={() => {
                  onCancel()
                }}
              >
                {props.t("Common.Action.Cancel")}
              </Button>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

SettingsMandateTypeCard.propTypes = {
  t: PropTypes.any,
  mandateTypeInfo: PropTypes.any,
  options: PropTypes.array,
  selectedValues: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(withTranslation()(SettingsMandateTypeCard))
