import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import Moment from "react-moment"
import { useAlert } from "react-alert"
import { withRouter } from "react-router-dom"
import { Button, CardLink, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import { dateDiffInDaysFromNowWithoutTime } from "helpers/lea-date_helper"
import { getOptionById } from "helpers/lea-options_helper"
import { mandateStatusClasses } from "helpers/lea-status_helper"
import { URL_MANDATES } from "helpers/lea-url_helper"
import { MandateStatus } from "models"

// i18n
import { withTranslation } from "react-i18next"
import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"

// Component
function DesktopMandateTableRow(props) {
  const { mandate, optionsMandateTypes  } = props

  // Alert
  const customAlert = useAlert()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleViewMandate(e, mandateId) {
    //console.log("DesktopMandateTableRow.handleViewMandate:mandateId", mandateId);
    e.preventDefault()
    props.history.push(URL_MANDATES + "/" + mandateId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={mandate.id} /*onClick={(e) => handleViewMandate(e, mandate.id)}*/>
      <td valign="middle">
        <Button size="sm" color="secondary" outline onClick={(e) => handleViewMandate(e, mandate.id)}>
          <i className="mdi mdi-eye" />
        </Button>
      </td>
      {/* <td valign="middle">
        {isEmpty(mandate.createdAt) ? ("") : (<Moment format="YYYY-MM-DD HH:mm">{mandate.createdAt}</Moment> )}
      </td> */}
      <td valign="middle">
        <span className={ "badge rounded-pill badge-soft-" + mandateStatusClasses[mandate.status] }>
          {props.t("Common.Enum.MandateStatus." + (mandate.retired ? "RETIRED" : mandate.status) )}
        </span>
      </td>
      <td valign="middle">
        {getOptionById(optionsMandateTypes, mandate.mandateType).name}
      </td>
      <td valign="middle">
        -
      </td>
      <td valign="middle">
        -
      </td>
      {/* <td valign="middle">  
        {isEmpty(mandate?.location) 
          ? 
          <div className="mb-0 text-danger">
            <i className="mdi mdi-alert-outline me-1" />
            {props.t("Page.MandateDetail.LocationView.Alert")}
          </div>
          : 
          <div>
            <p className="mb-0">
              {mandate.location?.address}
            </p>
            <p className="mb-0">
              {mandate.location?.city} {mandate.location?.stateOrProvince}
            </p>
            <p className="mb-0">
              {mandate.location?.zipOrPostalCode}
            </p>
          </div>
        }
      </td>
      <td valign="middle">
        {!isEmpty(mandate?.location) &&
          <p className="mb-0">
            {mandate.location.lotNumber}
          </p>
        }
      </td> */}
      <td valign="middle">
        {isEmpty(mandate.primaryContact)
          ?
          <div className="mb-0 text-danger">
            <i className="mdi mdi-alert-outline me-1" />
            {props.t("Common.Text.Alert.PrimaryContact")}
          </div>
          :
          <div>
            <p className="mb-0">
              {mandate.primaryContact?.fullName}
            </p>
            <p className="mb-0">
              <a href={"mailto:" + mandate.primaryContact?.email}>{mandate.primaryContact?.email}</a>
            </p>
            <p className="mb-0">
              <a href={"tel:" + mandate.primaryContact?.phoneNumber}>{formatPhoneNumberAsLocal(mandate.primaryContact?.phoneNumber)}</a>
            </p>
          </div>
      }
      </td>
      <td className="text-end" valign="middle">
        {mandate.status !== MandateStatus.COMPLETED && (
          <div>{'-'/*dateDiffInDaysFromNowWithoutTime(mandate.createdAt)*/}</div> // TODO : calculer par rapport à la date de livraison estimée
        )}
      </td>
    </tr>
  )
}

DesktopMandateTableRow.propTypes = {
  t: PropTypes.any,
  mandate: PropTypes.any,
  history: PropTypes.any,
  optionsMandateTypes: PropTypes.array,
}

export default withRouter(withTranslation()(DesktopMandateTableRow))
