import React from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from "reactstrap"
import { Formik } from "formik"
import { isEmpty, isNull } from "lodash"
import * as Yup from "yup"

//i18n
import { withTranslation } from "react-i18next"

// Component
function SettingsTaskTypeCard(props) {
  const { taskTypeInfo, onSave, onCancel } = props

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card outline color="info" className="border-5">
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={{
            name: isEmpty(taskTypeInfo?.name) ? "" : taskTypeInfo.name,
            order: isNull(taskTypeInfo.order) ? 0 : taskTypeInfo.order,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(props.t("Page.Settings.TaskTypesView.Name.Validation.Required")),
          })}
          onSubmit={values => {
            //alert(JSON.stringify(values, null, 2))
            const newTask = {
              name: isEmpty(values["name"]) ? "" : values["name"],
              order: isNull(values["order"]) ? 0 : values["order"],
            }
            taskTypeInfo.name = newTask.name
            taskTypeInfo.order = newTask.order
            if (onSave) {
              onSave(taskTypeInfo)
            }
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup floating>
                <Input
                  id="inputName"
                  name="name"
                  //placeholder="Name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  invalid={formik.touched.name && formik.errors.name ? true : false}
                />
                {formik.touched.name && formik.errors.name ? <div className="text-danger font-size-10 mt-1">{formik.errors.name}</div> : null}
                <Label for="inputName">{props.t("Page.Settings.TaskTypesView.Name")}</Label>
              </FormGroup>
              <FormGroup floating>
                <Input
                  id="inputOrder"
                  name="order"
                  //placeholder="Order"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.order}
                  invalid={formik.touched.order && formik.errors.order ? true : false}
                />
                {formik.touched.order && formik.errors.order ? <div className="text-danger font-size-10 mt-1">{formik.errors.order}</div> : null}
                <Label for="inputOrder">{props.t("Page.Settings.TaskTypesView.Order")}</Label>
              </FormGroup>
              <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">
                {props.t("Common.Action.Save")}
              </Button>
              <Button
                className="me-2"
                color="secondary"
                outline
                onClick={() => {
                  onCancel()
                }}
              >
                {props.t("Common.Action.Cancel")}
              </Button>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

SettingsTaskTypeCard.propTypes = {
  t: PropTypes.any,
  taskTypeInfo: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(withTranslation()(SettingsTaskTypeCard))
