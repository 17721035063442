import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { useQuery, useQueryClient } from "react-query"
import { withRouter } from "react-router-dom"
import { Button, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import { URL_REQUESTS } from "helpers/lea-url_helper"
import { requestStatusClasses } from "helpers/lea-status_helper"

import { callAPI } from "helpers/lea-graphql_helper"
import { getRequest } from "graphql/queries"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"

// Component
function ContactDetailRequestTableRow(props) {
  const { requestContact, optionsRequestTypes } = props

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [request, setRequest] = useState({})

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get list of request contact
  const { isError, isIdle, isLoading, isFetching, error } = useQuery("getRequest-" + requestContact.requestId, () => firstValueFrom(callAPI("getRequest", getRequest, { id: requestContact.requestId })), {
    retry: false,
    onSuccess: result => {
      //console.log("ContactDetailPage.RequestContactTableRow.getRequest.onSuccess:result", result)
      setRequest(isEmpty(result) ? {} : result)
    },
    onError: error => {
      console.error("ContactDetailPage.RequestContactTableRow.getRequest.onError:error", error)
      customAlert.error(
        props.t("Common.Text.Alert.Error", {
          0: error.errors[0].message,
        }),
        { timeout: 0 }
      )
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  const handleViewRequest = (e, requestId) => {
    //console.log("ContactDetailPage.RequestContactTableRow.handleViewRequest:requestId", requestId);
    e.preventDefault()
    props.history.push(URL_REQUESTS + "/" + requestId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Fragment>
      {(isLoading || isFetching) && 
        <tr key={requestContact.id}>
          <td valign="middle">
            <Spinner className="ms-2" color="primary" />
          </td>
        </tr>
      }
      {(!isLoading && !isFetching && !isError) &&
        <tr key={requestContact.id}>
          <td className="no-print" valign="middle" width="10%">
            <Button className="me-2" size="sm" color="secondary" outline onClick={e => handleViewRequest(e, requestContact.requestId)}>
              <i className="mdi mdi-eye" />
            </Button>
          </td>
          <td valign="middle">
            <span className={"badge rounded-pill badge-soft-" + requestStatusClasses[request.status]}>
              {request.retired && <s>{props.t("Common.Enum.RequestStatus." + request.status)}</s>}
              {!request.retired && <>{props.t("Common.Enum.RequestStatus." + request.status)}</>}
            </span>
            {request.retired && <span className={"badge rounded-pill badge-soft-" + requestStatusClasses["RETIRED"]}>{props.t("Common.Enum.RequestStatus." + (request.retired ? "RETIRED" : request.status))}</span>}
          </td>
          <td className="text-end" valign="middle" style={{ fontFamily: "monospace" }}>
            {request.id}
          </td>
        </tr>
      }
    </Fragment>
  )
}

ContactDetailRequestTableRow.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  requestContact: PropTypes.any,
  optionsRequestTypes: PropTypes.array,
}

export default withRouter(withTranslation()(ContactDetailRequestTableRow))
