import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { useAlert } from "react-alert"
import { useParams, withRouter } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Col, Container, Row, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import TaskDetailSummaryView from "./views/summary/TaskDetailSummaryView"
import Row404 from "components/Page404/Row404"

import { useCurrentUser } from "contexts/current-user-context"
import { URL_MANDATES } from "helpers/lea-url_helper"

import { callAPI, callAPI2, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { getTaskDetail } from "./graphql/queries"
import { updateTask } from "graphql/mutations"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"
import TaskDetailNotesView from "./views/notes/TaskDetailNotesView"

// Component
function TaskDetailPage(props) {
  // Get task id from the current URL (query param)
  const { taskId } = useParams()

  // Alert
  const customAlert = useAlert()

  // Query client
  const queryClient = useQueryClient()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [task, setTask] = useState()
  const [users, setUsers] = useState([])
  const [optionsTaskType, setOptionsTaskType] = useState([])

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  const { isError, isIdle, isLoading, isFetching, error } = useQuery(
    "getTaskDetail-" + taskId,
    () =>
      firstValueFrom(
        callAPI2("getTaskDetail", getTaskDetail, {
          id: taskId,
          /* EXAMPLE
            filterUser : { userStatus: { eq: UserStatus.ACTIVE } },
            filterSettingsTask: { xxx: { eq: ??? } },
            */
          limit: 1000,
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("TaskDetailPage.getTaskDetail.onSuccess:result", result)
        setTask(isEmpty(result?.getTask) ? {} : result.getTask)
        let optionsUsers = []
        if (!isEmpty(result?.listUsers?.items)) {
          result?.listUsers?.items.map(row => optionsUsers.push({ cognitoId: row.cognitoId, value: row.cognitoId, label: isEmpty(row.username) ? row.email : row.username, email: row.email, disabled: row.retired, username: row.username }))
        }
        setUsers(optionsUsers)
        setOptionsTaskType(isEmpty(result?.listSettingsTaskTypes?.items) ? [] : result.listSettingsTaskTypes.items)
      },
      onError: error => {
        console.error("TaskDetailPage.getTaskDetail.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }), { timeout: 0 })
      },
    }
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleTaskViewOnDelete(entityTask) {
    //console.log("TaskDetailPage.handleTaskViewOnDelete:entityTask", entityTask);
    doDeleteTask(entityTask)
  }

  function handleTaskViewOnUpdate(entityTask) {
    //console.log("TaskDetailPage.handleTaskViewOnUpdate:entityTask", entityTask);
    doUpdateTask(entityTask)
  }

  function handleTaskDetailViewOnUpdate(entityTask) {
    //console.log("TaskDetailPage.handleTaskDetailViewOnUpdate:entityTask", entityTask);
    doUpdateTask(entityTask)
  }

  function doDeleteTask(entityTask) {
    //console.log("TaskDetailPage.doDeleteTask:entityTask", entityTask);
    const input = {
      ...entityTask,
      updateUser: username,
      retired: true,
    }
    //console.log("TaskDetailPage.doDeleteTask:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("TaskDetailPage.doDeleteTask:updateInput", updateInput)
    updateTaskMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("TaskDetailPage.doDeleteTask.updateTaskMutation.onSuccess:result", result)
        queryClient.invalidateQueries("getMandateDetail-" + task.mandateId)
        props.history.push(URL_MANDATES + "/" + task.mandateId)
      },
      onError: error => {
        console.error("TaskDetailPage.doDeleteTask.updateTaskMutation.onError:error", error)
        queryClient.invalidateQueries("getTaskDetail-" + taskId)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  function doUpdateTask(entityTask) {
    //console.log("TaskDetailPage.doUpdateTask:entityTask", entityTask);
    const input = {
      ...entityTask,
      updateUser: username,
    }
    //console.log("TaskDetailPage.doUpdateTask:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("TaskDetailPage.doUpdateTask:updateInput", updateInput)
    updateTaskMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("TaskDetailPage.doUpdateTask.updateTaskMutation.onSuccess:result", result)
        setTask(result)
      },
      onError: error => {
        console.error("TaskDetailPage.doUpdateTask.updateTaskMutation.onError:error", error)
        queryClient.invalidateQueries("getTaskDetail-" + taskId)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const updateTaskMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateTask", updateTask, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Task</title>
      </MetaTags>
      <Container fluid={true}>
        {(isLoading || isFetching) && <Spinner className="ms-2" color="primary" />}
        {(!isLoading && !isFetching && !isError) && 
          <React.Fragment>
            {!isEmpty(task) /*&& task.retired === false*/ ? (
              <Row>
                <Col xs={6}>
                  <TaskDetailSummaryView task={task} optionsTaskType={optionsTaskType} users={users} onDelete={handleTaskViewOnDelete} onUpdate={handleTaskViewOnUpdate} />
                </Col>
                <Col xs={6}>
                  <TaskDetailNotesView task={task} notes={[]} users={users}/>
                </Col>
              </Row>
            ) : (
              <Row404 />
            )}
          </React.Fragment>
        }
      </Container>
    </div>
  )
}

TaskDetailPage.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  mandates: PropTypes.any,
}

export default withRouter(withTranslation()(TaskDetailPage))
