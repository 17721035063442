import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import Moment from "react-moment"
import { useAlert } from "react-alert"
import { withRouter } from "react-router-dom"
import { Button, CardLink, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import { dateDiffInDaysFromNowWithoutTime } from "helpers/lea-date_helper"
import { requestStatusClasses } from "helpers/lea-status_helper"
import { URL_REQUESTS } from "helpers/lea-url_helper"
import { RequestStatus } from "models"

// i18n
import { withTranslation } from "react-i18next"
import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"

// Component
function DesktopRequestTableRow(props) {

  const { request } = props

  // Alert
  const customAlert = useAlert()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleViewRequest(e, requestId) {
    //console.log("DesktopRequestTableRow.handleViewRequest:requestId", requestId);
    e.preventDefault()
    props.history.push(URL_REQUESTS + "/" + requestId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={request.id} /*onClick={(e) => handleViewRequest(e, request.id)}*/>
      {/* <td valign="middle">
        {isEmpty(request.createdAt) ? ("") : (<Moment format="YYYY-MM-DD HH:mm">{request.createdAt}</Moment> )}
      </td> */}
      <td valign="middle">
        <Button size="sm" color="secondary" outline onClick={(e) => handleViewRequest(e, request.id)}>
          <i className="mdi mdi-eye" />
        </Button>
      </td>
      <td valign="middle">
        <span className={ "badge rounded-pill badge-soft-" + requestStatusClasses[request.status] }>
          {props.t("Common.Enum.RequestStatus." + (request.retired ? "RETIRED" : request.status) )}
        </span>
      </td>
      <td valign="middle">
        {isEmpty(request.primaryContact)
          ?
          <div className="mb-0 text-danger">
            <i className="mdi mdi-alert-outline me-1" />
            {props.t("Common.Text.Alert.PrimaryContact")}
          </div>
          :
          <div>
            <p className="mb-0">
              {request.primaryContact?.fullName}
            </p>
            <p className="mb-0">
              <a href={"mailto:" + request.primaryContact?.email}>{ request.primaryContact?.email }</a>
              {/* {request.primaryContact?.email} */}
            </p>
            <p className="mb-0">
              <a href={"tel:" + request.primaryContact?.phoneNumber}>{formatPhoneNumberAsLocal(request.primaryContact?.phoneNumber)}</a>
              {/* {formatPhoneNumberAsLocal(request.primaryContact?.phoneNumber)} */}
            </p>
          </div>
      }
      </td>
      {/* <td valign="middle" className="text-sm-end">
        {request.status !== RequestStatus.COMPLETED && (
          <div>{dateDiffInDaysFromNowWithoutTime(request.createdAt)}</div>
        )}
      </td> */}
    </tr>
  )
}

DesktopRequestTableRow.propTypes = {
  t: PropTypes.any,
  request: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(DesktopRequestTableRow))
