import React from "react"
import PropTypes from "prop-types"

import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import { URL_PROFILE, URL_NOTIFICATIONS } from "helpers/lea-url_helper"

import UserProfilePage from "./profile/UserProfilePage"
import UserNotificationsPage from "./notifications/UserNotificationsPage"
import Page404 from "components/Page404/Page404"

// Component
function UserRouter(props) {
  return (
    <Switch>
      <Route exact from={`${props.match.path}/:Id`} component={Page404} />
      <Route exact path={URL_PROFILE} component={UserProfilePage} />
      <Route exact path={URL_NOTIFICATIONS} component={UserNotificationsPage} />
    </Switch>
  )
}

UserRouter.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(UserRouter)
