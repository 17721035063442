export const getInvoiceDetail = /* GraphQL */ `
  query getInvoiceDetail($id: ID!, $filterUser: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    getInvoice(id: $id) {
      _deleted
      _version
      billFrom {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        stateOrProvince
        zipOrPostalCode
      }
      billTo {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        stateOrProvince
        zipOrPostalCode
      }
      logo {
        filename
        id
        linkedTo {
          id
          type
        }
        objectKey
      }
      createdAt
      creator
      currencyCode
      discountAmount
      dueDate
      id
      isEstimate
      issueDate
      lines {
        amount
        description
        id
        isTaxed
        isTaxed2
        tax2Amount
        taxAmount
        mandateId
        quantity
        unitPrice
      }
      notes
      paidDate
      paymentMethod
      paymentTerm
      requestId
      retired
      sentDate
      status
      subject
      subtotalAmount
      tax
      tax2
      tax2Amount
      taxAmount
      taxDiscount
      totalAmount
      updatedAt
    },
    listUsers(filter: $filterUser, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        email
        id
        userStatus
        updatedAt
        tenantId
        cognitoId
        username
      }
      nextToken
      startedAt
    }
  }
`;
