import React from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Table } from "reactstrap"
import { Formik, Field, FieldArray, ErrorMessage } from "formik"
import { isEmpty, isNil } from "lodash"
import TextareaAutosize from "@mui/base/TextareaAutosize"
import * as Yup from "yup"

// i18n
import { withTranslation } from "react-i18next"

// Component
function InvoiceDetailInvoiceViewEdit(props) {
  const { invoice, onCancel, onSave } = props

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Formik
      enableReinitialize
      initialValues={{
        //invoiceId: isEmpty(invoice.id) ? "" : invoice.id,
        invoiceNumber: isEmpty(invoice?.invoiceNumber) ? "" : invoice.invoiceNumber,
        subject: isEmpty(invoice.subject) ? "" : invoice.subject,
        // FORMAT YYYY-MM-DDTHH:MM POUR LE CONTROLE <Input type='datetime-local'>
        // FORMAT YYYY-MM-DD       POUR LE CONTROLE <Input type='date'>
        //issueDate: isEmpty(invoice.issueDate) ? "" : moment(new Date(invoice.issueDate)).format('YYYY-MM-DDTHH:mm'),
        //dueDate: isEmpty(invoice.dueDate) ? "" : moment(new Date(invoice.dueDate)).format('YYYY-MM-DDTHH:mm'),
        dueDate: isEmpty(invoice.dueDate) ? "" : moment(new Date(invoice.dueDate)).format("YYYY-MM-DD"),
        tax: isNil(invoice.tax) ? 0 : invoice.tax,
        tax2: isNil(invoice.tax2) ? 0 : invoice.tax2,
        lineItems: isEmpty(invoice?.lines) ? [] : invoice.lines,
        notes: isEmpty(invoice?.notes) ? "" : invoice.notes,
      }}
      validationSchema={Yup.object({
        //subject: Yup.string().required(),
        tax: Yup.number().min(0, props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Tax.Validation.Min")).required(props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Tax.Validation.Required")),
        tax2: Yup.number().min(0, props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Tax2.Validation.Min")).required(props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Tax.Validation.Required")),
        lineItems: Yup.array().of(
          Yup.object().shape({
            description: Yup.string().required(props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Description.Validation.Required")),
            quantity: Yup.number().required(props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Quantity.Validation.Required")).positive(props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Quantity.Validation.Positive")),
            unitPrice: Yup.number().required(props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.UnitPrice.Validation.Required")).positive(props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.UnitPrice.Validation.Positive")),
          })
        ),
      })}
      onSubmit={values => {
        //console.log("values", JSON.stringify(values, null, 2))
        //console.log("invoice before update", JSON.stringify(invoice, null, 2))

        //invoice.invoiceNumber = values["invoiceNumber"]
        //invoice.issueDate = new Date(values["issueDate"]).toJSON() // Convert to UTC
        //invoice.dueDate = new Date(values["dueDate"]).toJSON()     // Convert to UTC
        invoice.dueDate = new Date(values["dueDate"]).toISOString().split("T")[0] // Convert to UTC
        invoice.subject = values["subject"]
        invoice.notes = values["notes"]
        invoice.tax = values["tax"]
        invoice.tax2 = values["tax2"]

        // Updtate amount
        invoice.lines = values["lineItems"]
        invoice.lines.map(line => {
          return (line.amount = line.quantity * line.unitPrice)
        })

        /*
                console.log("invoice.tax ", invoice.tax.toFixed(2))
                console.log("invoice.tax2", invoice.tax2.toFixed(3))
                */
        let subtotalAmount = 0.0
        subtotalAmount = invoice.lines.reduce((cumul, line) => line.amount + cumul, 0)
        invoice.subtotalAmount = subtotalAmount

        let subTotalTax1 = 0.0
        subTotalTax1 = invoice.lines.reduce((cumul, line) => (line.isTaxed ? line.amount * invoice.tax + cumul : cumul), 0)
        invoice.taxAmount = subTotalTax1 / 100

        let subTotalTax2 = 0.0
        subTotalTax2 = invoice.lines.reduce((cumul, line) => (line.isTaxed2 ? line.amount * invoice.tax2 + cumul : cumul), 0)
        invoice.tax2Amount = subTotalTax2 / 100

        let discountAmount = 0.0
        invoice.discountAmount = discountAmount

        // Total amount
        let total = invoice.subtotalAmount + invoice.taxAmount + invoice.tax2Amount + invoice.discountAmount
        invoice.totalAmount = total

        /* 
                console.log("subtotalAmount", invoice.subtotalAmount)
                console.log("totalAmount", invoice.taxAmount)
                console.log("totalAmount", invoice.tax2Amount)
                console.log("totalAmount", invoice.totalAmount)
                */

        //console.log("invoice after update", JSON.stringify(invoice, null, 2))
        onSave(invoice)
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
          <Card>
            <CardBody>
              <Row>
                <div className="mt-1">
                  <h4>
                    <strong>{props.t("Page.InvoiceDetail.EditInvoiceView")}</strong>
                  </h4>
                  <hr></hr>
                </div>
              </Row>
              <Row>
                <Col xs={6}>
                  <div>
                    <FormGroup row>
                      <Label sm="4" for="inputInvoiceId">
                        {props.t("Common.Label.Invoice.InvoiceNumber")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputInvoiceId" name="invoiceNumber" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.invoiceNumber} invalid={formik.touched.invoiceNumber && formik.errors.invoiceNumber ? true : false} />
                        {formik.touched.invoiceNumber && formik.errors.invoiceNumber ? <FormFeedback type="invalid">{formik.errors.invoiceNumber}</FormFeedback> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="4" for="inputDueDate">
                        {props.t("Common.Label.Date.DueDate")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputDueDate" name="dueDate" type="date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dueDate} invalid={formik.touched.dueDate && formik.errors.dueDate ? true : false} />
                        {formik.touched.dueDate && formik.errors.dueDate ? <FormFeedback type="invalid">{formik.errors.dueDate}</FormFeedback> : null}
                      </Col>
                    </FormGroup>
                  </div>
                </Col>
                <Col xs={6}>
                  <div>
                    <FormGroup row>
                      <Label sm="4" for="inputTax" className="text-end">
                        {props.t("Page.InvoiceDetail.EditInvoiceView.Tax")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputTax" name="tax" type="number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.tax} invalid={formik.touched.tax && formik.errors.tax ? true : false} />
                        {formik.touched.tax && formik.errors.tax ? <FormFeedback type="invalid">{formik.errors.tax}</FormFeedback> : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm="4" for="inputTax2" className="text-end">
                        {props.t("Page.InvoiceDetail.EditInvoiceView.Tax2")}
                      </Label>
                      <Col sm="8">
                        <Input id="inputTax2" name="tax2" type="number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.tax2} invalid={formik.touched.tax2 && formik.errors.tax2 ? true : false} />
                        {formik.touched.tax2 && formik.errors.tax2 ? <FormFeedback type="invalid">{formik.errors.tax2}</FormFeedback> : null}
                      </Col>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div>
                    <FormGroup row>
                      <Label sm="2" for="inputSubject">
                        {props.t("Page.InvoiceDetail.EditInvoiceView.Subject")}
                      </Label>
                      <Col sm="10">
                        <Input id="inputSubject" name="subject" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.subject} invalid={formik.touched.subject && formik.errors.subject ? true : false} />
                        {formik.touched.subject && formik.errors.subject ? <FormFeedback type="invalid">{formik.errors.subject}</FormFeedback> : null}
                      </Col>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <div className="table-responsive">
                    <Table size="sm" className="table-nowrap">
                      <thead>
                        <tr>
                          {/*<th className="text-start" style={{ width: "70px" }}>No.</th>*/}
                          <th>{props.t("Common.Label.Action")}</th>
                          <th>{props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Description")}</th>
                          <th>{props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Quantity")}</th>
                          <th>{props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.UnitPrice")}</th>
                          <th>{props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Tax", { 0: invoice.tax.toFixed(2) })}</th>
                          <th>{props.t("Page.InvoiceDetail.EditInvoiceView.Table.Field.Tax2", { 0: invoice.tax2.toFixed(3) })}</th>
                          <th className="text-end">{props.t("Common.Label.Amount")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <FieldArray
                          name="lineItems"
                          render={arrayHelpers => (
                            <React.Fragment>
                              {formik.values.lineItems && formik.values.lineItems.length > 0 ? (
                                formik.values.lineItems.map((lineItem, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Button size="sm" color="danger" outline onClick={() => arrayHelpers.remove(index)}>
                                        <i className="bx bxs-trash" />
                                      </Button>
                                    </td>
                                    <td>
                                      <FormGroup>
                                        <Field
                                          //bsSize="sm"
                                          //tag={Field}
                                          rows={3}
                                          cols={60}
                                          name={`lineItems[${index}].description`}
                                          as="textarea"
                                          //disabled={!isNil(formik.values.lineItems[index].mandateId)}
                                        />
                                        <div className="text-danger font-size-10 mt-1">
                                          <ErrorMessage name={`lineItems[${index}].description`} />
                                        </div>
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <FormGroup>
                                        <Field
                                          //bsSize="sm"
                                          //tag={Field}
                                          size={10}
                                          name={`lineItems[${index}].quantity`}
                                          as="input"
                                        />
                                        <div className="text-danger font-size-10 mt-1">
                                          <ErrorMessage name={`lineItems[${index}].quantity`} />
                                        </div>
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <FormGroup>
                                        <Field
                                          //bsSize="sm"
                                          //tag={Field}
                                          size={10}
                                          name={`lineItems[${index}].unitPrice`}
                                          as="input"
                                        />
                                        <div className="text-danger font-size-10 mt-1">
                                          <ErrorMessage name={`lineItems[${index}].unitPrice`} />
                                        </div>
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <FormGroup check>
                                        <Input tag={Field} name={`lineItems[${index}].isTaxed`} type="checkbox" />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <FormGroup check>
                                        <Input tag={Field} name={`lineItems[${index}].isTaxed2`} type="checkbox" />
                                      </FormGroup>
                                    </td>
                                    <td className="text-end">{(lineItem.quantity * lineItem.unitPrice).toFixed(2)}</td>
                                  </tr>
                                ))
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                              <tr>
                                <td colSpan={7}>
                                  <Button
                                    size="sm"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        amount: 0,
                                        description: "",
                                        id: formik.values.lineItems.length > 0 ? formik.values.lineItems.length + 1 : 1,
                                        isTaxed: true,
                                        isTaxed2: true,
                                        mandateId: null,
                                        quantity: 0,
                                        unitPrice: 0,
                                      })
                                    }
                                  >
                                    <i className="bx bx-add-to-queue" /> {props.t("Common.Action.Add")}
                                  </Button>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        />
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div>
                    <Label for="inputNotes">{props.t("Page.InvoiceDetail.EditInvoiceView.Notes")}</Label>
                    <Col sm="12">
                      <Input id="inputNotes" name="notes" tag={TextareaAutosize} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.notes} invalid={formik.touched.notes && formik.errors.notes ? true : false} />
                      {formik.touched.notes && formik.errors.notes ? <FormFeedback type="invalid">{formik.errors.notes}</FormFeedback> : null}
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <div className="float-start">
                  <Button className="me-2" color="primary" type="submit" disabled={!formik.isValid || !formik.dirty}>
                    {props.t("Common.Action.Save")}
                  </Button>
                  <Button className="me-2" color="secondary" outline onClick={onCancel}>
                    {props.t("Common.Action.Cancel")}
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  )
}

InvoiceDetailInvoiceViewEdit.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  invoice: PropTypes.any,
  onCancel: PropTypes.any,
  onSave: PropTypes.any,
}

export default withRouter(withTranslation()(InvoiceDetailInvoiceViewEdit))
