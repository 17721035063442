import React from "react";
import PropTypes from "prop-types";

import { Route, Switch, withRouter } from "react-router-dom";

import ContactsMasterPage from "./master/ContactsMasterPage";
import ContactDetailPage from "./detail/ContactDetailPage";
import Page404 from "components/Page404/Page404";

// Component
function ContactsRouter(props) {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/`} component={ContactsMasterPage} />
      <Route exact path={`${props.match.path}/:contactId`} component={ContactDetailPage} />
      <Route exact from={`${props.match.path}/:contactId/:Id`} component={Page404}/>
    </Switch>
  )
};

ContactsRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ContactsRouter);


