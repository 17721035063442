import React from "react";
import PropTypes from "prop-types";

import { useAlert } from "react-alert";
import { useMutation } from 'react-query';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { Formik } from "formik";
import { isEmpty, isNil } from "lodash";
import * as Yup from "yup";

import { useCurrentUser } from "contexts/current-user-context";
import { PHONE_NO_REGEX } from "helpers/lea-phonenumber_helper";

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper";
import { createContact } from "graphql/mutations";
import { firstValueFrom } from "rxjs";

// i18n
import { withTranslation } from "react-i18next";

// Component 
function AddContactCard(props) {

    const { contactinfo, onSave, onCancel } = props

    // Alert
    const customAlert = useAlert()

    // Current user
    //const { currentUser: { attributes: { email } } } = useCurrentUser()
    const { currentUser: { username } } = useCurrentUser()

    // ----------------------------------------------------------------------------------------------------------------
    // Handlers
    // ----------------------------------------------------------------------------------------------------------------
    function handleCancelOnClick(e) {
        //console.log("AddContactCard.handleCancelOnClick")
        e.preventDefault();
        onCancel(e)
    }

    async function handleOnSubmit(contactData) {
        //console.log("AddContactCard.handleOnSubmit:contactData", contactData)
        let entityContactNew = await doCreateContact(contactData)
        if (isNil(entityContactNew)) {
            customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de creer le contact" }), { timeout: 0 })
            return
        }
        onSave(entityContactNew)
    }

    async function doCreateContact(contactData) {
        //console.log("AddContactCard.doCreateContact:contactData", contactData);
        const input = {
            ...contactData,
            creator: username,
        }
        //console.log("AddContactCard.doCreateContact:input", input)
        let updateInput = removeGeneratedProperties(input);
        //console.log("AddContactCard.doCreateContact:updateInput", updateInput)
        try {
            const result = await createContactMutation.mutateAsync(updateInput)
            return result
        } catch (error) {
            console.error("AddContactCard.doCreateContact:error", error)
            return null
        }
    }

    const createContactMutation = useMutation(
        (data) => firstValueFrom(callAPI('createContact', createContact, {
            input: {
                ...data
            },
        })),
    );

    // ----------------------------------------------------------------------------------------------------------------
    // Rendering view
    // ----------------------------------------------------------------------------------------------------------------
    return (
        <Card>
            <CardBody>
                <Formik
                    /*enableReinitialize={true}*/
                    initialValues={{
                        /*contactType: isEmpty(contactinfo?.contact?.contactType) ? "PERSON" : `${contactinfo.contact.contactType}`,*/
                        fullName: isEmpty(contactinfo?.contact?.fullName) ? "" : contactinfo?.contact?.fullName,
                        email: isEmpty(contactinfo?.contact?.email) ? "" : contactinfo?.contact?.email,
                        phoneNumber: isEmpty(contactinfo?.contact?.phoneNumber) ? "" : contactinfo?.contact?.phoneNumber,
                        phoneExtension: isEmpty(contactinfo?.contact?.phoneExtension) ? "" : contactinfo?.contact?.phoneExtension,
                        phoneNumber2: isEmpty(contactinfo?.contact?.phoneNumber2) ? "" : contactinfo?.contact?.phoneNumber2,
                        phoneExtension2: isEmpty(contactinfo?.contact?.phoneExtension2) ? "" : contactinfo?.contact?.phoneExtension2,
                        address: isEmpty(contactinfo?.contact?.address) ? "" : contactinfo?.contact?.address,
                        city: isEmpty(contactinfo?.contact?.city) ? "" : contactinfo?.contact?.city,
                        stateOrProvince: isEmpty(contactinfo?.contact?.stateOrProvince) ? "" : contactinfo?.contact?.stateOrProvince,
                        zipOrPostalCode: isEmpty(contactinfo?.contact?.zipOrPostalCode) ? "" : contactinfo?.contact?.zipOrPostalCode,
                    }}
                    validationSchema={Yup.object({
                        fullName: Yup.string().required(props.t("Component.ContactSearch.AddContactCard.Name.Validation")),
                        email: Yup.string().email(props.t("Component.ContactSearch.AddContactCard.Email.Validation")).max(255),
                        phoneNumber: Yup.string().matches(PHONE_NO_REGEX, props.t("Component.ContactSearch.AddContactCard.PhoneNumber.Validation")).nullable(),
                        phoneNumber2: Yup.string().matches(PHONE_NO_REGEX, props.t("Component.ContactSearch.AddContactCard.PhoneNumber2.Validation")).nullable()
                    })}
                    onSubmit={(values) => {
                        //console.log("AddContactCard.onSubmit:values", values);
                        const contactData = {
                            /*contactType: values["contactType"],*/
                            fullName: isEmpty(values["fullName"]) ? null : values["fullName"],
                            email: isEmpty(values["email"]) ? null : values["email"],
                            phoneNumber: isEmpty(values["phoneNumber"]) ? null : values["phoneNumber"],
                            phoneExtension: isEmpty(values["phoneExtension"]) ? null : values["phoneExtension"],
                            phoneNumber2: isEmpty(values["phoneNumber2"]) ? null : values["phoneNumber2"],
                            phoneExtension2: isEmpty(values["phoneExtension2"]) ? null : values["phoneExtension2"],
                            address: isEmpty(values["address"]) ? null : values["address"],
                            city: isEmpty(values["city"]) ? null : values["city"],
                            stateOrProvince: isEmpty(values["stateOrProvince"]) ? null : values["stateOrProvince"],
                            zipOrPostalCode: isEmpty(values["zipOrPostalCode"]) ? null : values["zipOrPostalCode"],
                            // CHAMPS DENORM
                            fullNameDenom: isEmpty(values["fullName"]) ? null : values["fullName"].toLowerCase(),
                            emailDenom: isEmpty(values["email"]) ? null : values["email"].toLowerCase(),
                            phoneNumberDenom: isEmpty(values["phoneNumber"]) ? null : values["phoneNumber"],
                            addressDenom: isEmpty(values["address"]) ? null : values["address"].toLowerCase(),
                            cityDenom: isEmpty(values["city"]) ? null : values["city"].toLowerCase(),
                            stateOrProvinceDenom: isEmpty(values["stateOrProvince"]) ? null : values["stateOrProvince"].toLowerCase(),
                            zipOrPostalCodeDenom: isEmpty(values["zipOrPostalCode"]) ? null : values["zipOrPostalCode"].toLowerCase(),
                        };
                        handleOnSubmit(contactData);
                    }}>
                    {
                        formik => (
                            <React.Fragment>
                                <Form onSubmit={formik.handleSubmit}>
                                    <CardTitle className="mb-2">
                                        {props.t("Component.ContactSearch.AddContactCard.Title")}
                                    </CardTitle>
                                    <div className="mb-4">
                                        {props.t("Component.ContactSearch.AddContactCard.Subtitle")}
                                    </div>
                                    <hr className="mt-0" />
                                    <FormGroup row>
                                        <Label sm="2" for="inputFullName">{props.t("Common.Label.Contact.Name")}</Label>
                                        <Col sm="4">
                                            <Input
                                                id="inputFullName"
                                                name="fullName"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.fullName || ""}
                                                invalid={
                                                    /*formik.touched.fullName && formik.errors.fullName ? true : false*/
                                                    isEmpty(formik.values.fullName) ? true : false
                                                }
                                            />
                                            {formik.touched.fullName && formik.errors.fullName ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.fullName}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label sm="2" for="inputEmail">{props.t("Common.Label.Contact.Email")}</Label>
                                        <Col sm="4">
                                            <Input
                                                id="inputEmail"
                                                name="email"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email || ""}
                                                invalid={
                                                    formik.touched.email && formik.errors.email ? true : false
                                                }
                                            />
                                            {formik.touched.email && formik.errors.email ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.email}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="2" for="inputPhoneNumber">{props.t("Common.Label.Contact.PhoneNumber1")}</Label>
                                        <Col sm="2">
                                            <Input
                                                id="inputPhoneNumber"
                                                name="phoneNumber"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phoneNumber || ""}
                                                invalid={
                                                    formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false
                                                }
                                            />
                                            {formik.touched.phoneNumber && formik.errors.phoneNumber ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber}</div>
                                                : null}
                                        </Col>
                                        <Label sm="1" for="inputPhoneExtension">{props.t("Common.Label.Contact.Extension1")}</Label>
                                        <Col sm="1">
                                            <Input
                                                id="inputPhoneExtension"
                                                name="phoneExtension"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phoneExtension || ""}
                                                invalid={
                                                    formik.touched.phoneExtension && formik.errors.phoneExtension ? true : false
                                                }
                                            />
                                            {formik.touched.phoneExtension && formik.errors.phoneExtension ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.phoneExtension}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="2" for="inputPhoneNumber2">{props.t("Common.Label.Contact.PhoneNumber2")}</Label>
                                        <Col sm="2">
                                            <Input
                                                id="inputPhoneNumber2"
                                                name="phoneNumber2"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phoneNumber2 || ""}
                                                invalid={
                                                    formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ? true : false
                                                }
                                            />
                                            {formik.touched.phoneNumber2 && formik.errors.phoneNumber2 ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.phoneNumber2}</div>
                                                : null}
                                        </Col>
                                        <Label sm="1" for="inputPhoneExtension2">{props.t("Common.Label.Contact.Extension2")}</Label>
                                        <Col sm="1">
                                            <Input
                                                id="inputPhoneExtension2"
                                                name="phoneExtension2"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phoneExtension2 || ""}
                                                invalid={
                                                    formik.touched.phoneExtension2 && formik.errors.phoneExtension2 ? true : false
                                                }
                                            />
                                            {formik.touched.phoneExtension2 && formik.errors.phoneExtension2 ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.phoneExtension2}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="2" for="inputAddress">{props.t("Common.Label.Contact.Address")}</Label>
                                        <Col sm="4">
                                            <Input
                                                id="inputAddress"
                                                name="address"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address || ""}
                                                invalid={
                                                    formik.touched.address && formik.errors.address ? true : false
                                                }
                                            />
                                            {formik.touched.address && formik.errors.address ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.address}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="2" for="inputCity">{props.t("Common.Label.Contact.City")}</Label>
                                        <Col sm="4">
                                            <Input
                                                id="inputCity"
                                                name="city"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.city || ""}
                                                invalid={
                                                    formik.touched.city && formik.errors.city ? true : false
                                                }
                                            />
                                            {formik.touched.city && formik.errors.city ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.city}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="2" for="inputStateOrProvince">{props.t("Common.Label.Contact.StateOrProvince")}</Label>
                                        <Col sm="4">
                                            <Input
                                                id="inputStateOrProvince"
                                                name="stateOrProvince"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.stateOrProvince || ""}
                                                invalid={
                                                    formik.touched.stateOrProvince && formik.errors.stateOrProvince ? true : false
                                                }
                                            />
                                            {formik.touched.stateOrProvince && formik.errors.stateOrProvince ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.stateOrProvince}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm="2" for="inputZipOrPostalCode">{props.t("Common.Label.Contact.ZipOrPostalCode")}</Label>
                                        <Col sm="4">
                                            <Input
                                                id="inputZipOrPostalCode"
                                                name="zipOrPostalCode"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.zipOrPostalCode || ""}
                                                invalid={
                                                    formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ? true : false
                                                }
                                            />
                                            {formik.touched.zipOrPostalCode && formik.errors.zipOrPostalCode ?
                                                <div className="text-danger font-size-10 mt-1">{formik.errors.zipOrPostalCode}</div>
                                                : null}
                                        </Col>
                                    </FormGroup>
                                    <hr className="mt-0" />
                                    <Button className="me-2" color="primary" type="submit" disabled={!(formik.isValid && formik.dirty)}>
                                        {props.t("Common.Action.Save")}
                                    </Button>
                                    <Button className="me-2" color="secondary" type="reset" outline onClick={(e) => { handleCancelOnClick(e) }}>
                                        {props.t("Common.Action.Cancel")}
                                    </Button>
                                </Form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </CardBody>
        </Card>
    )
}

AddContactCard.propTypes = {
    t: PropTypes.any,
    contactinfo: PropTypes.any,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
}

export default withTranslation()(AddContactCard)
