import React from "react";
import PropTypes from "prop-types";

import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";

// i18n
import { withTranslation } from "react-i18next";

// Component
function NotificationsPage(props) {

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Notifications</title>
      </MetaTags>
      <Container fluid>
        <h4>{props.t("Page.User.Notifications")}</h4>
      </Container>
    </div>
  )
  
}

NotificationsPage.propTypes = {
  t: PropTypes.any,
  props: PropTypes.any,
  user: PropTypes.any
};

export default withRouter(withTranslation()(NotificationsPage));

