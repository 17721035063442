import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Button } from "reactstrap"
import { isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"

import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"

//i18n
import { withTranslation } from "react-i18next"

// Component
function MandateDetailContactTableRow(props) {
  const { mandate, mandateContact, onEdit, onDelete, onSetAsPrimary } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleEditContactOnClick(e) {
    //console.log("MandateContactTableRow.handleEditContactOnClick");
    e.preventDefault()
    onEdit(mandateContact)
  }

  function handleDeleteContactOnClick(e) {
    //console.log("MandateContactTableRow.handleDeleteContactOnClick");
    e.preventDefault()
    toggleDeleteConfirmationForm() // Open the form
  }

  function handleSetAsPrimaryContactOnClick(e) {
    //console.log("MandateContactTableRow.handleSetAsPrimaryContactOnClick");
    e.preventDefault()

    onSetAsPrimary(mandateContact)
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("MandateContactTableRow.handleDeleteConfirmationFormOnSave:confirmationData", confirmationData);
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(mandateContact)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function displayNamesOfRoles() {
    let roles = []
    if (isEmpty(mandateContact.roles)) {
      return roles
    }
    mandateContact.roles.map(role => {
      roles.push(props.t("Common.Enum.ContactRole." + role))
    })
    return roles
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <tr key={mandateContact.id}>
      {!mandate.retired &&
        <td className="no-print" valign="middle" width="15%">
          <Button className="me-2" size="sm" color="secondary" outline onClick={e => handleEditContactOnClick(e)}>
            <i className="bx bx-pencil" />
          </Button>
          <Button className="me-2" size="sm" color="danger" outline onClick={e => handleDeleteContactOnClick(e)}>
            <i className="bx bx-trash-alt" />
          </Button>
          {!mandateContact.primary && 
            <Button className="me-2" size="sm" color="success" outline onClick={e => handleSetAsPrimaryContactOnClick(e)}>
              P
            </Button>
          }
        </td>
      }
      <td valign="middle" width="15%">
        {mandateContact.primary ? "X" : ""}
      </td>
      <td valign="middle" width="40%">
        <p className="mb-0">{mandateContact.contact?.fullName}</p>
        <p className="mb-0">{mandateContact.contact?.email}</p>
        <p className="mb-0">{isEmpty(mandateContact.contact?.phoneExtension) ? formatPhoneNumberAsLocal(mandateContact.contact?.phoneNumber) : props.t("Common.Text.Contact.PhoneNumber", { 0: formatPhoneNumberAsLocal(mandateContact.contact?.phoneNumber), 1: mandateContact.contact?.phoneExtension })}</p>
        <p className="mb-0">{isEmpty(mandateContact.contact?.phoneExtension2) ? formatPhoneNumberAsLocal(mandateContact.contact?.phoneNumber2) : props.t("Common.Text.Contact.PhoneNumber", { 0: formatPhoneNumberAsLocal(mandateContact.contact?.phoneNumber2), 1: mandateContact.contact?.phoneExtension2 })}</p>
      </td>
      <td className="text-wrap" valign="middle" width="30%">
        {displayNamesOfRoles().join(", ")}
      </td>
      {openDeleteConfirmationForm && <DeleteConfirmationForm id={mandateContact.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.MandateDetail.ContactView.Form.DeleteConfirmation.Title")} body={props.t("Page.MandateDetail.ContactView.Form.DeleteConfirmation.Body", { 0: mandateContact.contact?.fullName })} />}
    </tr>
  )
}

MandateDetailContactTableRow.propTypes = {
  t: PropTypes.any,
  mandate: PropTypes.any,
  mandateContact: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSetAsPrimary: PropTypes.func,
}

export default withRouter(withTranslation()(MandateDetailContactTableRow))
