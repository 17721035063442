import React, { useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { useQuery } from "react-query"
import { Card, CardBody, CardTitle, Col, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap"
import { useCurrentUser } from "contexts/current-user-context"
import { isEmpty } from "lodash"

import DesktopMandateTableRow from "./DesktopMandateTableRow"

import { callAPI } from "helpers/lea-graphql_helper"
import { listSettingsMandateTypes, listMandates } from "graphql/queries"
import { MandateStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function DesktopTasksView(props) {
  const {
    currentUser: { username },
  } = useCurrentUser()

  // State
  const [mandates, setMandates] = useState([])
  const [mandatesPending, setMandatesPending] = useState([])
  const [mandatesStarted, setMandatesStarted] = useState([])
  const [mandatesCompleted, setMandatesCompleted] = useState([])
  const [optionsMandateType, setOptionsMandateTypes] = useState([])

  const [showAlert, setShowAlert] = useState(false)
  const [showError, setShowError] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  // Get list of mandates
  const { isError, isFetching, isIdle, isLoading, error } = useQuery(
    "listMandates",
    () =>
      firstValueFrom(
        callAPI("listMandates", listMandates, {
          filter: {
            assignee: { eq: username },
            retired: { ne: true },
          },
          limit: 10000,
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("DesktopTasksView.listTasks.onSuccess:result", result)
        setMandates(isEmpty(result?.items) ? [] : result.items)
        setMandatesPending(
          result?.items.filter(item => {
            return item.status === MandateStatus.PENDING
          })
        )
        setMandatesStarted(
          result?.items.filter(item => {
            return item.status === MandateStatus.INPROGRESS
          })
        )
        setMandatesCompleted(
          result?.items.filter(item => {
            return item.status === MandateStatus.COMPLETED
          })
        )
      },
      onError: error => {
        console.error("DesktopTasksView.listMandates.onError:error", error)
        setErrorMessage(error.errors[0].message)
        setShowError(true)
      },
    }
  )

  // Get list of mandate types
  const {
    isLoading: isLoadingSettingsMandateType,
    isIdle: isIdleSettingsMandateType,
    isFetching: isFetchingSettingsMandateType,
    isError: isErrorSettingsMandateType,
    error: errorSettingsMandateType,
  } = useQuery("listSettingsMandateTypes", () => firstValueFrom(callAPI("listSettingsMandateTypes", listSettingsMandateTypes)), {
    retry: false,
    //enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("DesktopTasksView.listSettingsMandateTypes.onSuccess:result", result)
      setOptionsMandateTypes(isEmpty(result?.items) ? [] : result.items)
    },
    onError: error => {
      console.error("DesktopTasksView.listSettingsMandateTypes.onError:error", error)
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {(isLoading || isFetching || isLoadingSettingsMandateType || isFetchingSettingsMandateType) && <Spinner className="ms-2" color="primary" />}
      {(!isLoading && !isFetching && !isError && !isLoadingSettingsMandateType && !isFetchingSettingsMandateType && !isErrorSettingsMandateType) &&
        <Card>
          <CardBody>
            <Row>
              <Col sm={4}>
                <Card color="danger" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{mandatesPending?.length > 0 ? mandatesPending?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-empty font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Pending")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={4}>
                <Card color="warning" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{mandatesStarted?.length > 0 ? mandatesStarted?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-half font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Inprogress")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={4}>
                <Card color="success" inverse>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <CardTitle className="font-size-24">{mandatesCompleted?.length > 0 ? mandatesCompleted?.length : 0}</CardTitle>
                      </div>
                      <i className="fas fa-thermometer-full font-size-24" />
                    </div>
                    <div className="d-flex flex-column">
                      <div>{props.t("Common.Text.Status.Completed")}</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {mandates.length > 0 &&
              <Row className="mt-4">
                <Col lg={12}>
                  <Table hover responsive size="sm">
                    <thead>
                      <tr>
                        <th width="8%">{props.t("Common.Label.Action")}</th>
                        <th width="10%">{props.t("Common.Label.Status")}</th>
                        <th>{props.t("Common.Label.MandateType")}</th>
                        <th>{props.t("Common.Label.Goal")}</th>
                        <th>{props.t("Common.Label.PurposeOf")}</th>
                        <th>{props.t("Common.Label.PrimaryContact")}</th>
                        <th className="text-sm-end">
                          {props.t("Common.Label.DaysOpen")}
                          <sup>
                            <i id="action-tooltip" className="bx bx-info-circle font-size-12" />
                          </sup>
                          <UncontrolledTooltip placement="right" target="action-tooltip">
                            {props.t("Common.Text.Tooltip.DaysOpen")}
                          </UncontrolledTooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mandatesPending.map(mandate => (
                        <DesktopMandateTableRow key={mandate.id} mandate={mandate} optionsMandateTypes={optionsMandateType} />
                      ))}
                      {mandatesStarted.map(mandate => (
                        <DesktopMandateTableRow key={mandate.id} mandate={mandate} optionsMandateTypes={optionsMandateType} />
                      ))}
                      {mandatesCompleted.map(mandate => (
                        <DesktopMandateTableRow key={mandate.id} mandate={mandate} optionsMandateTypes={optionsMandateType} />
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            }
          </CardBody>
        </Card>
      }
    </React.Fragment>
  )
}

DesktopTasksView.propTypes = {
  t: PropTypes.any,
  mandates: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(DesktopTasksView))
