import { API, graphqlOperation } from "aws-amplify"
import { updateMandate, updateTask } from "graphql/mutations"
import { listTasks } from "graphql/queries"
import { removeGeneratedProperties } from "helpers/lea-graphql_helper"

// Async function to archive the request (like delete cascade)
export async function archiveMandate(mandate) {
  //console.log("MandateService.archiveMandate:mandate", mandate)

  // 1) Get all task based on mandate
  const listTaskVariables = {
    filter: { mandateId: { eq: mandate.id }, retired: { ne: "true" } },
    limit: 10000,
  }
  const listOfTask = await API.graphql(graphqlOperation(listTasks, listTaskVariables))
  //console.log("MandateService.archiveMandate:listOfTask", listOfTask)

  // 2) Set the "Retired" field to true for all task
  const deleteTask = async task => {
    const input = {
      ...task,
      retired: true,
    }
    //console.log("MandateService.archiveMandate.deleteTask:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateService.archiveMandate.deleteTask:updateInput", updateInput)
    return await API.graphql(graphqlOperation(updateTask, { input: updateInput }))
  }

  const deleteAllTasks = async () => {
    return Promise.all(listOfTask.data.listTasks.items.map(task => deleteTask(task)))
  }

  // 3) Set the "Retired" field to true for all mandate
  const deleteMandate = async () => {
    const input = {
      ...mandate,
      retired: true,
    }
    //console.log("MandateService.archiveMandate.deleteMandate:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateService.archiveMandate.deleteMandate:updateInput", updateInput)
    return await API.graphql(graphqlOperation(updateMandate, { input: updateInput }))
  }

  // 4)  Execute
  deleteAllTasks().then(() => {
    deleteMandate().then(result => {
      //console.log("success", result)
    })
  })
}
