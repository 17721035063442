import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'

import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap"

import { URL_NOTIFICATIONS, URL_PROFILE } from "helpers/lea-url_helper"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "store/actions"

// User image
import user1 from "assets/images/users/user.png"

// Amplify
import { Auth } from 'aws-amplify';

//i18n
import { withTranslation } from "react-i18next"
import LanguageDropdown from "./LanguageDropdown"
import LanguageDropdownForMenu from "./LanguageDropdownForMenu"

/**
 * Profile menu component
 */
const ProfileMenu = props => {

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  //const [username, setusername] = useState("")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          {/*<span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>*/}
          {/*<i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>*/}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <DropdownItem tag={Link} to={URL_PROFILE}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Menu.User.Profile")}{" "}
          </DropdownItem>

          <DropdownItem tag={Link} to={URL_NOTIFICATIONS}>
            {/*<span className="badge bg-success float-end">11</span>*/}
            <i className="bx bx-bell font-size-16 align-middle me-1" />
            {props.t("Menu.User.Notification")}
          </DropdownItem>

          <DropdownItem onClick={() => { props.showRightSidebarAction(!props.showRightSidebar); }}>
            {/*<span className="badge bg-success float-end">11</span>*/}
            <i className="bx bx-show font-size-16 align-middle me-1" />
            {props.t("Menu.User.Appearence")}
          </DropdownItem>

          <div className="dropdown-divider" />

          <DropdownItem onClick={signOut} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Menu.User.Logout")}</span>
          </DropdownItem>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

const signOut = async () => {
  try {
    await Auth.signOut();
    // So the current workaround is to reload your app like this?
    window.location.reload();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(connect(null, { showRightSidebarAction })(withTranslation()(ProfileMenu)))
