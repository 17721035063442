import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"

import Moment from "react-moment"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardFooter, CardLink, CardText, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Progress, Row, Table } from "reactstrap"
import { isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"

import { requestStatusClasses } from "helpers/lea-status_helper"
import { getOptionByValue } from "helpers/lea-options_helper"
import { URL_REQUESTS } from "helpers/lea-url_helper"

import { CommunicationChannel, ReferrerType, RequestStatus } from "models"

//i18n
import { withTranslation, useTranslation } from "react-i18next"

// Component
function RequestDetailSummaryView(props) {
  const { request, users, onDelete, onEdit } = props

  // Detect language change
  const { i18n } = useTranslation()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuRequest, setOpenMenuRequest] = useState(false)
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)
  
  const requestStatusOptions = useMemo(() => { return initRequestStatusOptions() }, [i18n.language])
  const communicationChannelOptions = useMemo(() => { return initCommunicationChannelOptions()}, [i18n.language])
  const referrerOptions = useMemo(() => { return initReferrerOptions()}, [i18n.language])

  // ----------------------------------------------------------------------------------------------------------------
  // Init function (for useMemo hook)
  // ----------------------------------------------------------------------------------------------------------------
  function initRequestStatusOptions() {
    //console.log("[initRequestStatusOptions] - params[]")
    let items = [
      { value: RequestStatus.PENDING, label: i18n.t("Common.Enum.RequestStatus.PENDING") },
      { value: RequestStatus.INPROGRESS, label: i18n.t("Common.Enum.RequestStatus.INPROGRESS") },
      { value: RequestStatus.COMPLETED, label: i18n.t("Common.Enum.RequestStatus.COMPLETED") },
    ]
    return items
  }

  function initCommunicationChannelOptions() {
    //console.log("[initCommunicationChannelOptions] - params[]")
    let items = [
      { value: CommunicationChannel.EMAIL, label: i18n.t("Common.Enum.CommunicationChannel.EMAIL") },
      { value: CommunicationChannel.PHONE, label: i18n.t("Common.Enum.CommunicationChannel.PHONE") },
      { value: CommunicationChannel.SOCIAL_MEDIA, label: i18n.t("Common.Enum.CommunicationChannel.SOCIALMEDIA") },
      { value: CommunicationChannel.WEBSITE, label: i18n.t("Common.Enum.CommunicationChannel.WEBSITE") },
    ]
    return items
  }

  function initReferrerOptions() {
    //console.log("[initReferrerOptions] - params[]")
    let items = [
      { value: ReferrerType.CLIENT, label: i18n.t("Common.Enum.Referrer.CLIENT") },
      { value: ReferrerType.SEARCH, label: i18n.t("Common.Enum.Referrer.SEARCH") },
      { value: ReferrerType.SOCIAL_MEDIA, label: i18n.t("Common.Enum.Referrer.SOCIALMEDIA") },
      { value: ReferrerType.WEBSITE, label: i18n.t("Common.Enum.Referrer.WEBSITE") },
      { value: ReferrerType.OTHER, label: i18n.t("Common.Enum.Referrer.OTHER") },
    ]
    return items
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMenuActionEdit(e) {
    //console.log("[handleMenuActionEdit] - params[]")
    e.preventDefault()
    onEdit()
  }

  function handleViewRequests(e) {
    //console.log("[handleViewRequests] - params[]")
    e.preventDefault()
    props.history.push(URL_REQUESTS)
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("[handleDeleteConfirmationFormOnSave] - params[confirmationData]", confirmationData)
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the confirmation form
    onDelete(request)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuRequest = useCallback(() => {
    setOpenMenuRequest(!openMenuRequest)
  }, [openMenuRequest])

  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function creatorDisplayName() {
    let usr = users.find(item => {
      return item.cognitoId === request.creator
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  function assigneeDisplayName() {
    let usr = users.find(item => {
      return item.cognitoId === request.assignee
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              <CardTitle>{props.t("Page.RequestDetail.SummaryView")}</CardTitle>
            </div>
            {!request.retired && (
              <Dropdown direction="left" className="ms-1" isOpen={openMenuRequest} toggle={toggleMenuRequest}>
                <DropdownToggle tag="a" className="text-muted">
                  <i className="mdi mdi-dots-vertical font-size-18" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={e => handleMenuActionEdit(e)}>{props.t("Page.RequestDetail.SummaryView.Action.Edit")}</DropdownItem>
                  <DropdownItem onClick={toggleDeleteConfirmationForm}>{props.t("Page.RequestDetail.SummaryView.Action.Delete")}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          {openDeleteConfirmationForm && <DeleteConfirmationForm id={request.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.RequestDetail.SummaryView.Form.DeleteConfirmation.Title")} body={props.t("Page.RequestDetail.SummaryView.Form.DeleteConfirmation.Body", { 0: request.id })} />}
          {/* 
          <Row className="mt-2">
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-calendar me-1 text-primary" />
                {props.t("Common.Label.Date.CreatedAt")}
              </Label>
              <div>{isEmpty(request.createdAt) ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{request.createdAt}</Moment>}</div>
            </Col>
            <Col xl="4" xs="4"></Col>
            <Col xl="4" xs="4"></Col>
          </Row> 
          */}
          <Row className="mt-2">
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-import me-1 text-primary" />
                {props.t("Common.Label.Date.RequestDate")}
              </Label>
              <div>{isEmpty(request.requestDate) ? "-" : <Moment format="YYYY-MM-DD">{request.requestDate}</Moment>}</div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-envelope me-1 text-primary" />
                {props.t("Common.Label.CommunicationChannel")}
              </Label>
              <div>{isEmpty(request?.communicationChannel) ? "-" : getOptionByValue(communicationChannelOptions, request.communicationChannel).label}</div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-conversation me-1 text-primary" />
                {props.t("Common.Label.ReferredBy")}
              </Label>
              <div>{isEmpty(request?.referrer) ? "-" : getOptionByValue(referrerOptions, request.referrer).label}</div>
            </Col>
          </Row>
          <hr/>
          <Row>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-user me-1 text-primary" />
                {props.t("Common.Label.Creator")}
              </Label>
              <div>{creatorDisplayName()}</div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-user-check me-1 text-primary" />
                {props.t("Common.Label.Assignee")}
              </Label>
              <div>{isEmpty(request.assignee) ? props.t("Common.Text.User.Unassigned") : assigneeDisplayName()}</div>
            </Col>
            <Col xl="4" xs="4">
              <Label>
                <i className="bx bx-award me-1 text-primary" />
                {props.t("Common.Label.Status")}
              </Label>
              <div>
                <span className={"badge rounded-pill badge-soft-" + requestStatusClasses[request.status]}>
                  {request.retired &&
                    <s>{getOptionByValue(requestStatusOptions, request.status).label}</s>
                  }
                  {!request.retired &&
                    getOptionByValue(requestStatusOptions, request.status).label
                  }
                </span>
                {request.retired && 
                  <span className={"badge rounded-pill badge-soft-" + requestStatusClasses["RETIRED"]}>
                    {props.t("Common.Enum.RequestStatus.RETIRED")}
                  </span>
                }
              </div>
            </Col>
          </Row>
          {/* <div className="mb-2" style={{height:'24px'}}/> */}
          {/*
          <div>
            <div className="table-responsive">
              <Table size="sm" className="table-nowrap">
                <tbody>
                  <tr>
                    <th scope="row">{props.t("Common.Label.Creator")}</th>
                    <td>{creatorDisplayName()}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Common.Label.Date.CreatedAt")}</th>
                    <td>{isEmpty(request.createdAt) ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{request.createdAt}</Moment>}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Common.Label.Date.RequestDate")}</th>
                    <td>{isEmpty(request.requestDate) ? "-" : <Moment format="YYYY-MM-DD">{request.requestDate}</Moment>}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Common.Label.ReferredBy")}</th>
                    <td>{isEmpty(request?.referredBy) ? "-" : getOptionByValue(referredByOptions, request.referredBy).Label}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Common.Label.CommunicationChannel")}</th>
                    <td>{isEmpty(request?.communicationChannel) ? "-" : getOptionByValue(communicationChannelOptions, request.communicationChannel).label}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Common.Label.Assignee")}</th>
                    <td>{isEmpty(request.assignee) ? props.t("Common.Text.User.Unassigned") : assigneeDisplayName()}</td>
                  </tr>
                  <tr>
                    <th scope="row">{props.t("Common.Label.Status")}</th>
                    <td>
                      <span className={"badge rounded-pill badge-soft-" + requestStatusClasses[request.status]}>
                        {request.retired && <s>{getOptionByValue(requestStatusOptions, request.status).label}</s>}
                        {!request.retired && getOptionByValue(requestStatusOptions, request.status).label}
                      </span>
                      {request.retired && <span className={"badge rounded-pill badge-soft-" + requestStatusClasses["RETIRED"]}>{props.t("Common.Enum.RequestStatus.RETIRED")}</span>}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          */}  
        </CardBody>
        <CardFooter className="no-print">
          <div className="d-flex">
            <div className="flex-grow-1">
            <CardLink onClick={e => handleViewRequests(e)}>{props.t("Common.Action.BackToRequests")}</CardLink>
            </div>
            <CardText className="text-end">
              <small className="text-muted">
                {props.t("Common.Label.Date.CreatedAt")} : &nbsp;
                {isEmpty(request.createdAt) ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{request.createdAt}</Moment>}
              </small>
            </CardText>
          </div>
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

RequestDetailSummaryView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  request: PropTypes.any,
  users: PropTypes.any,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default withRouter(withTranslation()(RequestDetailSummaryView))
