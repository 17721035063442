import React, { useMemo } from "react"
import PropTypes from "prop-types"

import Select from "react-dropdown-select"
import { useAlert } from "react-alert"
import { useMutation } from "react-query"
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, FormFeedback, Input, Label, Row } from "reactstrap"
import { isEmpty, isNil } from "lodash"
import { Formik } from "formik"
import * as Yup from "yup"

import { updateMandate } from "graphql/mutations"
import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { MandateGoalType, MandatePriorityType, MandatePurposeType, MandateReportType, MandateStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation, useTranslation } from "react-i18next"

// Component
function MandateDetailSummaryViewEdit(props) {
  const { username, mandate, users, onSave, onCancel } = props

  // Alert
  const customAlert = useAlert()

  // Detect language change
  const { i18n } = useTranslation()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const mandateGoalOptions = useMemo(() => { return initMandateGoalOptions() }, [i18n.language])
  const mandatePaperVersionOptions = useMemo(() => { return initMandatePaperVersionOptions() }, [i18n.language])
  const mandatePurposeOptions = useMemo(() => { return initMandatePurposeOptions() }, [i18n.language])
  const mandatePriorityOptions = useMemo(() => { return initMandatePriorityOptions() }, [i18n.language])
  const mandateReportOptions = useMemo(() => { return initMandateReportOptions() }, [i18n.language])
  const mandateStatusOptions = useMemo(() => { return initMandateStatusOptions() }, [i18n.language])

  // ----------------------------------------------------------------------------------------------------------------
  // Init function (for useMemo hook)
  // ----------------------------------------------------------------------------------------------------------------
  function initMandateGoalOptions() {
    //console.log("[initGoalOptions] - params[]")
    let items = [
      { value: MandateGoalType.DAMAGE, label: props.t("Common.Enum.Goal.DAMAGE") },
      { value: MandateGoalType.INSURABLEVALUE, label: props.t("Common.Enum.Goal.INSURABLEVALUE") },
      { value: MandateGoalType.MARKETVALUE, label: props.t("Common.Enum.Goal.MARKETVALUE") },
      { value: MandateGoalType.PROSPECTIVEVALUE, label: props.t("Common.Enum.Goal.PROSPECTIVEVALUE") },
      { value: MandateGoalType.REALVALUE, label: props.t("Common.Enum.Goal.REALVALUE") },
      { value: MandateGoalType.RENTALVALUE, label: props.t("Common.Enum.Goal.RENTALVALUE") },
      { value: MandateGoalType.RETROSPECTIVEVALUE, label: props.t("Common.Enum.Goal.RETROSPECTIVEVALUE") },
      { value: MandateGoalType.SERVITUDE, label: props.t("Common.Enum.Goal.SERVITUDE") },
      { value: MandateGoalType.OTHER, label: props.t("Common.Enum.Goal.OTHER") },
    ]
    return items
  }

  function initMandatePaperVersionOptions() {
    //console.log("[initPaperVersionOptions] - params[]")
    let items = [
      { value: true, label: props.t("Common.Enum.Boolean.True") },
      { value: false, label: props.t("Common.Enum.Boolean.False") },
    ]
    return items
  }

  function initMandatePurposeOptions() {
    //console.log("[initPurposeOptions] - params[]")
    let items = [
      { value: MandatePurposeType.BUY, label: props.t("Common.Enum.Purpose.BUY") },
      { value: MandatePurposeType.CAPITALGAIN, label: props.t("Common.Enum.Purpose.CAPITALGAIN") },
      { value: MandatePurposeType.CONTESTATION, label: props.t("Common.Enum.Purpose.CONTESTATION") },
      { value: MandatePurposeType.EXPROPRIATION, label: props.t("Common.Enum.Purpose.EXPROPRIATION") },
      { value: MandatePurposeType.FINANCING, label: props.t("Common.Enum.Purpose.FINANCING") },
      { value: MandatePurposeType.FISCAL, label: props.t("Common.Enum.Purpose.FISCAL") },
      { value: MandatePurposeType.INSURANCE, label: props.t("Common.Enum.Purpose.INSURANCE") },
      { value: MandatePurposeType.INTERNALMANAGEMENT, label: props.t("Common.Enum.Purpose.INTERNALMANAGEMENT") },
      { value: MandatePurposeType.LITIGATION, label: props.t("Common.Enum.Purpose.LITIGATION") },
      { value: MandatePurposeType.MARKETRESEARCH, label: props.t("Common.Enum.Purpose.MARKETRESEARCH") },
      { value: MandatePurposeType.PENSIONFUND, label: props.t("Common.Enum.Purpose.PENSIONFUND") },
      { value: MandatePurposeType.PREEMPTIVE, label: props.t("Common.Enum.Purpose.PREEMPTIVE") },
      { value: MandatePurposeType.PROJECTPORTFOLIO, label: props.t("Common.Enum.Purpose.PROJECTPORTFOLIO") },
      { value: MandatePurposeType.REDEVELOPMENT, label: props.t("Common.Enum.Purpose.REDEVELOPMENT") },
      { value: MandatePurposeType.SALE, label: props.t("Common.Enum.Purpose.SALE") },
      { value: MandatePurposeType.SEPARATION, label: props.t("Common.Enum.Purpose.SEPARATION") },
      { value: MandatePurposeType.SUCCESSION, label: props.t("Common.Enum.Purpose.SUCCESSION") },
      { value: MandatePurposeType.OTHER, label: props.t("Common.Enum.Purpose.OTHER") },
    ]
    return items
  }

  function initMandatePriorityOptions() {
    //console.log("[initPriorityOptions] - params[]")
    let items = [
      { value: MandatePriorityType.LOW, label: props.t("Common.Enum.Priority.LOW") },
      { value: MandatePriorityType.MEDIUM, label: props.t("Common.Enum.Priority.MEDIUM") },
      { value: MandatePriorityType.HIGH, label: props.t("Common.Enum.Priority.HIGH") },
      { value: MandatePriorityType.CRITICAL, label: props.t("Common.Enum.Priority.CRITICAL") },
    ]
    return items
  }

  function initMandateReportOptions() {
    //console.log("[initReportTypeOptions] - params[]")
    let items = [
      { value: MandateReportType.ABBREVIATED, label: props.t("Common.Enum.ReportType.ABBREVIATED") },
      { value: MandateReportType.NARRATIVE, label: props.t("Common.Enum.ReportType.NARRATIVE") },
      { value: MandateReportType.CONSULTATION, label: props.t("Common.Enum.ReportType.CONSULTATION") },
      { value: MandateReportType.LETTRE, label: props.t("Common.Enum.ReportType.LETTRE") },
      { value: MandateReportType.MONITORINGWORKS, label: props.t("Common.Enum.ReportType.MONITORINGWORKS") },
    ]
    return items
  }

  function initMandateStatusOptions() {
    //console.log("[initMandateStatusOptions] - params[]")
    let items = [
      { value: MandateStatus.PENDING, label: props.t("Common.Enum.MandateStatus.PENDING") },
      { value: MandateStatus.INPROGRESS, label: props.t("Common.Enum.MandateStatus.INPROGRESS") },
      { value: MandateStatus.COMPLETED, label: props.t("Common.Enum.MandateStatus.COMPLETED") },
    ]
    return items
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  async function handleOnSubmit(entityMandate) {
    //console.log("[handleOnSubmit] - params[entityMandate]", entityMandate)
    let entityMandateUpdate = await doUpdateMandate(entityMandate)
    if (isNil(entityMandateUpdate)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de mettre à jour le mandat" }), { timeout: 0 })
    } else {
      onSave(entityMandateUpdate)
    }
  }

  async function doUpdateMandate(entityMandate) {
    //console.log("[doUpdateMandate] - params[entityMandate]", entityMandate)
    const input = {
      ...entityMandate,
      updateUser: username,
    }
    //console.log("[doUpdateMandate] - input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("[doUpdateMandate] - updateInput", updateInput)
    try {
      const result = await updateMandateMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("EditMandateView.doUpdateMandate:error", error)
      return null
    }
  }

  const updateMandateMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateMandate", updateMandate, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <Formik
          /*enableReinitialize={true}*/
          initialValues={{
            assignee: isEmpty(mandate?.assignee) ? "" : mandate?.assignee,
            estimatedDeliveryDate: isEmpty(mandate?.estimedDeliveryDate) ? "" : mandate.estimedDeliveryDate,
            deliveryDate: isEmpty(mandate?.deliveryDate) ? "" : mandate.deliveryDate,
            goal: isNil(mandate?.goal) ? null : mandate.goal,
            goalReason: isEmpty(mandate?.goalReason) ? "" : mandate?.goalReason,
            paperVersion: isNil(mandate?.paperVersion) ? null : mandate.paperVersion,
            purpose: isNil(mandate?.purpose) ? null : mandate.purpose,
            purposeReason: isEmpty(mandate?.purposeReason) ? "" : mandate?.purposeReason,
            priority: isNil(mandate?.priority) ? null : mandate.priority,
            reportType: isNil(mandate?.reportType) ? null : mandate.reportType,
            status: isEmpty(mandate?.status) ? "" : mandate?.status,
          }}
          validationSchema={Yup.object({})}
          onSubmit={values => {
            //console.log("EditMandateView.onSubmit:values", values)
            mandate.assignee = isEmpty(values["assignee"]) ? null : values["assignee"]
            mandate.estimedDeliveryDate = isEmpty(values["estimatedDeliveryDate"]) ? null : values["estimatedDeliveryDate"]
            mandate.deliveryDate = isEmpty(values["deliveryDate"]) ? null : values["deliveryDate"]
            mandate.goal = isNil(values["goal"]) ? null : values["goal"]
            //mandate.goalReason = isNil(values["goalReason"]) ? null : values["goalReason"]
            mandate.paperVersion = isNil(values["paperVersion"]) ? null : values["paperVersion"]
            mandate.purpose = isNil(values["purpose"]) ? null : values["purpose"]
            //mandate.purposeReason = isNil(values["purposeReason"]) ? null : values["purposeReason"]
            mandate.priority = isNil(values["priority"]) ? null : values["priority"]
            mandate.reportType = isNil(values["reportType"]) ? null : values["reportType"]
            mandate.status = isEmpty(values["status"]) ? null : values["status"]
            handleOnSubmit(mandate)
          }}
        >
          {formik => (
            <React.Fragment>
              <Form onSubmit={formik.handleSubmit}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <CardTitle>{props.t("Page.MandateDetail.SummaryViewEdit")}</CardTitle>
                    <hr className="mt-0" />
                  </div>
                </div>
                <Row>
                <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Assignee")}</Label>
                    <Col sm="3">
                      <Select
                        name="assignee"
                        placeholder={props.t("Common.Text.User.Unassigned")}
                        values={users.filter(item => {
                          return item.value === mandate?.assignee
                        })}
                        options={users}
                        onChange={values => formik.setFieldValue("assignee", isNil(values[0]?.value) ? "" : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Status")}</Label>
                    <Col sm="3">
                      <Select
                        name="status"
                        values={mandateStatusOptions.filter(item => {
                          return item.value === mandate.status
                        })}
                        options={mandateStatusOptions}
                        onChange={values => formik.setFieldValue("status", isNil(values[0]?.value) ? "" : values[0].value)}
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <hr/>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Goal")}</Label>
                    <Col sm="3">
                      <Select
                        name="goal"
                        values={mandateGoalOptions.filter(item => {
                          return item.value === mandate.goal
                        })}
                        options={mandateGoalOptions}
                        onChange={values => formik.setFieldValue("goal", isNil(values[0]?.value) ? null : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.PurposeOf")}</Label>
                    <Col sm="3">
                      <Select
                        name="goal"
                        values={mandatePurposeOptions.filter(item => {
                          return item.value === mandate.purpose
                        })}
                        options={mandatePurposeOptions}
                        onChange={values => formik.setFieldValue("purpose", isNil(values[0]?.value) ? null : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <hr/>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.ReportType")}</Label>
                    <Col sm="3">
                      <Select
                        name="reportType"
                        values={mandateReportOptions.filter(item => {
                          return item.value === mandate.reportType
                        })}
                        options={mandateReportOptions}
                        onChange={values => formik.setFieldValue("reportType", isNil(values[0]?.value) ? null : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.PaperVersion")}</Label>
                    <Col sm="3">
                      <Select
                        name="paperVersion"
                        values={mandatePaperVersionOptions.filter(item => {
                          return item.value === mandate.paperVersion
                        })}
                        options={mandatePaperVersionOptions}
                        onChange={values => formik.setFieldValue("paperVersion", isNil(values[0]?.value) ? null : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <hr/>
                  <FormGroup row>
                    <Label sm="2">{props.t("Common.Label.Priority")}</Label>
                    <Col sm="3">
                      <Select
                        name="priority"
                        values={mandatePriorityOptions.filter(item => {
                          return item.value === mandate.priority
                        })}
                        options={mandatePriorityOptions}
                        onChange={values => formik.setFieldValue("priority", isNil(values[0]?.value) ? null : values[0].value)}
                        clearable
                        /*closeOnSelect*/
                        portal={typeof document !== `undefined` && document.body}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2" for="inputEstimatedDeliveryDate">
                      {props.t("Common.Label.Date.EstimedDeliveryDate")}
                    </Label>
                    <Col sm="3">
                      <Input id="inputEstimatedDeliveryDate" name="estimatedDeliveryDate" type="date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.estimatedDeliveryDate} invalid={formik.touched.estimatedDeliveryDate && formik.errors.estimatedDeliveryDate ? true : false} />
                      {formik.touched.estimatedDeliveryDate && formik.errors.estimatedDeliveryDate ? <FormFeedback type="invalid">{formik.errors.estimatedDeliveryDate}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm="2" for="inputDeliveryDate">
                      {props.t("Common.Label.Date.DeliveryDate")}
                    </Label>
                    <Col sm="3">
                      <Input id="inputDeliveryDate" name="deliveryDate" type="date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.deliveryDate} invalid={formik.touched.deliveryDate && formik.errors.deliveryDate ? true : false} />
                      {formik.touched.deliveryDate && formik.errors.deliveryDate ? <FormFeedback type="invalid">{formik.errors.deliveryDate}</FormFeedback> : null}
                    </Col>
                  </FormGroup>
                </Row>
                <hr className="mt-0" />
                <Row>
                  <Col className="no-print">
                    <Button className="me-2" color="primary" disabled={!formik.isValid || !formik.dirty} type="submit">
                      {props.t("Common.Action.Save")}
                    </Button>
                    <Button className="me-2" color="secondary" outline onClick={onCancel}>
                      {props.t("Common.Action.Cancel")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

MandateDetailSummaryViewEdit.propTypes = {
  t: PropTypes.any,
  username: PropTypes.string,
  mandate: PropTypes.any,
  users: PropTypes.any,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default withTranslation()(MandateDetailSummaryViewEdit)
