import React, { useCallback, useState } from "react"
import PropTypes, { array } from "prop-types"

import { useAlert } from "react-alert"
import { useQueryClient } from "react-query"
import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Spinner, Table } from "reactstrap"

import MandateContactTableRow from "./ContactDetailMandateTableRow"

//i18n
import { withTranslation } from "react-i18next"

// Component
function ContactDetailMandateView(props) {
  const { contact, mandateContacts, optionsMandateTypes } = props

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  /* const [mandateContacts, setMandateContacts] = useState([]); */
  const [openMenu, setOpenMenu] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenu = useCallback(() => {
    setOpenMenu(!openMenu)
  }, [openMenu])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.ContactDetail.MandateView")}&nbsp;</CardTitle>
            <p className="text-muted mb-2">{props.t("Page.ContactDetail.MandateView.Subtitle")}</p>
          </div>
        </div>
        <div className="mt-2">
          {mandateContacts.length === 0 ? (
            <Alert color="primary">{props.t("Page.ContactDetail.MandateView.Alert")}</Alert>
          ) : (
            <div className="table-responsive">
              <Table size="sm" className="table-nowrap">
                <thead>
                  <tr>
                    <th className="no-print" width="5%">{props.t("Common.Label.Action")}</th>
                    <th>{props.t("Common.Label.Status")}</th>
                    <th>{props.t("Common.Label.MandateType")}</th>
                    <th className="text-end">{props.t("Common.Label.Identifier")}</th>
                  </tr>
                </thead>
                <tbody>
                  {mandateContacts.map(mdtcontact => (
                    <MandateContactTableRow key={mdtcontact.id} mandateContact={mdtcontact} optionsMandateTypes={optionsMandateTypes} />
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

ContactDetailMandateView.defaultProps = {
  mandateContacts: [],
}

ContactDetailMandateView.propTypes = {
  t: PropTypes.any,
  contact: PropTypes.any,
  mandateContacts: PropTypes.array,
  optionsMandateTypes: PropTypes.array,
}

export default withRouter(withTranslation()(ContactDetailMandateView))
