import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Alert, Badge, Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress, Row, Table, UncontrolledTooltip } from "reactstrap"

import RequestDetailMandateModalForm from "./RequestDetailMandateModalForm"
import RequestDetailMandateTableRow from "./RequestDetailMandateTableRow"

import { MandateStatus } from "models"

//i18n
import { withTranslation } from "react-i18next"

// Component
function RequestDetailMandateView(props) {
  const { request, users, mandates, optionsMandateType, onAddNew, onDelete } = props

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuMandate, setOpenMenuMandate] = useState(false)
  const [openAddMandateForm, setOpenAddMandateForm] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleAddMandateFormOnSave(mandateData) {
    //console.log("MandateView.handleAddMandateFormOnSave:mandateData", mandateData);
    toggleAddMandateForm()
    onAddNew(mandateData)
  }

  function handleMandateCardOnDelete(entityMandate) {
    //console.log("MandateView.handleMandateCardOnDelete:entityMandate", entityMandate);
    onDelete(entityMandate)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuMandate = useCallback(() => {
    setOpenMenuMandate(!openMenuMandate)
  }, [openMenuMandate])

  const toggleAddMandateForm = useCallback(() => {
    setOpenAddMandateForm(!openAddMandateForm)
  }, [openAddMandateForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const mandatesSortedAsc = mandates.sort((objA, objB) => objA.order - objB.order)

  const countAllMandate = () => {
    return mandates.reduce((cumul, mdt) => {
      return cumul + 1
    }, 0)
  }

  const countAllMandateActive = () => {
    return mandates.reduce((cumul, mdt) => {
      if (mdt.retired === false) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  const countAllMandateArchived = () => {
    return mandates.reduce((cumul, mdt) => {
      if (mdt.retired === true) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  const countAllMandateByStatus = status => {
    return mandates.reduce((cumul, mdt) => {
      if (mdt.status === status) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  const countAllMandateActiveByStatus = status => {
    return mandates.reduce((cumul, mdt) => {
      if (mdt.retired === false && mdt.status === status) {
        return cumul + 1
      }
      return cumul
    }, 0)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>
              {props.t("Page.RequestDetail.MandateView")}
              {/* &nbsp;
              {!request.retired && (
                <sup>
                  <Badge pill={true} color="info">{countAllActiveMandates()}</Badge>
                </sup>
              )}
              {request.retired && (
                <React.Fragment>
                  <sup>
                    <Badge pill={true} color="info">{countAllMandates()}</Badge>
                  </sup>
                  &nbsp;
                  <sup>
                    <Badge pill={true} color="secondary">{countArchivedMandates()}</Badge>
                  </sup>
                </React.Fragment>
              )} */}
            </CardTitle>
          </div>
          {!request.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuMandate} toggle={toggleMenuMandate}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={e => toggleAddMandateForm()}>{props.t("Page.RequestDetail.MandateView.Action.AddNewMandate")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {openAddMandateForm && <RequestDetailMandateModalForm mandateTypesOptions={optionsMandateType} toggle={toggleAddMandateForm} onSave={handleAddMandateFormOnSave} />}
        <div className="d-flex flex-column mt-2 no-print">
          {(request.retired && countAllMandate() > 0) &&
            <Progress className="progress-xl" multi max={100}>
              <Progress color="danger" bar value={(countAllMandateByStatus(MandateStatus.PENDING) / countAllMandate()) * 100}>
                {countAllMandateByStatus(MandateStatus.PENDING)}
              </Progress>
              <Progress color="warning" bar value={(countAllMandateByStatus(MandateStatus.INPROGRESS) / countAllMandate()) * 100}>
                {countAllMandateByStatus(MandateStatus.INPROGRESS)}
              </Progress>
              <Progress color="success" bar value={(countAllMandateByStatus(MandateStatus.COMPLETED) / countAllMandate()) * 100}>
                {countAllMandateByStatus(MandateStatus.COMPLETED)}
              </Progress>
            </Progress>
          }
          {(!request.retired && countAllMandateActive() > 0) &&
            <Progress className="progress-xl" multi max={100}>
              <Progress color="danger" bar value={(countAllMandateActiveByStatus(MandateStatus.PENDING) / countAllMandateActive()) * 100}>
                {countAllMandateActiveByStatus(MandateStatus.PENDING)}
              </Progress>
              <Progress color="warning" bar value={(countAllMandateActiveByStatus(MandateStatus.INPROGRESS) / countAllMandateActive()) * 100}>
                {countAllMandateActiveByStatus(MandateStatus.INPROGRESS)}
              </Progress>
              <Progress color="success" bar value={(countAllMandateActiveByStatus(MandateStatus.COMPLETED) / countAllMandateActive()) * 100}>
                {countAllMandateActiveByStatus(MandateStatus.COMPLETED)}
              </Progress>
            </Progress>
          }
        </div>
        {mandates.length === 0 && (
          <Row className="mt-2">
            <Col xl="12">
              <Alert color="danger">
                <i className="mdi mdi-alert-outline me-1" />
                {props.t("Common.Text.Alert.Mandates")}
              </Alert>
            </Col>
          </Row>
        )}
        {mandates.length > 0 && (
          <Row className="mt-2">
            <Col xl="12">
              <div className="table-responsive">
                <Table size="sm" className="table-nowrap">
                  <thead>
                    <tr>
                      <th className="no-print">{props.t("Common.Label.Action")}</th>
                      <th>{props.t("Common.Label.MandateType")}</th>
                      {/* <th>GoE</th>
                      <th>FoP</th> */}
                      <th>{props.t("Common.Label.PrimaryContact")}</th>
                      <th>
                        {props.t("Common.Label.DaysOpen")}
                        {/* <sup>
                          <i id="action-tooltip" className="bx bx-info-circle font-size-14 text-primary"/>
                        </sup>
                        <UncontrolledTooltip placement="right" target="action-tooltip">
                          {props.t("Common.Text.Tooltip.DaysOpen")}
                        </UncontrolledTooltip> */}
                      </th>
                      <th className="text-sm-end">{props.t("Common.Label.Status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      // Quand le statut de la demande est archivé, on affiche tous les mandats (archivés ou non)
                      // Quand le statut de la demande n'est pas archivé, on affiche seulement les mandats non archivés
                      mandatesSortedAsc.map(mandate => (request.retired || (!request.retired && !mandate.retired) ? <RequestDetailMandateTableRow key={mandate.id} mandate={mandate} optionsMandateTypes={optionsMandateType} /*userslist={users}*/ /*onDelete={handleMandateCardOnDelete}*/ /> : null))
                    }
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

RequestDetailMandateView.propTypes = {
  t: PropTypes.any,
  request: PropTypes.any,
  users: PropTypes.any,
  mandates: PropTypes.array,
  optionsMandateType: PropTypes.array,
  onAddNew: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withRouter(withTranslation()(RequestDetailMandateView))
