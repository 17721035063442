import React, { useCallback, useRef, useState } from "react"
import PropTypes from "prop-types"

import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import filterFactory, { dateFilter, selectFilter, textFilter } from "react-bootstrap-table2-filter"

import MetaTags from "react-meta-tags"
import Moment from "react-moment"
//import * as moment from "moment";
//import Moment  from 'moment-business-days'
import { useAlert } from "react-alert"
import { Link, withRouter } from "react-router-dom"
import { useMutation } from "react-query"
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import { isEmpty, isNull, isUndefined } from "lodash"

import { useCurrentUser } from "contexts/current-user-context"
import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"
import { invoiceStatusClasses } from "helpers/lea-status_helper"
import { URL_INVOICES } from "helpers/lea-url_helper"

import { API, graphqlOperation } from "aws-amplify"
import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { updateInvoice } from "graphql/mutations"
import { listInvoices } from "graphql/queries"
import { InvoiceStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Will be use to keep filter names
let fullNameFilter
let statusFilter
let creationDateFilter
let sendDateFilter
let dueDateFilter

// Component
function InvoicesMasterPage(props) {
  // Alert
  const customAlert = useAlert()

  // We use React Refs to directly access the components -> BootstrapTable
  const node = useRef()

  //
  const initialFormInvoice = { id: null, client: null }

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [invoices, setInvoices] = useState([])
  const [invoiceToDelete, setInvoiceToDelete] = useState(initialFormInvoice)
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [limit, setLimit] = useState(1000)
  const [nextTokenAWS, setNextTokenAWS] = useState()
  const [nextNextTokenAWS, setNextNextTokenAWS] = useState()
  const [previousTokensAWS, setPreviousTokensAWS] = useState([])
  const [hasMoreResults, setHasMoreResults] = useState(false)

  const [filterPosition, setFilterPosition] = useState("top")

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Function to load invoices when the form is finishing to refresh (see useEffect())
  const fetch = async () => {
    setIsLoading(true)
    try {
      const variables = {
        filter: { retired: { ne: "true" } }, // exclure les retired = true
        nextToken: nextTokenAWS,
        limit: limit,
      }
      Object.keys(variables).forEach(key => {
        if (isNull(variables[key]) || isUndefined(variables[key])) {
          delete variables[key]
        }
      })
      //console.log("fetch.variables=", variables)
      const result = await API.graphql(graphqlOperation(listInvoices, variables))
      //console.log("useEffect:result", result)

      setNextNextTokenAWS(result.data.listInvoices.nextToken)
      let listOfInvoices = invoices.concat(result.data.listInvoices.items)
      setInvoices(listOfInvoices)
      if (isUndefined(result.data.listInvoices.nextToken) || isNull(result.data.listInvoices.nextToken)) {
        setHasMoreResults(false)
        //console.log("useEffect:hasNext = ", false)
      } else {
        setHasMoreResults(true)
        //console.log("useEffect:hasNext = ", true)
      }
    } catch (err) {
      console.error("InvoicesMasterPage.fetch.onError:error", err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetch()
  }, [nextTokenAWS, limit]) // execute l'effet que si le token a changé

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleLoadMoreResult() {
    //console.log("InvoicesMasterPage.handleLoadMoreResult");
    setPreviousTokensAWS(prev => [...prev, nextTokenAWS])
    setNextTokenAWS(nextNextTokenAWS) // execute l'effet si token change (see React.useEffect())
    setNextNextTokenAWS(null)
  }

  function handleClearAllFilters() {
    //console.log("InvoicesMasterPage.handleClearAllFilters");
    statusFilter("")
    fullNameFilter("")
    creationDateFilter("")
    sendDateFilter("")
    dueDateFilter("")
  }

  function handleViewInvoice(e, invoiceId) {
    //console.log("InvoicesMasterPage.handleViewInvoice:invoiceId", invoiceId);
    e.preventDefault()
    props.history.push(URL_INVOICES + "/" + invoiceId)
  }

  function handleDeleteInvoice(e, row) {
    //console.log("InvoicesMasterPage.handleDeleteInvoice:row", row);
    e.preventDefault()
    setInvoiceToDelete(row)
    toggleDeleteConfirmationForm()
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("InvoicesMasterPage.handleDeleteConfirmationFormOnSave:confirmationData, confirmationData);
    toggleDeleteConfirmationForm() // Hide form
    doDeleteInvoice(confirmationData)
  }

  function doDeleteInvoice(confirmationData) {
    //console.log("InvoicesMasterPage.doDeleteInvoice:confirmationData", confirmationData);
    // on créer le payload comme à l'origine tout en mettant à jour le champ retired
    let invoice = invoices.find(({ id }) => id === confirmationData.id)
    const input = {
      ...invoice,
      updateUser: username,
      retired: true,
    }
    //console.log("InvoicesMasterPage.doDeleteInvoice:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("InvoicesMasterPage.doDeleteInvoice:updateInput", updateInput)
    updateInvoiceMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("InvoicesMasterPage.doDeleteInvoice.updateInvoiceMutation.onSuccess:result", result)
        let newArr = invoices.filter(item => {
          return item.id !== result.id
        })
        setInvoices(newArr)
      },
      onError: error => {
        console.error("InvoicesMasterPage.doDeleteInvoice.updateInvoiceMutation.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const updateInvoiceMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateInvoice", updateInvoice, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Def columns
  // ----------------------------------------------------------------------------------------------------------------
  const invoiceColumns = [
    /*
    {
      dataField: "rowCount",
      isDummyField: true,
      text: "#",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleEditInvoice(e, row.id)
        },
      },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex) => <div>{rowIndex + 1}</div>,
      headerStyle: (colum, colIndex) => {
        return { width: "5%", textAlign: "center" }
      },
      style: {
        cursor: "default",
        textAlign: "center",
      },
    },
    */
    {
      dataField: "status",
      isDummyField: false,
      text: props.t("Common.Label.Status"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewInvoice(e, row.id)
        },
      },
      filter: selectFilter({
        className: 'no-print',
        options: [
          {
            value: InvoiceStatus.DRAFT,
            label: props.t("Common.Enum.InvoiceStatus.DRAFT"),
          },
          {
            value: InvoiceStatus.SENT,
            label: props.t("Common.Enum.InvoiceStatus.SENT"),
          },
          {
            value: InvoiceStatus.PAID,
            label: props.t("Common.Enum.InvoiceStatus.PAID"),
          },
        ],
        getFilter: filter => {
          statusFilter = filter
        },
        //placeholder: 'Select Status...',
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <span className={"badge rounded-pill badge-soft-" + invoiceStatusClasses[row.status]}>{props.t("Common.Enum.InvoiceStatus." + row.status)}</span>,
      /*headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "createdAt",
      isDummyField: false,
      text: props.t("Common.Label.Date.CreatedAt"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewInvoice(e, row.id)
        },
      },
      filter: dateFilter({
        className: 'no-print',
        getFilter: filter => {
          creationDateFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (isEmpty(row?.createdAt) ? "" : <Moment format="YYYY-MM-DD">{row.createdAt}</Moment>),
      /*headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "billTo.fullName",
      isDummyField: false,
      text: props.t("Common.Label.Invoice.BillTo"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewInvoice(e, row.id)
        },
      },
      filter: textFilter({
        className: 'no-print',
        getFilter: filter => {
          fullNameFilter = filter
        },
        //placeholder: 'Enter Client...',
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (
        <div>
          <p className="mb-0">{row.billTo?.fullName}</p>
          <p className="mb-0">{row.billTo?.email}</p>
          <p className="mb-0">{formatPhoneNumberAsLocal(row.billTo?.phoneNumber)}</p>
        </div>
      ),
      /*headerStyle: (colum, colIndex) => {
        return { width: "35%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "sentDate",
      isDummyField: false,
      text: props.t("Common.Label.Date.SendDate"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewInvoice(e, row.id)
        },
      },
      filter: dateFilter({
        className: 'no-print',
        getFilter: filter => {
          sendDateFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (isEmpty(row?.sentDate) ? "" : <Moment format="YYYY-MM-DD">{row.sentDate}</Moment>),
      /*headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "dueDate",
      isDummyField: false,
      text: props.t("Common.Label.Date.DueDate"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewInvoice(e, row.id)
        },
      },
      filter: dateFilter({
        className: 'no-print',
        getFilter: filter => {
          dueDateFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (isEmpty(row?.dueDate) ? "" : <Moment format="YYYY-MM-DD">{row.dueDate}</Moment>),
      /*headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    // {
    //   dataField: "paidDate",
    //   isDummyField: false,
    //   text: props.t("Common.Label.Date.PaidDate"),
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       handleViewInvoice(e, row.id)
    //     },
    //   },
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cell, row) =>
    //     isEmpty(row?.paidDate) ? (
    //       ""
    //     ) : (
    //       <Moment format="YYYY-MM-DD">{row.paidDate}</Moment>
    //     ),
    //   /*headerStyle: (colum, colIndex) => {
    //     return { width: "10%" }
    //   },*/
    //   style: {
    //     cursor: "default",
    //   },
    //   sort: true,
    // },
    {
      dataField: "totalAmount",
      isDummyField: false,
      text: props.t("Common.Label.Amount"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewInvoice(e, row.id)
        },
      },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => row.totalAmount.toFixed(2),
      headerStyle: (colum, colIndex) => {
        return { /*width: "7%",*/ textAlign: "right" }
      },
      style: {
        cursor: "default",
        textAlign: "right",
      },
      sort: true,
    },
    /*
    {
      dataField: "action",
      text: props.t("Common.Label.Action"),
      isDummyField: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="text-muted">
              <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => handleEditInvoice(e, row.id)}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
              {props.t("Common.Action.Edit")}
            </DropdownItem>
            <DropdownItem onClick={(e) => handleDeleteInvoice(e, row)}>
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
              {props.t("Common.Action.Delete")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
    */
  ]

  //const { SearchBar } = Search;

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Invoices</title>
      </MetaTags>
      <Container fluid>
        {/* <h4>{props.t("Page.InvoicesMaster")}</h4> */}
        {isLoading && <Spinner className="ms-2" color="primary" />}
        {(!isLoading && !isError) &&
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    bootstrap4
                    columns={invoiceColumns}
                    data={invoices}
                    keyField="id"
                    //search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 no-print">
                          <Col sm="4" /*className="d-none d-sm-block"*/>
                            <Button color="secondary" outline onClick={handleClearAllFilters}>
                              <i className="mdi mdi-filter-remove-outline me-1" />
                              {props.t("Common.Action.ClearFilters")}
                            </Button>
                          </Col>
                        </Row>
                        {/*
                        <Row>
                          <Col sm="12">
                               <SearchBar
                                { ...toolkitProps.searchProps } 
                              /> 
                          </Col>
                        </Row>
                        */}
                        <Row>
                          <Col sm="12">
                            <BootstrapTable
                              bordered={false}
                              condensed
                              //BootstrapTable
                              //classes={"table align-middle table-nowrap"}
                              //classes={"table align-middle responsiveTable"}
                              filter={filterFactory()}
                              filterPosition={filterPosition}
                              hover
                              headerWrapperClasses={"table-light"}
                              keyField="id"
                              ref={node}
                              {...toolkitProps.baseProps}
                              wrapperClasses="table-responsive"
                            />
                            {/* <ExportCSVButton {...toolkitProps.csvProps}>Export CSV!!</ExportCSVButton> */}
                          </Col>
                        </Row>
                        {hasMoreResults && (
                          <Row>
                            <Col xs="12">
                              <div className="text-center my-3">
                                <Link to="#" className="text-success" onClick={handleLoadMoreResult}>
                                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                  {props.t("Common.Action.LoadMore")}
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

InvoicesMasterPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(InvoicesMasterPage))
