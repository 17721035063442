export const getRequestDetail = /* GraphQL */ `
  query getRequestDetail($id: ID!, $filterMandate: ModelMandateFilterInput, $filterRequestContact: ModelRequestContactFilterInput, $filterInvoice: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
    getRequest(id: $id) {
      _deleted
      _version
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      createdAt
      creator
      requestDate
      referrer
      deliveryDate
      estimedDeliveryDate
      id
      retired
      updatedAt
      status
      communicationChannel
    },
    listMandates(filter: $filterMandate, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        assignee
        primaryContact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        createdAt
        creator
        deliveryDate
        estimedDeliveryDate
        id
        location {
          city
          stateOrProvince
          address
          zipOrPostalCode
          category
          coordinates
          lotNumber
          subcategory
        }
        mandateType
        requestId
        retired
        updatedAt
        status
        paperVersion
        order
      }
      nextToken
      startedAt
    },
    listRequestContacts(filter: $filterRequestContact, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        contactId
        requestId
        roles
        contact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        primary
        createdAt
        id
        retired
        updatedAt
      }
      nextToken
      startedAt
    },
    listInvoices(filter: $filterInvoice, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        billFrom {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        billTo {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        logo {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        createdAt
        creator
        currencyCode
        discountAmount
        dueDate
        id
        isEstimate
        issueDate
        lines {
          amount
          description
          id
          isTaxed
          isTaxed2
          tax2Amount
          taxAmount
          mandateId
          quantity
          unitPrice
        }
        notes
        paidDate
        paymentMethod
        paymentTerm
        requestId
        retired
        sentDate
        status
        subject
        subtotalAmount
        tax
        tax2
        tax2Amount
        taxAmount
        taxDiscount
        totalAmount
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listRequestDetailSettings = /* GraphQL */ `
  query listRequestDetailSettings(
    $filterSettingsCompany: ModelSettingsCompanyInformationFilterInput,
    $filterSettingsMandate: ModelSettingsMandateTypeFilterInput, 
    $filterSettingsTask: ModelSettingsTaskTypeFilterInput,
    $filterUser: ModelUserFilterInput,
    $limit: Int, 
    $nextToken: String) {
    listSettingsCompanyInformations(filter: $filterSettingsCompany, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        id
        contact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        logo {
          filename
          id
          linkedTo {
            id
            type
          }
          objectKey
        }
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    },
    listSettingsMandateTypes(filter: $filterSettingsMandate, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        id
        retired
        updatedAt
        name
        cost
        translations {
          locale
          text
        }
        tasks
        order
      }
      nextToken
      startedAt
    },
    listSettingsTaskTypes(filter: $filterSettingsTask, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        id
        retired
        updatedAt
        name
        translations {
          locale
          text
        }
        order
      }
      nextToken
      startedAt
    },
    listUsers(filter: $filterUser, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        email
        id
        userStatus
        updatedAt
        tenantId
        cognitoId
        username
      }
      nextToken
      startedAt
    }
  }
`;